import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Table, Button, Form } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from "react-router-dom";
import { FcSearch, FcDocument, FcFullTrash } from "react-icons/fc";
import axios from 'axios';
import "../../style/Store.css";
import socketIOClient, { io } from "socket.io-client";
const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost:8080";

function Store() {
  const [products, setProducts] = useState([]);
  const [categorys, setCategory] = useState([]);
  const [brands, setBrand] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [product, setProduct] = useState({});
  const [socket, setSocket] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      await getProducts();
      await getBrands();
      await getCategory();
      await getPhotos();
    }
    fetchData();

    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);
    newSocket.on("getProduct", async (product) => {
      document.getElementById('productForm').reset();
      setProduct(product);
      console.log(product);
    });

    newSocket.on("getAllProducts", async (products) => {
      setProducts(products);
    });
    return () => newSocket.close();
  }, [setSocket]);

  const getProducts = async () => {
    const productRes = await axios.get('https://redline-autohaus-api.herokuapp.com/store/product/get');
    setProducts(productRes.data);
  }
  
  const getCategory = async () => {
    const catRes = await axios.get('https://redline-autohaus-api.herokuapp.com/store/category/get');
    console.log(catRes);
    setCategory(catRes.data);
  }
  
  const getBrands = async () => {
    const brandRes = await axios.get('https://redline-autohaus-api.herokuapp.com/store/brand/get');
    console.log(brandRes);
    setBrand(brandRes.data);
  }
  
  const getPhotos = async () => {
    const photoRes = await axios.get('https://redline-autohaus-api.herokuapp.com/store/photo/get');
    setPhotos(photoRes.data);
  }
  
  const photoList = photos.map((photo) =>
    <tr>
      <td>{photo.id}</td>
      <td><img src={photo.photoUrl} alt="productPhoto" width="256px"></img></td>
      <td><Button className="btn-danger">Delete</Button></td>
    </tr>
  );
  
  const productsList = products.map((product) =>
    <tr>
      <td>{product.id}</td>
      <td>{product.productName}</td>
      <td><a href={`https://redlineautohaus.ca/products/${product.id}/view`} target="_blank"><FcSearch style={{fontSize: '28px'}} /></a></td>
      <td><FcDocument style={{fontSize: '28px'}} onClick={ () => getProductToEdit(product.id)} /></td>
      <td><FcFullTrash style={{fontSize: '28px'}} /></td>
    </tr>
  );
  
  const productsSelectList = products.map((product) =>
    <option value={product.id}>{product.productName}</option>
  );
  
  const categoryList = categorys.map((category) =>
    <tr>
      <td>{category.id}</td>
      <td>{category.categoryName}</td>
      <td><Button className="btn-danger">Delete</Button></td>
    </tr>
  );
  
  const brandList = brands.map((brand) =>
    <tr>
      <td>{brand.id}</td>
      <td>{brand.brandName}</td>
      <td><Button className="btn-danger">Delete</Button></td>
    </tr>
  );
  
  const brandSelectList = brands.map((brand) => 
    <option value={brand.id} selected={ product.product_brand ? brand.id == product.product_brand.id : 'false' }>{brand.brandName}</option>
  );
  
  const categorySelectList = categorys.map((category) => 
    <option value={category.id} selected={product.product_category ? category.id == product.product_category.id : 'false'}>{category.categoryName}</option>
  );

  const handleProductSubmit = async (evt) => {
    evt.preventDefault();
    const payload = {
      productName: evt.target.productName.value,
      productDescription: editorRef.current.getContent(),
      productPartNumber: evt.target.productPartNumber.value,
      msrp: evt.target.msrp.value,
      brandId: evt.target.brandId.value,
      categoryId: evt.target.categoryId.value,
      minYear: evt.target.minYear.value,
      maxYear: evt.target.maxYear.value,
      make: evt.target.make.value,
      model: evt.target.model.value
    };
    console.log(payload);
    await axios.post('https://redline-autohaus-api.herokuapp.com/store/product/add', payload);
    await getProducts();
  }
  
  const handlePhotoSubmit = async (evt) => {
    evt.preventDefault();
    const payload = {
      photoUrl: evt.target.photoUrl.value,
      productId: evt.target.productId.value
      
    };
    const createPhoto = await axios.post('https://redline-autohaus-api.herokuapp.com/store/photo/add', payload);
    console.log(createPhoto);
    await getPhotos();
  }
  
  const handleCategorySubmit = async (evt) => {
    evt.preventDefault();
    const payload = {
      categoryName: evt.target.categoryName.value
    };
    const createCategory = await axios.post('https://redline-autohaus-api.herokuapp.com/store/category/add', payload);
    console.log(createCategory);
    await getCategory();
  }
  
  const handleBrandSubmit = async (evt) => {
    evt.preventDefault();
    const payload = {
      brandName: evt.target.brandName.value
    };
    const createBrandRes = await axios.post('https://redline-autohaus-api.herokuapp.com/store/brand/add', payload);
    await getBrands();
    console.log(createBrandRes);
  }

  const getProductToEdit = async (id) => { 
    socket.emit('getProduct', {id});
  }

  const updateProduct = async (evt, id) => { 

    const payload = {
      productName: evt.target.parentElement[0].value,
      productDescription: editorRef.current.getContent(),
      productPartNumber: evt.target.parentElement[2].value,
      msrp: evt.target.parentElement[1].value,
      brandId:evt.target.parentElement[7].value,
      categoryId: evt.target.parentElement[8].value,
      minYear: evt.target.parentElement[3].value,
      maxYear: evt.target.parentElement[4].value,
      make: evt.target.parentElement[5].value,
      model:evt.target.parentElement[6].value
    };

    socket.emit('updateProduct', {id, payload});
  }

  const editorRef = useRef(null);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
          <Card>
            <Card.Header>
              Product Editor
            </Card.Header>
              <Card.Body>
                <Form id='productForm' onSubmit={(evt) => handleProductSubmit(evt)}>
                  <Row>
                    <Col>
                    <Form.Group>
                        <Form.Control name="productName" type="text" placeholder="Name" defaultValue={ product.productName} />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                      <Form.Control name="msrp" type="text" placeholder="MSRP." defaultValue={ product.msrp} />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                      <Form.Control name="productPartNumber" type="text" placeholder="Part Number" defaultValue={ product.productPartNumber} />
                    </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <Form.Group>
                      <Form.Control name="minYear" type="text" placeholder="Min Vehicle Year" defaultValue={ product.minYear} />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                      <Form.Control name="maxYear" type="text" placeholder="Max Vehicle Year" defaultValue={ product.maxYear} />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                      <Form.Control name="make" type="text" placeholder="Make" defaultValue={ product.make} />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                      <Form.Control name="model" type="text" placeholder="Model" defaultValue={ product.model} />
                    </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <select className="form-control" name="brandId" required>
                          {brandSelectList}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                        <select className="form-control" name="categoryId" required>
                        {categorySelectList}
                      </select>
                    </Form.Group>  
                    </Col>
                  </Row>
                  <Editor
                    apiKey='xv21dwmpktkj1o0v2315rjxbsbxskuuz3xg1j28410hotn0a'
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={product.productDescription}
                    init={{
                      selector: 'textarea#open-source-plugins',
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      menubar: 'file edit view insert format tools table help',
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                      toolbar_sticky: true,
                      autosave_ask_before_unload: true,
                      autosave_interval: '30s',
                      autosave_prefix: '{path}{query}-{id}-',
                      autosave_restore_when_empty: false,
                      autosave_retention: '2m',
                      image_advtab: true,
                      importcss_append: true,
                      file_picker_callback: function (callback, value, meta) {
                        /* Provide file and text for the link dialog */
                        if (meta.filetype === 'file') {
                          callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                        }
                    
                        /* Provide image and alt text for the image dialog */
                        if (meta.filetype === 'image') {
                          callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                        }
                    
                        /* Provide alternative source and posted for the media dialog */
                        if (meta.filetype === 'media') {
                          callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                        }
                      },
                      height: 500,
                      menubar: true,
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      images_file_types: 'jpg,svg,webp',
                      browser_spellcheck: 'true',
                      media_live_embeds: true
                    }}
                  />
                  <br />
                  <Button variant="primary" type="submit">
                    Create New Product
                  </Button>
                  <Button onClick={ (evt) => updateProduct(evt, product.id) } variant="success">
                    Update Product
                  </Button>
                  <a href={`https://redlineautohaus.ca/products/${product.id}/view`} target="_blank"><Button variant="secondary">
                    View Product
                  </Button></a>
                </Form>
            </Card.Body>
          </Card>
          </Col>
          <Col md="3">
            <Card>
              <Card.Header>Products</Card.Header>
              <Card.Body className="storeCardBody">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
          <Card>
            <Card.Header>Add Fitment</Card.Header>
            <Card.Body>
              <Form onSubmit={(evt) => handleProductSubmit(evt)}>
                <Form.Group>
                  <Form.Control name="productName" type="text" placeholder="Year" />
                </Form.Group>
                <Form.Group>
                  <Form.Control name="msrp" type="text" placeholder="Make" />
                </Form.Group>
                <Form.Group>
                  <Form.Control name="msrp" type="text" placeholder="Model" />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Create Fitment
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <br />
            
          </Col>
          <Col>
            <Card>
              <Card.Header>Add Brand</Card.Header>
              <Card.Body>
                <Form onSubmit={(evt) => handleBrandSubmit(evt)}>
                  <Form.Group>
                    <Form.Control name="brandName" type="text" placeholder="Brand Name" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Create Brand
                    </Button>
                </Form>
              </Card.Body>
            </Card>
            <br />
            <Card>
              <Card.Header>Brands</Card.Header>
              <Card.Body className="storeCardBody">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {brandList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Add Category</Card.Header>
              <Card.Body>
                <Form onSubmit={(evt) => handleCategorySubmit(evt)}>
                  <Form.Group>
                    <Form.Control name="categoryName" type="text" placeholder="Name" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Create Category
                    </Button>
                </Form>
              </Card.Body>
            </Card>
            <br />
            <Card>
              <Card.Header>Categories</Card.Header>
              <Card.Body className="storeCardBody">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Add Photo</Card.Header>
              <Card.Body>
                <Form onSubmit={(evt) => handlePhotoSubmit(evt)}>
                  <Form.Group>
                    <Form.Control name="photoUrl" type="text" placeholder="URL" />
                  </Form.Group>
                  <Form.Group>
                    <select className="form-control" name="productId">
                      {productsSelectList}
                    </select>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Create Photo
                    </Button>
                </Form>
              </Card.Body>
            </Card>
            <br />
            <Card>
              <Card.Header>Photos</Card.Header>
              <Card.Body className="storeCardBody">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {photoList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Store;

