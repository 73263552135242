import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, ListGroup, Form, Modal, Badge  } from 'react-bootstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import "../../style/Listings.css";

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

function SalesListings(props) {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [systemMessage, setSystemMessage] = useState('');
  const [ownershipDestination, setOwnershipDestination] = useState('');
  const [inventory, setInventory] = useState([]);
  const [currentListing, setcurrentListing] = useState({});
  const [socket, setSocket] = useState(null);
  const [rinLog, setRinLog] = useState(null);
  const [rinLogs, setRinLogs] = useState([]);
  const [sendReleaseRequest, setSendReleaseRequest] = useState('No');

  const closeMessage = () => setShowMessage(false);

  const editClose = () => setShowEdit(false);

  const viewClose = () => setShowView(false);

  const getVehicles = async () => {
    const invoicesResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/sales/vehicles/get');
    const rinResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/rin-log/get');

    const rinLogsData = {};
    for(const log of rinResponse.data){
      console.log(log);
      rinLogsData[log.vin] = log;
    }
    setRinLogs(rinLogsData);
    // const invoicesResponse = await axios.get('http://localhost/sales/vehicles/get');
    setListings(invoicesResponse.data.listings);
    setLoading(true);
  }
  
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    //create payload include retail_price wholesale_price mileage carfax features, vehicle_id
    const payload = {
      retail_price: evt.target.retail_price.value,
      wholesale_price: evt.target.wholesale_price.value,
      mileage: evt.target.mileage.value,
      carfax: evt.target.carfax.value,
      features: evt.target.features.value,
      vehicle_id: evt.target.inventory_id.value,
      engine_size: evt.target.engine_size.value,
      transmission: evt.target.transmission.value,
      drivetrain: evt.target.drivetrain.value,
      warranty: evt.target.warranty.value,
      colour: evt.target.colour.value,
      datePurchased: evt.target.datePurchased.value,
      purchasedFromName: evt.target.purchasedFromName.value,
      purchasedFromAddress: evt.target.purchasedFromAddress.value,
      stockNumber: evt.target.stockNumber.value,
    }
    const response = await axios.post('https://redline-autohaus-api.herokuapp.com/sales/vehicle/new', payload);
    // const response = await axios.post('http://localhost/sales/vehicle/new', payload);
    setShowEdit(false);
    setSystemMessage(response.data.message);
    setListings(response.data.listings);
    setShowMessage(true);
  }

  const handleUpdateSubmit = async (evt, vehicleId) => {
    evt.preventDefault();
    //create payload include retail_price wholesale_price mileage carfax features, vehicle_id
    const payload = {
      retail_price: evt.target.retail_price.value,
      wholesale_price: evt.target.wholesale_price.value,
      mileage: evt.target.mileage.value,
      carfax: evt.target.carfax.value,
      features: evt.target.features.value,
      vehicle_id: vehicleId,
      listing_id: currentListing.id,
      engine_size: evt.target.engine_size.value,
      transmission: evt.target.transmission.value,
      drivetrain: evt.target.drivetrain.value,
      warranty: evt.target.warranty.value,
      colour: evt.target.colour.value,
      datePurchased: evt.target.datePurchased.value,
      purchasedFromName: evt.target.purchasedFromName.value,
      purchasedFromAddress: evt.target.purchasedFromAddress.value,
      stockNumber: evt.target.stockNumber.value,
    }
    const response = await axios.post('https://redline-autohaus-api.herokuapp.com/sales/vehicle/new/edit', payload);
    // const response = await axios.post('http://localhost/sales/vehicle/new/edit', payload);
    setShowView(false);
    setSystemMessage(response.data.message);
    setListings(response.data.listings);
    setShowMessage(true);
  }

  const toggleStatus = async (id, stockNumber) => {
    // const response = await axios.post('https://redline-autohaus-api.herokuapp.com/sales/vehicle/status/toggle', {id});
    socket.emit('toggleListingStatus', {id, stockNumber, ownershipDestination, sendReleaseRequest});
    // const response = await axios.post('http://localhost/sales/vehicle/status/toggle', {id});
  }

  const viewVehicle = async (vehicle) => {
    socket.emit('getRinLogByVin', vehicle.vehicle.vin);
    setcurrentListing(vehicle);
  }

      //upload photo to firebase storage
  const uploadPhoto = async (e) => {
    e.preventDefault();
    const file = e.target.logo.files[0];
    const storageRef = fire.storage().ref();
    const fileRef = storageRef.child(file.name+' '+moment().format('YYYYMMDDHHmmss'));
    await fileRef.put(file);
    const currentUrl = await fileRef.getDownloadURL();
    socket.emit('uploadListingPhoto', { url: currentUrl, vehicle_id: currentListing.id });
  }

  const deletePhoto = async (id) => {
    socket.emit('deleteListingPhoto', { photo_id: id, vehicle_id: currentListing.id });
  }

  const archiveListing = async (evt, id) => {
    //socket emit archiveListing
    evt.preventDefault();
    socket.emit('archiveListing', { listing_id: id });
  }

  const changeReleaseRequestValue = (evt) => {
    setSendReleaseRequest(evt.target.value);
  }

  useEffect(() => {

    const socket = socketIOClient(ENDPOINT);
    setSocket(socket);

    socket.on('toggleListingStatus', (data) => {
      setSystemMessage(data.message);
      setShowMessage(true);
      setShowView(false);
      setListings(data.listings);
    });

    socket.on('deleteListingPhoto', (data) => {
      setcurrentListing(data.listingWithPhotos);
      setListings(data.listingsWithPhotos);
    });

    socket.on('archiveListing', (data) => {
      setShowEdit(false);
      setShowView(false);
      setSystemMessage(data.message);
      setShowMessage(true);
      setListings(data.listings);
    });

    socket.on('getRinLogByVin', (data) => {
      setRinLog(data);
      setShowView(true);
    });

    socket.on('uploadListingPhoto', (data) => {
      setcurrentListing(data.listingWithPhotos);
      setListings(data.listingsWithPhotos);
    });

    socket.on('getInventory', (data) => {
      setInventory(data);
    });

    socket.on('error', (data) => {
      setSystemMessage(data);
      setShowMessage(true);
    });

    socket.emit('getInventory');

    getVehicles();
    
  }, []);

  const inventoryList = inventory.map((item) =>
    <option value={item.id}>{`${item.year} ${item.make} ${item.model} - ${item.vin}`}</option>
  );

  const carList = listings.map((vehicle) =>
    <Col md='2'>
      <Card onClick={() => viewVehicle(vehicle)}>
        <Card.Img variant="top" src={vehicle.vehicle_listings_photos[0] ? vehicle.vehicle_listings_photos[0].url : null} />
        <Card.Body>
          <Card.Title>{vehicle.title}</Card.Title>
          <Card.Text>
            Stock # {vehicle.vehicle && vehicle.vehicle.stockNumber ? vehicle.vehicle.stockNumber : 'N/A'}<br/>
            {vehicle.vehicle ? vehicle.vehicle.vin : 'N/A'}<br/>
            Retail: {vehicle.retail_price}<br/>
            Wholesale: {vehicle.wholesale_price}<br/>
            Status: <Badge  variant={vehicle.status === 'For Sale' ? 'success' : 'danger'}>{vehicle.status}</Badge><br/>
            Days Listed: <strong>{moment().diff(moment(vehicle.createdAt), 'days')} days</strong><br/>
            Floored: {rinLogs[vehicle.vehicle.vin] ? moment().diff(moment(rinLogs[vehicle.vehicle.vin].datePurchased), 'days') > 59 ? <Badge variant="danger"><strong>{moment().diff(moment(rinLogs[vehicle.vehicle.vin].datePurchased), 'days')} days</strong></Badge> : <Badge variant="success"><strong>{moment().diff(moment(rinLogs[vehicle.vehicle.vin].datePurchased), 'days')} days</strong></Badge> : 'N/A'}<br/>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button variant="primary" onClick={e => setShowEdit(true)} type="submit">
                Add Listing
            </Button>
          </Col>
        </Row>
        <Row>
          {carList}
        </Row>
      </Container>

      <Modal show={showMessage} onHide={closeMessage}>
        <Row>
            <Col>
            <p className='modalHeading'>{systemMessage}</p>
            </Col>
        </Row>
      </Modal>

      <Modal show={showView} onHide={viewClose}>
        <Row>
            <Col>
              <p className='modalHeading' style={{marginBottom:'0px'}}>{currentListing.title}</p>
              <b>{currentListing.vehicle ? currentListing.vehicle.vin : 'N/a'}</b><br />
              <b>Stock #{currentListing.vehicle ? currentListing.vehicle.stockNumber : 'N/A'}</b><br/>
            </Col>
        </Row>
        <Row>
          <Col>
          {/* Upload photo */}
          <Form onSubmit={(e) => uploadPhoto(e)}>
            <Form.Group>
            <Form.Label>Upload Vehicle Photos</Form.Label>
            <Form.Control name="logo" type="file" className='form-control' placeholder="Logo" required />
            </Form.Group>
            <Button className='btn btn-primary' type="submit">
            Upload
            </Button>
          </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              {
                currentListing.vehicle_listings_photos ? currentListing.vehicle_listings_photos.map((photo) =>
                    <Col md='3' style={{marginTop:'20px'}}><img onClick={() => deletePhoto(photo.id)} src={photo.url} style={{width: '100%'}} /></Col>
                ) : null
              }
            </Row>
          </Col>
        </Row>
        <Row>
            <Col>
              <Form style={{margin: '10px'}} onSubmit={(evt) => handleUpdateSubmit(evt, currentListing.vehicle_id)}>
                <Row>
                <Col>
                  <Form.Group>
                    <Form.Control name="retail_price" type="text" placeholder="Retail Price" required="required" defaultValue={currentListing.retail_price} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="wholesale_price" type="text" placeholder="Wholesale Price" required="required" defaultValue={currentListing.wholesale_price} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="mileage" type="text" placeholder="Mileage" required="required" defaultValue={currentListing.mileage} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="carfax" type="text" placeholder="CARFAX URL" required="required" defaultValue={currentListing.carfax} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="engine_size" type="text" placeholder="Engine Size" required="required" defaultValue={currentListing.engine_size} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="transmission" type="text" placeholder="Transmission" required="required" defaultValue={currentListing.transmission} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="drivetrain" type="text" placeholder="Drivetrain" required="required" defaultValue={currentListing.drivetrain} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="warranty" type="text" placeholder="Warranty" required="required" defaultValue={currentListing.warranty} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="ownershipDestination" type="text" placeholder="Ownership Destination" onChange={(evt) => setOwnershipDestination(evt.target.value)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <textarea name="features" type="text" placeholder="Feature List" className="form-control" required="required" style={{minHeight: '200px'}} defaultValue={currentListing.features} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="colour" type="text" placeholder="Colour" required="required" defaultValue={rinLog ? rinLog.colour : ''} />
                  </Form.Group>
                  <Form.Group>
                    <DateTimePicker name="datePurchased" type="text" placeholder={rinLog ? moment(rinLog.datePurchased).format('LLLL') : ''} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="purchasedFromName" type="text" placeholder="Purchased From Name" defaultValue={rinLog ? rinLog.purchasedFromName : ''} required="required" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="purchasedFromAddress" type="text" placeholder="Purchased From Address" defaultValue={rinLog ? rinLog.purchasedFromAddress : ''} required="required" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control name="stockNumber" type="text" placeholder="Stock Number" defaultValue={currentListing.vehicle ? currentListing.vehicle.stockNumber : ''} required="required" />
                  </Form.Group>
                  <Form.Group>
                    <select name="status" className="form-control" required="required" onChange={(evt) => changeReleaseRequestValue(evt)} defaultValue={sendReleaseRequest}>
                      <option value={'No'}>No</option>
                      <option value={'Yes'}>Yes</option>
                    </select>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Update Listing
                  </Button>
                  <Button variant="success" onClick={() => toggleStatus(currentListing.id, currentListing.vehicle ? currentListing.vehicle.stockNumber : '')}>{currentListing.status === 'For Sale' ? 'Sold' : 'For Sale'}</Button>
                  <Button variant="success" onClick={(evt) => archiveListing(evt, currentListing.id)}>{currentListing.deleted ? 'Restore Listing' : 'Archive Listing'}</Button>
                </Col>
                </Row>
              </Form>
            </Col>
        </Row>
      </Modal>

      <Modal show={showEdit} onHide={editClose}>
        <Row>
            <Col>
              <p className='modalHeading'>Create Vehicle Listing</p>
            </Col>
        </Row>
        <Row>
            <Col>
              <Form onSubmit={(evt) => handleSubmit(evt)}>
                <Row>
                  <Col>
                    <Form.Group>
                      <select name="inventory_id" type="text" className="form-control" required="required">
                        <option>Select A Vehicle From Your Inventory</option>
                        {inventoryList}
                      </select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="retail_price" type="text" placeholder="Retail Price" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="wholesale_price" type="text" placeholder="Wholesale Price" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="mileage" type="text" placeholder="Mileage" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="carfax" type="text" placeholder="CARFAX URL" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="engine_size" type="text" placeholder="Engine Size" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="transmission" type="text" placeholder="Transmission" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="drivetrain" type="text" placeholder="Drivetrain" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="warranty" type="text" placeholder="Warranty" required="required" />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <textarea name="features" type="text" placeholder="Feature List" className="form-control" required="required" style={{minHeight: '200px'}} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="colour" type="text" placeholder="Colour" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <DateTimePicker name="datePurchased" type="text" placeholder="Date Purchased" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="purchasedFromName" type="text" placeholder="Purchased From Name" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="purchasedFromAddress" type="text" placeholder="Purchased From Address" required="required" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name="stockNumber" type="text" placeholder="Stock Number" required="required" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Add Listing
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
        </Row>
      </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default SalesListings;

