import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import { SwatchesPicker } from 'react-color';


const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";


function Technicians(props) {
  const [techs, setTechs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [techToEdit, setTechToEdit] = useState('');

  const createClose = () => setShowCreate(false);
  const openCreate = () => setShowCreate(true);

  const editClose = () => setShowEdit(false);
  const openEdit = (tech) => {setTechToEdit(tech); setShowEdit(true);};

  const getTechs = async () => {
    socket.emit('getTechnicians', await fire.auth().currentUser.getIdToken());
  }
  
  useEffect(() => {

    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    const getTechnicians = async () => {
      newSocket.emit('getTechnicians', await fire.auth().currentUser.getIdToken());
    }
    getTechnicians();

    newSocket.on('deleteTech', async (data) => {
      if(data.length > 0){
        setTechs(data);
      }
      setLoading(true);
    });

    newSocket.on('getTechnicians', async (data) => {
      if(data.length > 0){
        setTechs(data);
      }
      setLoading(true);
    });

    return () => {
      newSocket.disconnect();
    }

  }, [setSocket]);
  
  const techList = techs.map((tech) =>
    <tr>
      <td>{tech.name}</td>
      <td>{tech.phone}</td>
      <td><div className='techColor' style={{background: `${tech.color}`}}></div></td>
      <td><Button className="btn-success" onClick={() => openEdit(tech)}>Edit</Button></td>
      <td><Button className="btn-danger" onClick={() => deleteTech(tech.id)}>Delete</Button></td>
    </tr>
  );
 
  const handleEditSubmit = async (evt, id) => {
    evt.preventDefault();

    const name = evt.target.name.value;
    const phone = evt.target.phone.value;
    const color = evt.target.color.value;

    const payload = {
      idToken: await fire.auth().currentUser.getIdToken(),
      id,
      name,
      phone,
      color
    };
    console.log(payload);
    await axios.post('https://redline-autohaus-api.herokuapp.com/technician/edit', payload); 
    await getTechs();
    editClose();
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const name = evt.target.name.value;
    const phone = evt.target.phone.value;
    const color = evt.target.color.value;

    const payload = {
      idToken: await fire.auth().currentUser.getIdToken(),
      name,
      phone,
      color
    };

    await axios.post('https://redline-autohaus-api.herokuapp.com/technician/add', payload); 
    await getTechs();
    createClose();
  }

  const deleteTech = async(id) => {
    const payload = {
      idToken: await fire.auth().currentUser.getIdToken(),
      id
    };

    socket.emit('deleteTech', payload);
    setLoading(false);
    await getTechs();
  }

  const colorChanged = async (data) => {
    document.getElementsByClassName('colorInput')[0].value = data.hex;
  }
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button onClick={e => openCreate()}>Add Technician</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Technicians</Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Color Code</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {techList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showCreate} onHide={createClose}>
        <Row>
          <Col>
            <p className='modalHeading'>Add New Technician</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={(evt) => handleSubmit(evt)}>
              <Form.Group>
                <Form.Control name="name" type="text" placeholder="Name" required />
              </Form.Group>
              <Form.Group>
                <Form.Control name="phone" type="text" placeholder="Phone Number" required />
              </Form.Group>
              <Form.Group>
                <Form.Control name="color" type="text" placeholder="Technician Color" disabled required className='colorInput' />
              </Form.Group>
              <Form.Group>
                <SwatchesPicker
                  onChange={ (data) => colorChanged(data)}
                  width={'100%'}
                  height={'auto'}
                  style={{margin: 'auto', display: 'block'}}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Create Technician
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal show={showEdit} onHide={editClose}>
        <Row>
          <Col>
            <p className='modalHeading'>Edit Technician</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={(evt) => handleEditSubmit(evt, techToEdit.id)}>
              <Form.Group>
                <Form.Control name="name" type="text" placeholder="Name" defaultValue={techToEdit.name} />
              </Form.Group>
              <Form.Group>
                <Form.Control name="phone" type="text" placeholder="Phone Number" defaultValue={techToEdit.phone} />
              </Form.Group>
              <Form.Group>
                <Form.Control name="color" type="text" placeholder="Technician Color" disabled required className='colorInput' defaultValue={techToEdit.color} />
              </Form.Group>
              <Form.Group>
                <SwatchesPicker
                  onChange={ (data) => colorChanged(data)}
                  width={'100%'}
                  height={'auto'}
                  style={{margin: 'auto', display: 'block'}}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Save Technician
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#4439bf"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
    </div>
  );
}

export default Technicians;

