import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';

function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInvoices = async () => {
    const invoicesResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/invoices'); 
    setInvoices(invoicesResponse.data);
    setLoading(true)
  }

  useEffect(() => {
    
    getInvoices();
    
  }, []);

  const deleteInvoice = async (evt, id) => {
    evt.preventDefault();
    const deleteRes = await axios.get(`https://redline-autohaus-api.herokuapp.com/invoice/delete/${id}`);
    console.log(deleteRes);
    getInvoices();

  }

  const invoiceList = invoices.map((invoice) =>
    <tr>
      <td>{invoice.id}</td>
      <td>{invoice.vehicle.customer.name}</td>
      <td>{invoice.vehicle.customer.phone_number}</td>
      <td>{invoice.vehicle.year}</td>
      <td>{invoice.vehicle.make}</td>
      <td>{invoice.vehicle.model}</td>
      <td>{invoice.technician}</td>
      <td>{invoice.total_amount_charged}</td>
      <td>{moment(invoice.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td><Link to={`/invoices/${invoice.id}`}><Button>View</Button></Link></td>
      <td><Button className="btn-danger" onClick={(evt) => deleteInvoice(evt, invoice.id)}>Delete</Button></td>
    </tr>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Invoices</Card.Title>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Year</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Technician</th>
                      <th>Sale Amount</th>
                      <th>Finalized</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default Invoices;

