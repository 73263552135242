import React, { useEffect, useState } from 'react';
import { Container, Row, Card, Col, Accordion, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';

function Invoices() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrders = async () => {
    const invoicesResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/orders/get'); 
    setOrders(invoicesResponse.data);
    console.log(invoicesResponse.data);
    setLoading(true);
  }

  useEffect(() => {
    
    getOrders();
    
  }, []);


  const invoiceList = orders.map((order) =>
    <Row>
      <Col md="12">
        <Card>
          <Card.Header>
            <span onClick={() => { navigator.clipboard.writeText(order.firstName) }}>{order.firstName}</span> <span onClick={() => { navigator.clipboard.writeText(order.lastName) }}>{ order.lastName }</span>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="4">
                <p>Status: <span style={{color: order.order_status === 'Approved' ? '#059825' : '#f30000', fontWeight: 600  }}>{ order.order_status }</span></p>
                <p>Address: <span onClick={() => { navigator.clipboard.writeText(`${order.address} ${order.city} ${order.province}`) }}>{order.address} {order.city} {order.province}</span></p>
                <p>Apartment: {order.apartment ? order.apartment : 'N/A'}</p>
                <p>Email: {order.email}</p>
                <p>Phone: {order.phone }</p>
                <p>Amount: ${order.total_amount}</p>
                <p>Order Placed: { moment(order.created_at).format('LLLL') }</p>
                <Link to={`/print/order/${order.id}`}>Print Order</Link>
              </Col>
              <Col md="8">
                <Accordion defaultActiveKey="0">
                  {
                    order.order_items.map((item) =>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="text" eventKey="0">
                        ${item.final_price} - {item.product.productName}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p>Price Per Unit: ${ item.price_per_unit }</p>
                            <p>Quantity: {item.quantity}</p>
                            <p>Part Number: <span onClick={() => { navigator.clipboard.writeText(item.product.productPartNumber) }}>{ item.product.productPartNumber }</span></p>
                            <Row>
                              {item.product.product_photos.map((photo) => 
                                <Col><img width="300px" src={photo.photoUrl} /></Col>
                              )}
                            </Row>
                          </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    )
                  }
                </Accordion>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>   
    </Row>
  );
  
  return (
    loading ?
 
    <Container fluid>
      
        {invoiceList}
      
    </Container>
  
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default Invoices;

