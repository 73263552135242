import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';

function VehiclesForSale(props) {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVehicles = async () => {
    const invoicesResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/vehicle/for-sale/get');
    setVehicles(invoicesResponse.data);
    setLoading(true);
  }

  const setVehicleForSale = async (vehicleId) => {
    await axios.post('https://redline-autohaus-api.herokuapp.com/vehicle/for-sale', {vehicleId: vehicleId});
    await getVehicles();
    alert('Vehicle Status Has Changed!');
  }

  useEffect(() => {
    
    getVehicles();
    
  }, []);

  const carList = vehicles.map((vehicle) =>
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Row>
              <Col>
                {vehicle.year} {vehicle.make} {vehicle.model}
              </Col>
              <Col>
                <Link to={`/invoices/${vehicle.id}/new`}><Button className="btn-success btnCardHeader">New Quote</Button></Link>
              </Col>
              <Col>
                <Button className="btn-success btnCardHeader" onClick={() => setVehicleForSale(vehicle.id)}>Toggle For Sale Status</Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              {vehicle.invoices.map((invoice) => 
              <ListGroup.Item>${invoice.total_amount_charged} - {moment(invoice.createdAt).format('LLLL')} 
              <Link to={`/invoices/${invoice.id}`} className="float-right">
                <Button>View</Button>
              </Link>
            </ListGroup.Item>)}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        {carList}
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default VehiclesForSale;

