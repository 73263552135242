import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Button, Form, Modal } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

function RinLog() {
  const [rinLogs, setRinLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [metadata, setMetadata] = useState({});

  const createClose = () => setShowCreate(false);
  const openCreate = () => setShowCreate(true);

  const getLogs = async () => {
    const rinResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/rin-log/get');
    // const rinResponse = await axios.get('http://localhost/rin-log/get');

    const metaDataResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/metadata/get');
    console.log(rinResponse);
    setRinLogs(rinResponse.data);
    setMetadata(metaDataResponse.data);
    setLoading(true)
  }

  useEffect(() => {
    
    getLogs();
    
  }, []);


  const newRinLog = async (evt) => {
    evt.preventDefault();
    const payload = {
      vin: evt.target.vin.value,
      colour: evt.target.colour.value,
      year: evt.target.year.value,
      make: evt.target.make.value,
      model: evt.target.model.value,
      date: evt.target.date.value,
      kilometers: evt.target.kilometers.value,
      status: evt.target.status.value
    };

    console.log(payload);

    await axios.post(`https://redline-autohaus-api.herokuapp.com/rin-log/new`, payload);
    getLogs();
  }


  const invoiceList = rinLogs.map((rinLog) =>
    <tr>
      <td onClick={() => { navigator.clipboard.writeText(rinLog.vin) }}>{rinLog.vin}</td>
      <td>{rinLog.colour}</td>
      <td>{rinLog.year}</td>
      <td>{rinLog.make}</td>
      <td>{rinLog.model}</td>
      <td>{moment(rinLog.datePurchased).utc().format('YYYY-MM-DD')}</td>
      <td>{moment(rinLog.dateSold).utc().format('YYYY-MM-DD')}</td>
      <td>{rinLog.kilometers}</td>
      <td>{rinLog.status}</td>
      <td>{rinLog.purchasedFromName}</td>
      <td>{rinLog.purchasedFromAddress}</td>
      <td>{rinLog.soldToName}</td>
      <td>{rinLog.soldToAddress}</td>
    </tr>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button onClick={e => openCreate()}>Add New RIN Log</Button>
          </Col>
        </Row>
        <Row>
          <Col>
          <Card>
              <Card.Body>
                RIN: <b>{metadata.rin}</b><br/>
                DEALER NUMBER: <b>{metadata.dealer_number}</b><br/>
                REDLINE HST: <b>{metadata.hst_number_1}</b><br/>
                GUDON HST: <b>{metadata.hst_number_2}</b><br />
                GUDON AUCTION ACCESS NUMBER: <b>5398587</b>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Table striped bordered hover responsive id="rinLog">
                  <thead>
                    <tr>
                      <th className='smallTableHeading'>VIN</th>
                      <th className='smallTableHeading'>Colour</th>
                      <th className='smallTableHeading'>Year</th>
                      <th className='smallTableHeading'>Make</th>
                      <th className='smallTableHeading'>Model</th>
                      <th className='smallTableHeading'>DOP</th>
                      <th className='smallTableHeading'>DOS</th>
                      <th className='smallTableHeading'>Kilometers</th>
                      <th className='smallTableHeading'>Status</th>
                      <th className='smallTableHeading'>Purchased</th>
                      <th className='smallTableHeading'>P-Address</th>
                      <th className='smallTableHeading'>Sold</th>
                      <th className='smallTableHeading'>S-Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showCreate} onHide={createClose}>
        <Row>
          <Col>
            <p className='modalHeading'>Add New RIN</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={(evt) => newRinLog(evt)}>
              <Form.Group>
                <Form.Control name="vin" type="text" placeholder="VIN" required="required" />
              </Form.Group>
              <Form.Group>
                <Form.Control name="colour" type="text" placeholder="Colour" required="required" />
              </Form.Group>
              <Form.Group>
                <Form.Control name="year" type="text" placeholder="Year" required="required" />
              </Form.Group>
              <Form.Group>
                <Form.Control name="make" type="text" placeholder="Make" required="required" />
              </Form.Group>
              <Form.Group>
                <Form.Control name="model" type="text" placeholder="Model" required="required" />
              </Form.Group>
              {/* add form group for purchasedFromName */}
              <Form.Group>
                <Form.Control name="purchasedFromName" type="text" placeholder="Purchased From Name" />
              </Form.Group>
              {/* add form group for purchasedFromAddress */}
              <Form.Group>
                <Form.Control name="purchasedFromAddress" type="text" placeholder="Purchased From Address" />
              </Form.Group>
              <Form.Group>
                <DateTimePicker name="datePurchased" type="text" placeholder="Date Purchased" />
              </Form.Group>
              <Form.Group>
                <DateTimePicker name="dateSold" type="text" placeholder="Date Sold" />
              </Form.Group>
              <Form.Group>
                <Form.Control name="kilometers" type="text" placeholder="KM" required="required" />
              </Form.Group>
              <Form.Group>
                <select name="status" className="form-control" required="required">
                  <option value="Bought">Bought</option>
                  <option value="Sold">Sold</option>
                </select>
              </Form.Group>
              <Form.Group>
                <Button type="submit">Submit</Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default RinLog;

