import React from 'react';
import { Container } from 'react-bootstrap';
// import { ChatGPT } from 'chatgpt';

function ChatGPTPage() {
  return (
    <>
      <Container fluid>
        {/* <ChatGPT
          modelName="model_name"
          apiKey="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"
        /> */}
      </Container>
    </>
  );
}

export default ChatGPTPage;

