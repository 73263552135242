import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import moment from 'moment';

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";



const ProductDisplay = ({ownerId, trialLeft}) => {

    return(
        <section>
            
            <small>{trialLeft}</small>
            <div className="product">
                <div className="description">
                <h3>Clever Plan</h3>
                <h5>$50.00 / month</h5>
                </div>
            </div>
            <form action="https://redline-autohaus-api.herokuapp.com/create-checkout-session" method="POST">
                <input type="hidden" name="lookup_key" value="price_1Mh5keGWg08MFMXqoYNbcy1X" />
                <input type="hidden" name="ownerId" value={ownerId} />
                <button id="checkout-and-portal-button" className='btn btn-primary' type="submit">
                Subscribe
                </button>
            </form>
            {/* <div className="product">
                <div className="description">
                <h3>Intelligent Plan</h3>
                <h5>$100.00 / month</h5>
                </div>
            </div>
            <form action="http://localhost/create-checkout-session" method="POST">
                <input type="hidden" name="lookup_key" value="price_1Mh3R4GWg08MFMXqHCm2e4yr" />
                <button id="checkout-and-portal-button" className='btn btn-primary' type="submit">
                Subscribe
                </button>
            </form> */}
        </section>
    );
};

const ActiveSubDisplay = ({ customerId }) => {
    return (
        <section>
            <div className="product Box-root">
            <div className="description Box-root">
                <p>Status: Active</p>
            </div>
            </div>
            <form action="https://redline-autohaus-api.herokuapp.com/create-portal-session-by-customer-id" method="POST">
            <input
                type="hidden"
                id="session-id"
                name="customer_id"
                value={customerId}
            />
            <button id="checkout-and-portal-button" className="btn btn-primary" type="submit">
                Manage Subscription
            </button>
            </form>
        </section>
    );
};

const SuccessDisplay = ({ sessionId }) => {
    return (
        <section>
            <div className="product Box-root">
            <div className="description Box-root">
                <p>Subscription successful!</p>
            </div>
            </div>
            <form action="https://redline-autohaus-api.herokuapp.com/create-portal-session" method="POST">
            <input
                type="hidden"
                id="session-id"
                name="session_id"
                value={sessionId}
            />
            <button id="checkout-and-portal-button" className='btn btn-primary' type="submit">
                Manage Subscription
            </button>
            </form>
        </section>
    );
};

const Message = ({ message }) => (
<section>
<p>{message}</p>
</section>
);


function Settings(props) {
    const [loading, setLoading] = useState(false);
    const [socket, setSocket] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [ownerId, setOwnerId] = useState('');
    const [shop, setShop] = useState({});
    const [url, setUrl] = useState('');
    const [systemMessage, setSystemMessage] = useState('');
    const [trialLeft, setTrialLeft] = useState('');

    const closeMessage = () => setShowMessage(false);

    const editClose = () => setShowEdit(false);

    const updateCountdown = (shop) => {
        if(shop){
            const futureDate = moment(shop.createdAt).add(30, 'days');
            const now = moment();
            const diff = futureDate.diff(now);
        
            // Convert the difference to days, hours, minutes, and seconds
            var duration = moment.duration(diff);
            
            // Display the countdown
            let timeLeft = duration.days() + " days, " + duration.hours() + " hours, " + duration.minutes() + " minutes, " + duration.seconds() + " seconds until trial ends.";
           
            // Set the duration to 0 if it is negative
            duration.seconds() < 0 ? timeLeft = 'Sorry your trial period has expired, if you would like to continue using AutoClever please subscribe below.' : duration = duration;
            setTrialLeft(timeLeft);
            setTimeout(
                () => updateCountdown(shop),
                1000
            );
        }
    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const socket = socketIOClient(ENDPOINT);
        setSocket(socket);
        
        const getShopData = async () => {
            socket.emit('getShopData', { idToken: await fire.auth().currentUser.getIdToken() });
            console.log('get shop data');
        }

        getShopData();

        socket.on('error', (error) => {
            console.log(error);
            setMessage(error);
            setShowMessage(true);
        });

        socket.on('getShopData', (data) => {
            console.log(data);
            setStripeCustomerId(data.stripe_customer_id);
            setOwnerId(data.owner_id);
            setShop(data);
            updateCountdown(data);
            setUrl(data.shop_logo);
            setLoading(true);
        });

        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
            "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }

    }, [sessionId, setSocket, ownerId]);

    const subscriptionElement = () => {
        if (!success && message === '' && stripeCustomerId === null) {
            return <ProductDisplay ownerId={ownerId} trialLeft={trialLeft} />;
        } else if (success && sessionId !== '') {
            return <SuccessDisplay sessionId={sessionId} />;
        } else if (stripeCustomerId !== null){
            return <ActiveSubDisplay customerId={stripeCustomerId} />;
        }else {
            return <Message message={message} />;
        }
    };

    //upload photo to firebase storage
    const uploadPhoto = async (e) => {
        e.preventDefault();
        const file = e.target.logo.files[0];
        const storageRef = fire.storage().ref();
        const fileRef = storageRef.child(file.name+' '+moment().format('YYYYMMDDHHmmss'));
        await fileRef.put(file);
        const currentUrl = await fileRef.getDownloadURL();
        setUrl(currentUrl);
        socket.emit('uploadLogo', { idToken: await fire.auth().currentUser.getIdToken(), url: currentUrl });
    }

    //shopInfoUpdate function

    const shopInfoUpdate = async (e) => {

        e.preventDefault();
        const shopName = e.target.shopName.value;
        const shopAddress = e.target.shopAddress.value;
        const shopCity = e.target.shopCity.value;
        const shopState = e.target.shopState.value;
        const shopZip = e.target.shopZip.value;
        const shopPhone = e.target.shopPhone.value;
        const shopEmail = e.target.shopEmail.value;
        const shopTaxId = e.target.shopTaxId.value;
        const shopWebsite = e.target.shopWebsite.value;
        const shopFacebook = e.target.shopFacebook.value;
        const shopInstagram = e.target.shopInstagram.value;
        const shopGoogle = e.target.shopInstagram.value;

        //payload
        const payload = {
            idToken: await fire.auth().currentUser.getIdToken(),
            shopName: shopName,
            shopAddress: shopAddress,
            shopCity: shopCity,
            shopState: shopState,
            shopZip: shopZip,
            shopPhone: shopPhone,
            shopEmail: shopEmail,
            shopTaxId: shopTaxId,
            shopWebsite: shopWebsite,
            shopFacebook: shopFacebook,
            shopInstagram: shopInstagram,
            shopGoogle: shopGoogle
        };
        
        socket.emit('shopInfoUpdate', payload);
        setSystemMessage('Your Shop Info Has Been Updated!');
        setShowMessage(true);
    }

    return (
    loading ?
    <>
        <Container fluid>
        <Row>
            <Col>
            <p id='searchCustomerHeading'>Settings</p>
            </Col>
        </Row>
        <Row>
            <Col>
            <Card className='shopLogoCard'>
                <Card.Body>
                <p className='mainCardPurpleHeading'>Shop Logo</p>
                <p>Current Logo:</p>
                <img src={url} alt='logo' className='shopLogo' style={{maxWidth: '100%'}}/>
                <Form className='shopInfoCard shopLogoForm' onSubmit={(e) => uploadPhoto(e)}>
                    <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>Upload Shop Logo</Form.Label>
                        <Form.Control name="logo" type="file" className='form-control' placeholder="Logo" required />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Button className='btn btn-primary' type="submit">
                        Upload
                        </Button>
                    </Col>
                    </Row>
                </Form>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                <p className='mainCardPurpleHeading'>Subscription</p>
                {
                    subscriptionElement()
                }
                </Card.Body>
            </Card>
            </Col>
            <Col md="8">
            <Card>
                <Card.Body>
                <p className='mainCardPurpleHeading'>Shop Information</p>
                <Form className='shopInfoCard' onSubmit={(e) => shopInfoUpdate(e)}>
                    <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>Shop Name</Form.Label>
                        <Form.Control name="shopName" type="text" placeholder="Name" required defaultValue={shop.shop_name} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                        <Form.Label>Shop Address</Form.Label>
                        <Form.Control name="shopAddress" type="text" placeholder="Address" required defaultValue={shop.shop_street} />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control name="shopCity" type="text" placeholder="City" required defaultValue={shop.shop_city} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control name="shopState" type="text" placeholder="State" required defaultValue={shop.shop_state} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control name="shopZip" type="text" placeholder="Zip Code" required defaultValue={shop.shop_postal_code} />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control name="shopPhone" type="text" placeholder="Phone Number" required defaultValue={shop.shop_phone_number} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control name="shopEmail" type="text" placeholder="Email" required defaultValue={shop.shop_email} />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>Website</Form.Label>
                        <Form.Control name="shopWebsite" type="text" placeholder="Website" required defaultValue={shop.shop_website} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                        <Form.Label>Tax ID</Form.Label>
                        <Form.Control name="shopTaxId" type="text" placeholder="Tax ID" required defaultValue={shop.shop_tax_id} />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>Facebook URL</Form.Label>
                        <Form.Control name="shopFacebook" type="text" placeholder="Facebook URL" required defaultValue={shop.shop_facebook_url} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                        <Form.Label>Instagram URL</Form.Label>
                        <Form.Control name="shopInstagram" type="text" placeholder="Instagram URL" required defaultValue={shop.shop_instagram_url} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                        <Form.Label>Google Maps Profile URL</Form.Label>
                        <Form.Control name="shopGoogle" type="text" placeholder="Google Maps Profile URL" required defaultValue={shop.shop_google_url} />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Button type="submit">Save Changes</Button>
                </Form>
                </Card.Body>
            </Card>
            </Col>
        </Row>
        </Container>
        <Modal show={showMessage} onHide={closeMessage}>
        <Row>
            <Col>
            <p className='modalHeading'>{systemMessage}</p>
            </Col>
        </Row>
        </Modal>
        <Modal show={showEdit} onHide={editClose}>
        <Row>
            <Col>
            <p className='modalHeading'>Edit Technician</p>
            </Col>
        </Row>
        <Row>
            <Col>
            <Form>
                <Button variant="primary" type="submit">
                Save Technician
                </Button>
            </Form>
            </Col>
        </Row>
        </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
        <Triangle
            type="Puff"
            color="#000"
            height={100}
            width={100}
            timeout={3000} //3 secs
        />
    </div>
    );
}

export default Settings;

