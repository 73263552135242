import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';

function ActiveJobs(props) {
  const [jobs, setJobs] = useState([]);
  const [techs, setTechs] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const getTechs = async () => {
    const techResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/technicians/get'); 
    setTechs(techResponse.data);
  }
  
  const getJobs = async () => {
    const invoicesResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/active/jobs/get'); 
    setJobs(invoicesResponse.data);
    setLoading(true);
  }

  useEffect(() => {

    getJobs();
    getTechs();
    
  }, []);

  const deleteInvoice = async (evt, id) => {
    evt.preventDefault();
    const deleteRes = await axios.get(`https://redline-autohaus-api.herokuapp.com/invoice/delete/${id}`);
    console.log(deleteRes);
    getJobs();

  }

  const finalizeJob = async (evt, id) => {
    evt.preventDefault();
    const payload = {
      id: id,
      technician: evt.target.technician.value
    };
    await axios.post(`https://redline-autohaus-api.herokuapp.com/active/jobs/finalize`, payload);
    props.history.push(`/invoices`);
  };
  
  
  const sendReviewText = async (invoice) => {
    setLoading(false);
    const response = await axios.post(`https://redline-autohaus-api.herokuapp.com/message/job/complete/send`, invoice);
    console.log(response);
    alert(response.data);
    setLoading(true);
  };
  
  const techList = techs.map((tech) => 
    <option value={tech.name}>{tech.name}</option>
  );
  
  const jobList = jobs.map((job) =>
 
    <tr>
      <td>{job.id}</td>
      <td>{job.vehicle.customer.name}</td>
      <td>{job.vehicle.customer.phone_number}</td>
      <td>{job.vehicle.year}</td>
      <td>{job.vehicle.make}</td>
      <td>{job.vehicle.model}</td>
      <td>{job.total_amount_charged}</td>
      <td>{job.createdAt}</td>
      <td>
        <form onSubmit={(evt) => finalizeJob(evt, job.id)}>
          <select name="technician" className="form-control">
            {techList}
          </select>
          <br />
          <Button type="submit" className="btn-success">Generate Final Invoice</Button>
        </form>
        <br></br>
        <Button onClick={() => sendReviewText(job)} className="btn-warning">Send Review Text</Button>
        
      </td>
      <td><Link to={`/invoices/${job.id}`}><Button>View</Button></Link></td>
      <td><Button className="btn-danger" onClick={(evt) => deleteInvoice(evt, job.id)}>Delete</Button></td>
      
    </tr>
   
  );
  

  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Approved Jobs</Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Year</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Sale Amount</th>
                      <th>Date</th>
                      <th>Technician</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
    </div>
  );
}

export default ActiveJobs;

