import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Breadcrumb,
  Button,
  Form,
} from "react-bootstrap";
import axios from "axios";
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';
import {Triangle} from 'react-loader-spinner';


function NewQuote(props) {
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [rows, setRows] = useState([]);
  const [payload, setPayload] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [vehicleId, setVehicleId] = useState(0);
  const [services, setServices] = useState([]);
  const [workStats, setWorkStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setInvoiceItems([
      { className: "firstRow", name: "type", prettyName: 'Type' },
      { className: "firstRow", name: "description", prettyName: 'Description' },
      { className: "firstRow", name: "partNumber", prettyName: 'Part Number' },
      { className: "firstRow", name: "warranty", prettyName: 'Warranty' },
      { className: "firstRow", name: "quantity", prettyName: 'Quantity' },
      { className: "firstRow", name: "pricePerUnit", prettyName: 'Price Per Unit' },
    ]);
    setVehicleId(props.match.params.id);
    getCustomer(props.match.params.id);
    setRows([{ name: "row" }]);
    getServices();
  }, [props.match.params.id]);

  const getServices = async () => {
    const serviceData = await axios.get(`https://redline-autohaus-api.herokuapp.com/services/get`);
    console.log(serviceData);
    setServices(serviceData.data);
  }
  
  const getCustomer = async (vehicleId) => {
    const vehicleData = await axios.get(`https://redline-autohaus-api.herokuapp.com/vehicles/${vehicleId}/get`);
    // const vehicleData = await axios.get(`http://localhost:8080/vehicles/${vehicleId}/get`);
    console.log(vehicleData);
    setCustomer(vehicleData.data.vehicle.customer);
    setVehicle(vehicleData.data.vehicle);
    await getWorkDone(vehicleData.data.workStats);
  }

  const getWorkDone = async(workData) => {
    const workDoneData = {
      labels: [],
      datasets: [
        {
          label: '# of Votes',
          data: [],
          backgroundColor: [

          ],
          borderColor: [

          ],
          borderWidth: 1,
        },
      ],
    }
    const chartData = {};
    for(let index in workData){
      console.log(index);
      console.log(workData[index]);
      if(chartData[workData[index].service_type]){
        chartData[workData[index].service_type] = ((parseFloat(workData[index].quantity)*parseFloat(workData[index].price_per_unit))+chartData[workData[index].service_type]);
      }else{
        chartData[workData[index].service_type] = (parseFloat(workData[index].quantity)*parseFloat(workData[index].price_per_unit));
      }
    }


    for (let index in chartData) {
      workDoneData.labels.push(index);
      workDoneData.datasets[0].data.push(parseFloat(chartData[index]).toFixed(2));
      const color = `rgba(${Math.random()*256|0}, ${Math.random()*256|0}, ${Math.random()*256|0}, 1)`;
      workDoneData.datasets[0].backgroundColor.push(color);
      workDoneData.datasets[0].borderColor.push(color);
    }

    setWorkStats(workDoneData);
  }

  const handleQuoteSubmit = async (evt) => {
    evt.preventDefault();
    setPayload([]);
    const formData = evt.target.childNodes[2].childNodes[0].childNodes;
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].getElementsByClassName("form-control").length) {
        const type = formData[i].getElementsByClassName("form-control")[0]
          .value;
        const description = formData[i].getElementsByClassName(
          "form-control"
        )[1].value;
        const partNumber = formData[i].getElementsByClassName("form-control")[2]
          .value;
        const warranty = formData[i].getElementsByClassName("form-control")[3]
        .value;
        const quantity = formData[i].getElementsByClassName("form-control")[4]
          .value;
        const pricePerUnit = formData[i].getElementsByClassName(
          "form-control"
        )[5].value;
        setPayload(
          payload.push({
            type: type,
            description: description,
            partNumber: partNumber,
            warranty: warranty,
            quantity: quantity,
            pricePerUnit: pricePerUnit,
          })
        );
      }
    }

    if(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/g.test(evt.target.vin.value)){
      setLoading(false);
      await axios.post('https://redline-autohaus-api.herokuapp.com/invoice/create/', { vehicleId: vehicleId, invoiceItems: payload, notes: evt.target.notes.value, vin: evt.target.vin.value, plate: evt.target.plate.value, odometer: evt.target.odometer.value });
      // await axios.post('http://localhost/invoice/create/', { vehicleId: vehicleId, invoiceItems: payload, notes: evt.target.notes.value });
      props.history.push(`/dashboard`);
    }else{
      alert('Invalid VIN');
    }


  };

  const addInvoiceItem = (evt) => {
    evt.preventDefault();
    const newRows = rows.concat([{ name: "row" }]);
    setRows(newRows);
  };

  const serviceSelected = (data) => {
    const selectedService = data.target.value;
    const selectedServiceMetaData = services.filter((service) => {return service.service_name==selectedService});
    const descriptionField = data.target.parentNode.parentNode.parentNode.childNodes[1].childNodes[0].childNodes[1];
    const quantityField = data.target.parentNode.parentNode.parentNode.childNodes[4].childNodes[0].childNodes[1];
    const pricePerUnitField = data.target.parentNode.parentNode.parentNode.childNodes[5].childNodes[0].childNodes[1];
    pricePerUnitField.value = selectedServiceMetaData[0].service_rate_per_unit;
    quantityField.value = 1;
    descriptionField.value = selectedServiceMetaData[0].service_description;
    console.log(selectedServiceMetaData);
  }

  const serviceList = services.map((service) =>
    <option value={service.service_name}>{service.service_name}</option>
  );

  const pieChartOptions = {
    legend: { display: false },

    scales: {
        xAxes: [{
        display: false,
        gridLines: {
            display: false
        },
        ticks: {
            display: true,
            padding: 0
        }
        }],
        yAxes: [{
        display: false,
        gridLines: {
            display: false
        },
        ticks: {
            display: false, //this will remove only the label
            padding: 0
        }
        }]
    }
  }

  return (
    loading ? 
      <>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <Card.Body className="minHeight293 newQuoteCustomerInfoPadding">
                  <Row>
                    <Col>
                      <p className="mainCardPurpleHeading">Customer Information</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="newQuoteCustomerInfoLabel">Name:</label>
                      <p className="newQuoteCustomerInfoHeading">{customer.name}</p>
                    </Col>
                    <Col>
                      <label className="newQuoteCustomerInfoLabel">Phone Number:</label>
                      <p className="newQuoteCustomerInfoHeading">{customer.phone_number}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="newQuoteCustomerInfoLabel">Customer Since:</label> 
                      <p className="newQuoteCustomerInfoHeading">{moment(customer.createdAt).fromNow()}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                  <Card.Body className="minHeight293 newQuoteCustomerInfoPadding">
                    <Row>
                      <Col>
                        <p className="mainCardPurpleHeading">Vehicle Information</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="newQuoteCustomerInfoLabel">Year:</label>
                        <p className="newQuoteCustomerInfoHeading">{vehicle.year}</p>
                      </Col>
                      <Col>
                        <label className="newQuoteCustomerInfoLabel">Make:</label>
                        <p className="newQuoteCustomerInfoHeading">{vehicle.make}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="newQuoteCustomerInfoLabel">Model:</label> 
                        <p className="newQuoteCustomerInfoHeading">{vehicle.model}</p>
                      </Col>
                    </Row>  
                  </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                  <Card.Body className="minHeight293 newQuoteCustomerInfoPadding">
                    <p className="mainCardPurpleHeading">Customer Work Done</p>
                    <Doughnut data={workStats} options={pieChartOptions} />
                  </Card.Body>
              </Card>
            </Col>
          </Row>  
          <Row>
            <Col>
              <Card className="marginTop20">
                <Card.Body className="newQuoteBody">
                  <p className="mainCardPurpleHeading">New Work Order / Quote</p>
                  <Form onSubmit={(evt) => handleQuoteSubmit(evt)} style={{ width: '100%', padding: '10px' }}>
                        <Row>

                          <Col>
                            <label className='newQuoteLabel'>Plate:</label>
                            <Form.Control name="plate" defaultValue={vehicle.plate} required className="form-control newQuoteFormControl"></Form.Control>
                          </Col>
                        
                          <Col>
                            <label className='newQuoteLabel'>VIN:</label>
                            <Form.Control name="vin" defaultValue={vehicle.vin} required className="form-control newQuoteFormControl"></Form.Control>
                          </Col>

                          <Col>
                            <label className='newQuoteLabel'>Odometer:</label>
                            <Form.Control name="odometer" required className="form-control newQuoteFormControl"></Form.Control>
                          </Col>

                        </Row>
                        <Row>
                            <Col>
                            <label className='newQuoteLabel'>Notes:</label>
                            <textarea name="notes" className="form-control newQuoteFormControl height500"></textarea>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                              {rows.map((val, id) => (
                                <Row>
                                  {invoiceItems.map((val, id) => (
                                  <>
                                    {id === 0 || id === 1 || id === 2 || id === 3 ? (
                                      <Col md='3' className="marginTop35">
                                          <Form.Group>
                                          <label className='newQuoteLabel'>{val.prettyName}:</label>
                                          {val.name === "type" || val.name === "warranty" ? (
                                              <select className="form-control newQuoteFormControl" onChange={val.name === 'type' ? (data) => serviceSelected(data) : ''} name={val.name} key={id}>
                                              {val.name === "type" ? serviceList : <><option value="1 Year/20,000 KM">1 Year/20,000 KM</option><option value="90 Days/5,000 KM">90 Days/5,000 KM</option><option value="Lifetime Warranty">Lifetime Warranty</option><option value="Manufaturer Warranty">Manufaturer Warranty</option></>}
                                              </select>
                                          ) : (
                                              <Form.Control
                                                  className={`${val.className} newQuoteFormControl`}
                                                  name={val.name}
                                                  key={id}
                                                  type="text"
                                              />
                                              )}
                                          </Form.Group>
                                      </Col>
                                    ) : (
                                      <Col md='6' className="marginTop35">
                                          <Form.Group>
                                          <label className='newQuoteLabel'>{val.prettyName}:</label>
                                          {val.name === "type" || val.name === "warranty" ? (
                                              <select className="form-control newQuoteFormControl" onChange={val.name === 'type' ? (data) => serviceSelected(data) : ''} name={val.name} key={id}>
                                              {val.name === "type" ? serviceList : <><option value="1 Year/20,000 KM">1 Year/20,000 KM</option><option value="90 Days/5,000 KM">90 Days/5,000 KM</option><option value="Lifetime Warranty">Lifetime Warranty</option><option value="Manufaturer Warranty">Manufaturer Warranty</option></>}
                                              </select>
                                          ) : (
                                              <Form.Control
                                                  className={`${val.className} newQuoteFormControl`}
                                                  name={val.name}
                                                  key={id}
                                                  type="number"
                                                  step=".01"
                                              />
                                              )}
                                          </Form.Group>
                                      </Col>
                                    )}
                                  </>
                                  ))}
                                  <hr className="quoteHR" />
                                </Row>
                              ))}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit">
                                Create Quote
                                </Button>
                                <Button
                                variant="success"
                                onClick={(evt) => addInvoiceItem(evt)}
                                className='marginLeft15'
                                >
                                Add Quote Item
                                </Button>
                            </Col>  
                        </Row>                      
                    </Form>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    :
    <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
      <Triangle
        color="#000"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    </div>
  );
}

export default NewQuote;
