import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import { CookiesProvider, useCookies } from "react-cookie";

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";


function TechSettings(props) {
    const [technicians, setTechnicians] = useState([]);
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["technician"]);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const newSocket = socketIOClient(ENDPOINT);
        setSocket(newSocket);
        console.log(cookies);
        const getShopData = async () => {
            newSocket.emit('getTechnicians', await fire.auth().currentUser.getIdToken() );
        }

        getShopData();

        newSocket.on('getTechnicians', (data) => {
            setTechnicians(data);
            setLoading(true);
        });

        newSocket.on('error', (error) => {
            console.log(error);
        });


    }, [setSocket]);

    const selectTechnician = async (e, techs) => {
        e.preventDefault();
        const selectedTech = techs.filter(tech => tech.id == e.target.technicianSelected.value);
        removeCookie("technician");
        setCookie("technician", selectedTech, { path: "/" });
        console.log(cookies);
    }

    return (
    loading ?
    <>
        <Container fluid>
        <Row>
            <Col>
            <p id='searchCustomerHeading'>Settings</p>
            </Col>
        </Row>
        <Row>
            <Col>
            <Card className='shopLogoCard'>
                <Card.Body>
                <p className='mainCardPurpleHeading'>Technician Profile</p>
                <Form className='shopInfoCard shopLogoForm' onSubmit={(e) => selectTechnician(e, technicians)}>
                    <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>Select Technician</Form.Label>
                        <Form.Control as="select" name='technicianSelected'>
                            <option value=''>Select Technician</option>
                            {technicians ? technicians.map((tech, index) => (
                                <option key={index} value={tech.id} selected={cookies.technician && cookies.technician.length > 0 && cookies.technician[0].id === tech.id ? true : false}>{tech.name}</option>
                            )) : null}
                        </Form.Control>
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Button className='btn btn-primary' type="submit">
                            Set Technician Profile
                        </Button>
                    </Col>
                    </Row>
                </Form>
                </Card.Body>
            </Card>
            </Col>
        </Row>
        </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
        <Triangle
            type="Puff"
            color="#000"
            height={100}
            width={100}
            timeout={3000} //3 secs
        />
    </div>
    );
}

export default TechSettings;

