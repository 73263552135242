import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';

let deleteId = 0;

function BillsOfSale() {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const deleteWindowClose = () => setShowDelete(false);
  const deleteWindowShow = (id) => {setShowDelete(true); deleteId = id};

  const getBillOfSale = async () => {
    const billResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/bill-of-sale/get'); 
    setBills(billResponse.data);
    setLoading(true)
  }

  useEffect(() => {
    
    getBillOfSale();
    
  }, []);

  const deleteBillOfSale = async (evt, id) => {
    evt.preventDefault();
    const deleteRes = await axios.get(`https://redline-autohaus-api.herokuapp.com/bill-of-sale/${id}/delete`);
    console.log(deleteRes);
    getBillOfSale();

  }

  const billsList = bills.map((bill) =>
    <tr>
      <td>{bill.id}</td>
      <td>{bill.firstName} {bill.lastName}</td>
      <td>{bill.vehicleYear} {bill.vehicleMake} {bill.vehicleModel}</td>
      <td>{bill.sellingPrice}</td>
      <td>{moment(bill.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td><Link to={`/vehicle/sell/${bill.id}/view`}><Button>View</Button></Link></td>
      <td><Button className="btn-danger" onClick={() => deleteWindowShow(bill.id)}>Delete</Button></td>
    </tr>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>Bills Of Sale</Card.Header>
             
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Vehicle</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {billsList}
                  </tbody>
                </Table>
             
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showDelete} onHide={deleteWindowClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{color: '#fff'}}>Are You Sure You Want To Delete This Appointment?</p>
          <Button variant="success" onClick={() => deleteBillOfSale(deleteId)}>Yes</Button>
          <Button variant="danger" onClick={deleteWindowClose}>No</Button>
        </Modal.Body>
      </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default BillsOfSale;

