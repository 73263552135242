import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container, FormControl, Form, Toast } from 'react-bootstrap';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import moment from 'moment';
import fire from '../../config/Firebase';
import {Triangle} from 'react-loader-spinner';
import '../../style/Stats.css';
import socketIOClient, { io } from "socket.io-client";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { ListItemIcon, Badge } from '@mui/material';

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";


function Stats(props) {
    const [stats, setStats] = useState({});
    const [labourStats, setLabourStats] = useState({});
    const [loading, setLoading] = useState(false);
    const [techChartData, setTechChartData] = useState([]);
    const [monthlyChartData, setMonthlyChartData] = useState({});
    const [yearlyChartData, setYearlyChartData] = useState({});
    const [monthlyWebsiteChartData, setMonthlyWebsiteData] = useState({});
    const [visitsByCityData, setVisitsByCityData] = useState({}); // [
    const [channelVisits, setChannelVisits] = useState({});
    const [pageVisits, setPageVisits] = useState({}); 
    const [authed, setAuthed] = useState(false);
    const [socket, setSocket] = useState(null);
    const [aiResponse, setAiResponse] = useState('');
    const [show, setShow] = useState(false);
    
    let streamResponse = '';

    const toggleShow = () => setShow(false);

    useEffect(() => {
        
        const newSocket = socketIOClient(ENDPOINT);
        setSocket(newSocket);

        const getAllDataFunc = async () => {
          newSocket.emit('getStats', await fire.auth().currentUser.getIdToken());
        }
        getAllDataFunc();

        newSocket.on('askAiAssistant', async (data) => {
            if(!show){
                setShow(true);                
            }
            streamResponse+=data;
            setAiResponse(streamResponse);
            console.log(streamResponse);
        });

        newSocket.on('streamDone', async (data) => {
            streamResponse = '';
        });

        newSocket.on('getYearlyChartData', async (chartData) => {
          const yearlyChartPayload = {
            labels: [],
            datasets: [
              {
                label: "This Year's Revenue",
                fill: false,
                lineTension: 0.5,
                borderColor: '#ffcd92',
                backgroundColor: '#ffcd92',
                borderCapStyle: 'bevel',
                borderDash: [],
                borderDashOffset: 0.0,
                borderWidth:10,
                borderJoinStyle: 'butt',
                data: []
              }
              ,{
                label: "Previous Year's Revenue",
                fill: false,
                lineTension: 0.5,
                borderColor: '#5bc89a',
                backgroundColor: '#5bc89a',
                borderCapStyle: 'bevel',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'butt',
                borderWidth:10,
                data: []
              }
            ]
          };
          
          for (let data in chartData.yearlyChartData) {
            yearlyChartPayload.datasets[0].data.push((chartData.yearlyChartData[data].total*1.13).toFixed(2));
          }

          for (let data in chartData.lastYearlyRevenue) {
            yearlyChartPayload.labels.push(moment(chartData.lastYearlyRevenue[data].dateOnly).format('MMMM'));
            yearlyChartPayload.datasets[1].data.push((chartData.lastYearlyRevenue[data].total*1.13).toFixed(2));
          }


          const labourChartData = {
            labels: [],
            datasets: [
              {
                label: '# of Votes',
                data: [],
                backgroundColor: [
      
                ],
                borderColor: [
  
                ],
                borderWidth: 1,
              },
            ],
          }

          let technicians = {};
          for (let index in chartData.technicians) {
            technicians[chartData.technicians[index].name] = chartData.technicians[index];
          }

          for (let index in chartData.techLabour) {
            labourChartData.labels.push(index);
            labourChartData.datasets[0].data.push(chartData.techLabour[index].total);
            const color = `rgba(${Math.random()*256|0}, ${Math.random()*256|0}, ${Math.random()*256|0}, 1)`;
            if(technicians[index]){
              labourChartData.datasets[0].backgroundColor.push(technicians[index].color);
              labourChartData.datasets[0].borderColor.push(technicians[index].color);
            }
          }
      
          setLabourStats(labourChartData);

          setYearlyChartData(yearlyChartPayload);
          setLoading(true);
        });
    
        newSocket.on("getStats", async (socketData) => {
          
          await getTechStats(socketData.statsTech, socketData.technicians);
          console.log(socketData);
          setStats(socketData.stats);

          //chart data for cities of website visits
          const websiteMonthPayload = {
            labels: [],
            datasets: [
              {
                label: '# of Visits',
                data: [],
                backgroundColor: [
      
                ],
                borderColor: [
  
                ],
                borderWidth: 1,
              },
            ],
          }
      
          setPageVisits(socketData.stats.googlePageTitleStats);
          
          for (let index in socketData.stats.googleVisitsByMonth) {

            websiteMonthPayload.labels.push( socketData.stats.googleVisitsByMonth[index].month);
            websiteMonthPayload.datasets[0].data.push((socketData.stats.googleVisitsByMonth[index].activeUsers));

            const color = `rgba(${Math.random()*256|0}, ${Math.random()*256|0}, ${Math.random()*256|0}, 1)`;
           
            websiteMonthPayload.datasets[0].backgroundColor.push(color);
            websiteMonthPayload.datasets[0].borderColor.push(color);
          
          }
      
          setVisitsByCityData(websiteMonthPayload);

          //chart data for channel group acquisition of website visits
          const websiteVisitChannelGroupData = {
            labels: [],
            datasets: [
              {
                label: '# of Visits',
                data: [],
                backgroundColor: [
      
                ],
                borderColor: [
  
                ],
                borderWidth: 1,
              },
            ],
          }
          
          for (let index in socketData.stats.googleVisitsByChannelGroup) {

            websiteVisitChannelGroupData.labels.push( socketData.stats.googleVisitsByChannelGroup[index].channel);
            websiteVisitChannelGroupData.datasets[0].data.push((socketData.stats.googleVisitsByChannelGroup[index].activeUsers));

            const color = `rgba(${Math.random()*256|0}, ${Math.random()*256|0}, ${Math.random()*256|0}, 1)`;
           
            websiteVisitChannelGroupData.datasets[0].backgroundColor.push(color);
            websiteVisitChannelGroupData.datasets[0].borderColor.push(color);
          
          }
      
          setChannelVisits(websiteVisitChannelGroupData);

          //chart data for month to month website visits

          const monthlyWebsiteChartDataPayload = {
            labels: [],
            datasets: [
              {
                label: 'Visits This Month',
                fill: false,
                lineTension: 0.1,
                borderColor: '#255cda',
                backgroundColor: '#255cda',
                borderCapStyle: 'butt',
                borderJoinStyle: 'bevel',
                borderDash: [],
                borderDashOffset: 0.0,
                borderWidth:8,
                data: []
              },
              {
                label: 'Visits Last Month',
                fill: false,
                lineTension: 0.1,
                borderColor: '#4439bf',
                backgroundColor: '#4439bf',
                borderCapStyle: 'butt',
                borderJoinStyle: 'bevel',
                borderDash: [],
                borderDashOffset: 0.0,
                borderWidth:6,
                data: []
              }
            ]
          };

          for (let data in socketData.stats.websiteAnalytics) {
            monthlyWebsiteChartDataPayload.datasets[0].data.push((socketData.stats.websiteAnalytics[data].activeUsers));
            monthlyWebsiteChartDataPayload.datasets[1].data.push((socketData.stats.previousMonthWebsiteVisits[data].activeUsers));
            monthlyWebsiteChartDataPayload.labels.push(moment(socketData.stats.websiteAnalytics[data].date).format('MMMM DD'));
          }

          //chart data for revenue month to month

          const monthlyChartDataPayload = {
            labels: [],
            datasets: [
              {
                lineTension: 0.5,
                label: 'Current Month Revenue',
                backgroundColor: '#39BF97',
                fill: true,
                borderCapStyle: 'round',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'butt',
                data: []
              },
              {
                lineTension: 0.5,
                label: 'Previous Month Revenue',
                backgroundColor: '#ffcd92',
                fill: true,
                borderDash: [],
                borderDashOffset: 0.0,
                data: []
              },
              {
                label: 'Jobs Approved Current Month',
                fill: false,
                lineTension: 0.1,
                borderColor: '#255cda',
                backgroundColor: '#255cda',
                borderCapStyle: 'butt',
                borderJoinStyle: 'bevel',
                borderDash: [],
                borderDashOffset: 0.0,
                borderWidth:8,
                data: []
              },
              {
                label: 'Jobs Approved Last Month',
                fill: false,
                lineTension: 0.1,
                borderColor: '#4439bf',
                backgroundColor: '#4439bf',
                borderCapStyle: 'butt',
                borderJoinStyle: 'bevel',
                borderDash: [],
                borderDashOffset: 0.0,
                borderWidth:6,
                data: []
              }
            ]
          };
          
          for (let data in socketData.stats.monthlyChartData) {
            monthlyChartDataPayload.datasets[0].data.push((socketData.stats.monthlyChartData[data].total*1.13).toFixed(2));
            monthlyChartDataPayload.labels.push(`${moment(socketData.stats.monthlyChartData[data] ? socketData.stats.monthlyChartData[data].dateOnly : '').format('MMMM DD')} vs ${socketData.stats.previousMonthlyChartData[data] ? moment(socketData.stats.previousMonthlyChartData[data].dateOnly).format('MMMM DD') : ''}`);
          }
    
          for (let data in socketData.stats.previousMonthlyChartData) {
            monthlyChartDataPayload.datasets[1].data.push((socketData.stats.previousMonthlyChartData[data].total*1.13).toFixed(2));
          }

          for (let data in socketData.stats.monthlyApprovalData) {
            monthlyChartDataPayload.datasets[2].data.push((socketData.stats.monthlyApprovalData[data].total*1.13).toFixed(2));
            // monthlyChartDataPayload.labels.push(`${moment(socketData.stats.monthlyApprovalData[data] ? socketData.stats.monthlyApprovalData[data].dateOnly : '').format('MMMM DD')} vs ${socketData.stats.monthlyApprovalData[data] ? moment(socketData.stats.monthlyApprovalData[data].dateOnly).format('MMMM DD') : ''}`);
          }

          for (let data in socketData.stats.previousMonthlyApprovalData) {
            monthlyChartDataPayload.datasets[3].data.push((socketData.stats.previousMonthlyApprovalData[data].total*1.13).toFixed(2));
          }
    
          setMonthlyChartData(monthlyChartDataPayload);
          setMonthlyWebsiteData(monthlyWebsiteChartDataPayload);
          console.log(monthlyWebsiteChartDataPayload);
          newSocket.emit('getYearlyChartData', await fire.auth().currentUser.getIdToken());
    
        });
        
        return () => {
          newSocket.disconnect();
        };

      }, [setSocket]);

    const getAuthed = async (evt) => {
      evt.preventDefault();
      fire.auth().signInWithEmailAndPassword(fire.auth().currentUser.email, evt.target.children[0].value).then(() => {
        setAuthed(true);
      }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorCode+' '+errorMessage);
        // ...
      });
    }

    const getTechStats = async (technicianStats, technicians) => {

        // const technicianStats = await axios.get('https://redline-autohaus-api.herokuapp.com/technicians/stats');
    
        const techStatsChartData = {
          labels: [],
          datasets: [
            {
              label: '# of Votes',
              data: [],
              backgroundColor: [
    
              ],
              borderColor: [

              ],
              borderWidth: 1,
            },
          ],
        }
        
        let techniciansData = {};
        for (let index in technicians) {
          techniciansData[technicians[index].name] = technicians[index];
        }
        console.log(techniciansData);
        for (let index in technicianStats) {
          techStatsChartData.labels.push(technicianStats[index].name);
          techStatsChartData.datasets[0].data.push((technicianStats[index].total_rev*1.13).toFixed(2));
          const color = `rgba(${Math.random()*256|0}, ${Math.random()*256|0}, ${Math.random()*256|0}, 1)`;
          if(techniciansData[technicianStats[index].name]){
            techStatsChartData.datasets[0].backgroundColor.push(techniciansData[technicianStats[index].name].color);
            techStatsChartData.datasets[0].borderColor.push(techniciansData[technicianStats[index].name].color);
          }
        }
    
        setTechChartData(techStatsChartData);
    
    }

    const monthlyRevenueOptions = {
        legend: { display: true },

        scales: {
            xAxes: [{
            display: false,
            gridLines: {
                display: false
            },
            ticks: {
                display: true,
                padding: 0
            }
            }],
            yAxes: [{
            display: false,
            gridLines: {
                display: false
            },
            ticks: {
                display: true, //this will remove only the label
                padding: 0
            }
            }]
        }
    }

    const pieChartOptions = {
      legend: { display: false },

      scales: {
          xAxes: [{
          display: false,
          gridLines: {
              display: false
          },
          ticks: {
              display: true,
              padding: 0
          }
          }],
          yAxes: [{
          display: false,
          gridLines: {
              display: false
          },
          ticks: {
              display: false, //this will remove only the label
              padding: 0
          }
          }]
      }
    }

    return (
        loading ?
            <>
            <Container fluid>
            { !authed ? 
            <div className='blur'>
              <Form onSubmit={(evt) => getAuthed(evt)}>
                <FormControl type='password' placeholder='Enter your password to view' id='getAuthedForStats' className='transparentLoginBox'></FormControl>
              </Form>
            </div>
            :
            null
            }
            <Toast id="aiResponse2" show={show} onClose={() => {toggleShow()}}>
                <Toast.Header>
                <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                <strong className="mr-auto">ALL-E:</strong>
                </Toast.Header>
                <Toast.Body>{ aiResponse }</Toast.Body>
            </Toast>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='purpleHeading'>${stats.accountsReceivable.total !== null ? (stats.accountsReceivable[0].total*1.13).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Accounts Receivable</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='greenHeading'>${stats.currentMonthRevenue.total !== null ? (stats.currentMonthRevenue.total*1.13).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Revenue This Month</p>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='yellowHeading'>${stats.previousMonthRevenue.total !== null ? (stats.previousMonthRevenue.total*1.13).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Revenue Previous Month</p>
                      </Card.Body>
                    </Card>
                  </Col>




                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='purpleHeading'>${stats.currentMonthRevenue.total !== null ? ((stats.currentMonthRevenue.total)*1.13 / stats.customerCount.total.toFixed(2)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Average Per Vehicle</p>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='greenHeading'>${stats.currentMonthRevenue.total !== null ? ((stats.currentMonthRevenue.total*1.13) / moment().format('D')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Average Revenue Per Day</p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='purpleHeading'>${stats.currentMonthRevenue.total !== null ? (((stats.currentMonthRevenue.total*1.13) / moment().format('D')) * 30).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Projected Monthly Revenue</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='greenHeading'>${stats.yearlyRevenue[0].total !== null ? (stats.yearlyRevenue[0].total*1.13).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Year To Date Revenue</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='yellowHeading'>${stats.yearlyRevenue[0].total !== null ? (((stats.yearlyRevenue[0].total*1.13) / moment().dayOfYear()) * 365).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Projected Yearly Revenue</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='purpleHeading'>{stats.customerCount.total !== null ? stats.customerCount.total.toFixed(2) : '0'}</p>
                        <p className='mainLabel'>Vehicles Serviced This Month</p>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='greenHeading'>{stats.prevCustomerCount.total !== null ? stats.prevCustomerCount.total.toFixed(2) : '0'}</p>
                        <p className='mainLabel'>Vehicles Serviced Last Month</p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='purpleHeading'>${stats.costOfGoodsSold.total !== null ? (stats.costOfGoodsSold.total*1.13).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Cost Of Goods Sold</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <p className='greenHeading'>${stats.costOfGoodsSold.total !== null && stats.monthlyRevenueWithDealer.total !== null ? ((stats.monthlyRevenueWithDealer.total*1.13) - (stats.costOfGoodsSold.total*1.13)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>
                        <p className='mainLabel'>Current Monthly Profit</p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className='marginTop20'>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Website Visits Month To Month</p>
                      <Line data={monthlyWebsiteChartData} options={monthlyRevenueOptions} />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Website Visits By Source</p>
                      <Doughnut data={channelVisits} options={pieChartOptions} />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Website Visits By Month</p>
                      <Bar data={visitsByCityData} options={monthlyRevenueOptions} />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Website Visits By Page</p>
                      <List style={{maxHeight: '212px', overflow: 'scroll'}}>
                        {pageVisits.map((pageVisit, index) => {
                          return (
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <Badge max={9999} badgeContent={pageVisit.activeUsers} color="primary"></Badge>
                                </ListItemIcon>
                                <ListItemText primary={pageVisit.channel} />
                              </ListItemButton>
                            </ListItem>
                          )
                        })}
                      </List>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Revenue Month To Month</p>
                      <Line data={monthlyChartData} options={monthlyRevenueOptions} />
                      
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Technician Revenue</p>
                      <Card.Body>                      
                        <Doughnut data={techChartData} options={pieChartOptions} />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Revenue Year To Year</p>
                      <Line data={yearlyChartData} options={monthlyRevenueOptions} />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Revenue Year To Year</p>
                      <Bar data={yearlyChartData} options={monthlyRevenueOptions} />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ border: 'none' }}>
                      <p className='mainCardHeading'>Technician Labour Sold</p>
                      <Card.Body>
                        <Doughnut data={labourStats} options={pieChartOptions} />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
              </Container>
              </>
              :
              <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
              <Triangle
                type="Puff"
                color="#000"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            </div>
            );
}
export default Stats;