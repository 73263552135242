import * as React from "react";
import { Button } from "@/components/ui/button";
import { Clipboard } from 'lucide-react';
import { cn } from "src/utils";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <div className={cn(
      "relative flex items-center rounded-md border-input bg-white text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2",
      className,
    )}>
      <input
        type={type}
        className={cn(
          "h-10 w-full rounded-md border border-slate-200 bg-white pl-3 pr-10 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
          className
        )}
        ref={ref}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />
      <CopyToClipboard text={value}>
        <Button className="absolute right-2 bg-transparent p-0">
          <Clipboard className="w-4 h-4 text-black" />
        </Button>
      </CopyToClipboard>
    </div>
  );
});
Input.displayName = "Input";

export { Input };