import React from 'react';
import Dashboard from '../pages/backoffice/Dashboard.jsx';
import Customers from '../pages/backoffice/Customers';
import ViewCustomer from '../pages/backoffice/ViewCustomer';
import NewQuote from '../pages/backoffice/NewQuote';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import TopBarMenu from '../sections/TopBarMenu';
import Sidebar from '../sections/Sidebar';
import Invoices from '../pages/backoffice/Invoices';
import Quotes from '../pages/backoffice/Quotes';
import ViewInvoice from '../pages/backoffice/ViewInvoice';
import ActiveJobs from '../pages/backoffice/ActiveJobs';
import PrintInvoice from '../pages/backoffice/PrintInvoice'
import PrintOnlineInvoice from '../pages/backoffice/PrintOnlineInvoice';
import Technicians from '../pages/backoffice/Technicians';
import VehicleBillSale from '../pages/backoffice/VehicleBillSale';
import PunchClock from '../pages/backoffice/PunchClock';
import ViewClock from '../pages/backoffice/ViewClock';
import BillsOfSale from '../pages/backoffice/BillsOfSale';
import Store from '../pages/backoffice/Store';
import ViewVehicleBillSale from '../pages/backoffice/ViewVehicleBillSale';
import RinLog from '../pages/backoffice/RinLog';
import Payout from '../pages/backoffice/Payout';
import Orders from '../pages/backoffice/Orders';
import Schedule from '../pages/backoffice/Schedule';
import Notes from '../pages/backoffice/Notes';
import VehiclesForSale from '../pages/backoffice/VehiclesForSale';
import Parts from '../pages/backoffice/Parts';
import Message from '../pages/backoffice/Message';
import MessageCenter from '../pages/backoffice/MessageCenter';
import SalesListings from '../pages/backoffice/SalesListings';
import FinanceApplications from '../pages/backoffice/FinanceApplications.jsx';
import Stats from '../pages/backoffice/Stats';
import ChatGPTPage from '../pages/backoffice/ChatGPT';
import fire from '../config/Firebase';
import Responsibilities from '../pages/backoffice/Responsibilities';
import EditWorkOrder from '../pages/backoffice/EditWorkOrder';
import Settings from '../pages/backoffice/Settings';
import TechSettings from '../pages/backoffice/TechSettings';
import Services from '../pages/backoffice/Services';

function AuthRoutes(props) {

  return (
    <>


      <Router>
      <TopBarMenu />
        <div id="wrapper">
          <Sidebar />
          <main id="page-content-wrapper" role="main" style={{height: `${window.innerHeight-106}px`}}>
            <Switch>
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/stats" exact component={Stats} />
              <Route path="/technicians" exact component={Technicians} />
              <Route path="/settings" exact component={Settings} />
              <Route path="/tech-settings" exact component={TechSettings} />
              <Route path="/notes" exact component={Notes} />
              <Route path="/customers" exact component={Customers} />
              <Route path="/customers/view/:id" exact component={Customers} />
              <Route path="/customers/:id" exact component={ViewCustomer} />
              <Route path="/invoices" exact component={Invoices} />
              <Route path="/invoices/:id" exact component={EditWorkOrder} />
              <Route path="/invoices/:id/new" exact component={NewQuote} />
              <Route path="/quotes" exact component={Quotes} />
              <Route path="/jobs" exact component={ActiveJobs} />
              <Route path="/print/:id" exact component={PrintInvoice} />
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/print/order/:id" exact component={PrintOnlineInvoice} />
              : null}
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/vehicles/for/sale" exact component={VehiclesForSale} />
              : null}
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/vehicle/sell" exact component={VehicleBillSale} />
              : null}
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/vehicles/sold" exact component={BillsOfSale} />
              : null}
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/store" exact component={Store} />
              : null}  
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/rin-log" exact component={RinLog} />
              : null}
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/vehicle/sell/:id/view" exact component={ViewVehicleBillSale} />
              : null}
              <Route path="/payout" exact component={Payout} />
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/orders" exact component={Orders} />
              : null}
              <Route path="/clock" exact component={PunchClock} />
              <Route path="/clock/view" exact component={ViewClock} />
              <Route path="/schedule" exact component={Schedule} />
              <Route path="/parts" exact component={Parts} />
              <Route path="/services" exact component={Services} />
              <Route path="/message/:id" exact component={MessageCenter} />
              <Route path="/messages" exact component={MessageCenter} />
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/sales-listings" exact component={SalesListings} />
                : null}
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/finance-application" exact component={FinanceApplications} />
              : null}
              {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
              <Route path="/responsibilities" exact component={Responsibilities} />
              : null}
              <Route path="/ai" exact component={ChatGPTPage} />
              <Redirect to="/dashboard" />
            </Switch>
          </main>
        </div> 
      </Router>

    </>

  );

}

export default AuthRoutes;
