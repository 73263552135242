import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import TopBarMenu from '../sections/TopBarMenu';
import Sidebar from '../sections/Sidebar';
import Settings from '../pages/backoffice/Settings';

function TrialExpiredRoutes(props) {

  return (
    <>


      <Router>
      <TopBarMenu />
        <div id="wrapper">
          <Sidebar />
          <main id="page-content-wrapper" role="main" style={{height: `${window.innerHeight-106}px`}}>
            <Switch>
              <Route path="/settings" exact component={Settings} />
              <Redirect to="/settings" />
            </Switch>
          </main>
        </div> 
      </Router>

    </>

  );

}

export default TrialExpiredRoutes;
