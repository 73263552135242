import React, {useEffect, useState} from 'react';
import { Container, Col, Card, Row } from 'react-bootstrap';
import axios from 'axios';
import '../style/Invoice.css';
import logo from '../assets/redline-logo-black.png';
import * as moment from 'moment';

function PrintInvoice(props) {
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(false);
  const [parts, setParts] = useState(0);
  const [labour, setLabour] = useState(0);
  
  
  useEffect(() => {

    const getInvoice = async () => {
      const idDecoded = Buffer.from(props.match.params.id, 'base64').toString('ascii');
      const invoiceResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/invoices/${idDecoded}/get`);  
      console.log(invoiceResponse);
      console.log(idDecoded);
      setInvoice(invoiceResponse.data);
      let totalParts = 0;
      let totalLabour = 0;

      const itemList = invoiceResponse.data.invoiceItems.map((item, index) => {
        
        if(item.service_type === 'Repair Labour' || item.service_type === 'Specialty Repair Labour' || item.service_type === 'Tire Installation' || item.service_type === 'Alignment' || item.service_type === 'Mechanical Diagnosis' || item.service_type === 'Electrical Diagnosis'){
          totalLabour += item.amount_charged;
        }

        if(item.service_type === 'Parts'){
          totalParts += item.amount_charged;
        }

        return (<Row style={{marginTop: '20px', background: index % 2 === 0 ? '#fff' : '#eee'}}>
          <Col sm={12}><span className="text-inverse"><b>Description:</b> {item.service_type}</span><br /><small>{item.description}</small></Col>
          <Col sm={12}><b>Price Per Unit:</b> ${item.price_per_unit}</Col>
          <Col sm={12}><b>Quantity:</b> {item.quantity}</Col>
          <Col sm={12}><b>Total Amount:</b> ${item.amount_charged}</Col>
        </Row>);
      });
      
      setLabour(totalLabour);
      setParts(totalParts);
      setItems(itemList);
      
      setLoading(true);
      console.log(invoiceResponse.data);
    }
    getInvoice();
    
  }, [props.match.params.id]);

  return (
    loading ?
    <>
      <Container fluid style={{background: '#FFF'}}>
        <Col>
          <Card className="print-invoice" style={{overflowX: 'hidden', boxShadow: 'none'}}>
          
            <div className="invoice-company text-inverse f-w-600">
                <span className="pull-right hidden-print">
                </span>
                <img src={logo} alt="Redline Autohaus & Performance" style={{ width: '320px', marginLeft: '-30px' }}></img>
            </div>

            <div className="invoice-header">
                <h3>{invoice.status === 'Invoice' || invoice.status === null || invoice.status === 'Delivered' ? 'INVOICE' : 'QUOTE'}</h3>
                <Row>
                  <Col md={4}>
                    <small>from</small>
                    <address className="m-t-5 m-b-5">
                        <strong className="text-inverse">Redline Autohaus & Performance</strong><br />
                        3086 Jefferson Blvd<br />
                        Windsor, N8T 3G9<br />
                        Phone: (519) 997-2356
                    </address>
                  </Col>
                  <Col md={4}>
                    <small>to</small>
                    <address className="m-t-5 m-b-5">
                        <strong className="text-inverse">{invoice.vehicle.customer.name}</strong><br />
                        {invoice.vehicle.customer.phone_number}<br /> 
                        {invoice.vehicle.year} {invoice.vehicle.make} {invoice.vehicle.model}<br /> <b>VIN:</b> {invoice.vehicle.vin}<br />
                        <b>Plate:</b> {invoice.vehicle.plate}<br />
                        <b>Odometer:</b> {invoice.odometer}
                    </address>
                  </Col>
                  <Col md={4}>
                    <small>Details</small>
                    <div className="date text-inverse m-t-5">{moment(invoice.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</div>
                    <div className="invoice-detail">
                        Invoice #{invoice.id+1000}<br />
                        HST # 757463518RT0001
                    </div>
                  </Col>
                </Row>
              </div>
              {items}
              <Row style={{marginTop:'20px', marginBottom: '20px'}} className="invoice-price-row">
                {invoice.shop_supplies ? 
                  <>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>PARTS: </b>
                    <span className="text-inverse">${parts.toFixed(2)}</span>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>LABOUR: </b>
                    <span className="text-inverse">${labour.toFixed(2)}</span>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>SUBTOTAL: </b>
                    <span className="text-inverse">${invoice.final_amount_charged.toFixed(2)}</span>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <i className="fa fa-plus text-muted"></i>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>DISPOSAL FEE/SHOP SUPPLIES: </b>
                    <span className="text-inverse">$4.99</span>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>HST (13%): </b>
                    <span className="text-inverse">${(invoice.final_amount_charged*0.13).toFixed(2)}</span>
                  </Col>
                  </>
                : 
                <>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>PARTS: </b>
                    <span className="text-inverse">${parts.toFixed(2)}</span>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>LABOUR: </b>
                    <span className="text-inverse">${labour.toFixed(2)}</span>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>SUBTOTAL: </b>
                    <span className="text-inverse">${invoice.total_amount_charged.toFixed(2)}</span>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <i className="fa fa-plus text-muted"></i>
                  </Col>
                  <Col xs={12} sm={6} className="sub-price">
                    <b>HST (13%): </b>
                    <span className="text-inverse">${(invoice.total_amount_charged*0.13).toFixed(2)}</span>
                  </Col>
                </>
                }
              </Row>
              <div className="invoice-price">
                {invoice.shop_supplies ?
                <div className="invoice-price-right">
                    <small>TOTAL</small> <span className="f-w-600">${((invoice.final_amount_charged)*1.13).toFixed(2)}</span>
                </div>
                :
                <div className="invoice-price-right">
                    <small>TOTAL</small> <span className="f-w-600">${((invoice.total_amount_charged)*1.13).toFixed(2)}</span>
                </div>
                }
              </div>

              <p style={{marginTop:'30px'}}>Customer Signature: ___________________</p>
              <p>Notes:</p>
              <p>{invoice.notes}</p>
              <div className="invoice-note">
                  * Make all cheques payable to Redline Automotive Service<br />
                  * Payment is due upon receiving invoice<br />
                  * If you have any questions concerning this invoice, contact  519-997-2356
              </div>
            
              <div className="invoice-footer">
                  <p className="text-center m-b-5 f-w-600">
                    THANK YOU FOR YOUR BUSINESS
                  </p>
              </div>
          </Card>
        </Col>
      </Container>
      </>
      :
      <></>
  );
}

export default PrintInvoice;

