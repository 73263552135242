import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, Table, Button, Form, InputGroup, FormControl, Accordion, ListGroup, Nav, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Triangle} from 'react-loader-spinner';
import moment from 'moment';
import momentLocalizerWidget from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import { CookiesProvider, useCookies } from "react-cookie";
import unknownCar from '../../assets/unknown.png';


const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";



moment.locale('en');
momentLocalizerWidget();
const localizer = momentLocalizer(moment);
let currentTechnician = '';



function Customers(props) {
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState('');
  const [showVin, setShowVin] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [vinBtn, setVinBtn] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [appointmentVehicles, setAppointmentVehicles] = useState();
  const [techs, setTechs] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState({});
  const [customerPhone, setCustomerPhone] = useState('');
  const [totalSpent, setTotalSpent] = useState(0);
  const [quoteCount, setQuoteCount] = useState('');
  const [showCreate, setShowCreate] = useState(false);
  const [showNewVehicle, setShowNewVehicle] = useState(false);
  const [socket, setSocket] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["technician"]);
  
  const createClose = () => setShowCreate(false);
  const openCreate = () => {setShowCreate(true);};
  const openNewVehicle = () => {setShowNewVehicle(true);}
  const newVehicleClose = () => setShowNewVehicle(false);
  
  useEffect(() => {

    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    const viewCustomer = async (customerId) => {
      newSocket.emit('updateCustomers', { customerId: customerId, idToken: await fire.auth().currentUser.getIdToken() });
      setModalState('View');
    }

    const changeVin = async (evt, id) => {
      newSocket.emit('changeVin', {newVin: evt.target.value, id})
    }

    if(typeof props.match.params.id !== 'undefined'){
      viewCustomer(props.match.params.id);
    }

    const createNewQuote = async (evt, vehicleId) => {
      evt.preventDefault();
      console.log('create new quote');

      let filters;

      if(cookies.technician && cookies.technician.length > 0){
        filters = {
          vehicle_id: vehicleId, 
          idToken: await fire.auth().currentUser.getIdToken(),
          technician: cookies.technician[0].name
        };
      }else{
        filters = {
          vehicle_id: vehicleId, 
          idToken: await fire.auth().currentUser.getIdToken()
        };
      }

      newSocket.emit('newQuote', filters);
    }

    newSocket.on("newQuote", (data) => {
      props.history.push(`/invoices/${data.id}`);
    });

    newSocket.on('getTechnicians', async (data) => {
      if (data.length > 0) { 
        setTechs(data);
      }
    });
    
    newSocket.on('updateCustomers', async (data) => {
        let totalSpendAmount = 0;

        data.vehicles.map((vehicle) => {
          vehicle.invoices.map((invoice) => {
            totalSpendAmount = totalSpendAmount+invoice.total_amount_charged;
          });
        });
        setTotalSpent(totalSpendAmount);

        setCustomer(data);

        let quoteCountVar = 0;
        for(let car of data.vehicles){
          for(let invoice of car.invoices){
            quoteCountVar++;
          }
        }
        setQuoteCount(quoteCountVar);

        setVehicles(data.vehicles.map((vehicle) =>
          <>
            <hr />
            <Row className="yearMakeModelRow">
              <Col md="3">{ vehicle.photo ? <img src={vehicle.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px', marginTop: '35px'}}></img> : <img src={unknownCar} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '-20px' , borderRadius: '10px'}}></img> }</Col>
              <Col md="9">
                <Row className="firstCustomerVehiclesRow">
                  <Col>
                    <Form.Control className="kanbanVehicleModel" defaultValue={vehicle.model}  />                      
                  </Col>
                  <Col>
                    <Row>
                      {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                      <Col>
                        <Link className='newQuoteLink' onClick={(evt) => setVehicleForSale(evt, vehicle.id, newSocket)}>For Sale</Link>
                      </Col>
                      :
                      null
                      }
                      <Col>
                        <Link className='newQuoteLink' onClick={(evt) => createNewQuote(evt, vehicle.id)}>New Quote</Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col md="4">
                        <Form.Control className="kanbanMakeYear" defaultValue={vehicle.year} />
                      </Col>
                      <Col md="8">
                        <Form.Control className="kanbanMakeYear" defaultValue={vehicle.make} />
                      </Col>
                    </Row>
                    <Row>
                      <Col className='padding-left-15'>
                        <Form.Control className='vinFormControl' onChange={ (evt) => changeVin(evt, vehicle.id)} name="vin" type="text" placeholder="VIN" defaultValue={vehicle.vin} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          
          
            <ListGroup>
              {vehicle.invoices.map((invoice) => 
              <ListGroup.Item className='paddingNone'>{invoice.status === 'Quote' ? 'Quote' : 'Job'} #{invoice.id+1000} - {moment(invoice.createdAt).format('MMM DD YYYY')} - ${invoice.total_amount_charged}
              <Link to={`/invoices/${invoice.id}`} className="float-right">
                <Button>View</Button>
              </Link>
            </ListGroup.Item>)}
            </ListGroup>
          </>
        ));
        setAppointmentVehicles(data.vehicles.map((vehicle) =>
          <option value={vehicle.id}>{vehicle.year} {vehicle.make} {vehicle.model}</option>
        ));
    });

    newSocket.on('createCustomer', async (createCustomerResponse) => {
      viewCustomer(createCustomerResponse.id);
    });

    newSocket.on('getAppointments', async (data) => { 
      console.log(data);
      const calendarEventsArr = [];
      for (let item of data) {
        console.log(item);
        calendarEventsArr.push({color: item.technician ? item.technician.color : '#007bff', id: item.id, title: item.description, allDay: false, start: moment(item.appointment_date).toDate(), end: item.appointment_date_end ? moment(item.appointment_date_end).toDate() : moment(item.appointment_date).toDate()});
      }
      setCalendarEvents(calendarEventsArr);
      setLoading(true);
    });

    newSocket.on('searchCustomer', async (searchForCustomerResponse) => { 
      console.log(searchForCustomerResponse);
      const customerListMap = searchForCustomerResponse.map((customer) => {
        return <tr>
          <td>{customer.id}</td>
          <td>
            {customer.name}
          </td>
          <td>{customer.phone_number}</td>
          <td>{moment(customer.createdAt).fromNow()}</td>
          <td><Nav.Link className='viewCustomerLink' onClick={() => viewCustomer(customer.id)}>View</Nav.Link></td>
        </tr>
      });

      setCustomerList(customerListMap);

    });

    newSocket.on('getCustomers', async (customerResponse) => { 
      if (customerResponse) { 
        const customerListMap = customerResponse.map((customer) => {
          return <tr>
            <td>{customer.id}</td>
            <td>
              {customer.name}
            </td>
            <td>{customer.phone_number}</td>
            <td>{moment(customer.createdAt).fromNow()}</td>
            <td><Nav.Link className='viewCustomerLink' onClick={() => viewCustomer(customer.id)}>View</Nav.Link></td>
          </tr>
        });

        setCustomerList(customerListMap);
        
        newSocket.emit('getAppointments', await fire.auth().currentUser.getIdToken());
        
      }
    });

    const getAllCustomersData = async () => {

      newSocket.emit('getCustomers', await fire.auth().currentUser.getIdToken());
      newSocket.emit('getTechnicians', await fire.auth().currentUser.getIdToken());

    }
    
    setModalState('Search');
    getAllCustomersData();
    
    return () => {
      newSocket.disconnect();
    };

  }, [props.match.params.id, setSocket]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      name: event.target.name.value,
      phone: event.target.phone.value,
      vin: showVin ? event.target.vin.value : null,
      year: event.target.year.value,
      make: event.target.make.value,
      model: event.target.model.value,
      idToken: await fire.auth().currentUser.getIdToken()
    };

    socket.emit('createCustomer', payload);

  }

  const searchForCustomer = async (evt) => {

    setCustomerPhone(evt.target.value);
    socket.emit('searchCustomer', { search: evt.target.value, idToken: await fire.auth().currentUser.getIdToken() });

  }

  const addVin = (event) => {
    event.preventDefault();
    setShowVin(true);
    setVinBtn(false);
  }

  const addVehicle = async (event) => {
    event.preventDefault();
    const payload = {
      customer_id: event.target.customer_id.value,
      vin: showVin ? event.target.vin.value : null,
      year: event.target.year.value,
      make: event.target.make.value,
      model: event.target.model.value,
      idToken: await fire.auth().currentUser.getIdToken()
    };
    
    socket.emit('addVehicle', payload);
    setVehicles([]);
    
  }

  const setVehicleForSale = async (evt, vehicleId, socket) => {
    evt.preventDefault();
    socket.emit('setVehicleForSale', { vehicleId: vehicleId, idToken: await fire.auth().currentUser.getIdToken() });
    alert('Vehicle Status Has Changed!');
  }

  const changePhoneNumber = async (evt, id) => {
    socket.emit('changePhoneNumber', {newNumber: evt.target.value, id})
  }

  const changeName = async (evt, id) => {
    socket.emit('changeName', {newName: evt.target.value, id})
  }
  
  const techSelectId = techs.map((tech) =>
    currentTechnician == tech.id ?  <option value={tech.id} selected>{tech.name}</option> : <option value={tech.id} >{tech.name}</option>
  );

  
  const techLegend = techs.map((tech) =>
  
    <ListGroup.Item><div style={{ width: '15px', backgroundColor: tech.color, height: '15px', borderRadius: '15px', display: 'inline-block'}}></div> <span>{ tech.name }</span></ListGroup.Item>

  );

  const createAppointment = async (event) => {

    event.preventDefault();
    const payload = {
      date: event.target.appointment_date.value,
      duration: event.target.appointment_duration.value,
      vehicle_id: event.target.vehicle_id.value,
      description: event.target.description.value,
      technician: event.target.technician.value,
      idToken: await fire.auth().currentUser.getIdToken()
    };
    
    socket.emit('createAppointment', payload);
    createClose();
  }

  return (
    loading ?
    <>
      <Container fluid className='container-customer-info'>
        {modalState === 'Search' ?
          <>
            <Row>
              <Col>
                <p id='searchCustomerHeading'>Search</p>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormControl
                  placeholder="Search By Phone Number"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  id="searchCustomer"
                  onKeyUp={(evt) => searchForCustomer(evt)}
                />
              </Col>
              <Col md="8">
                <Button id="createCustomerButton" onClick={() => openCreate()}>Create Customer</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card id='customersCard'>
                  <Card.Body id='customerListBody'>
                    <p id='customerHeading'>Customers</p>
                    <Table striped bordered hover responsive style={{marginBottom: '0px'}}>
                      <thead>
                        <tr>
                          <th>Customer #</th>
                          <th>Name</th>
                          <th>Phone Number</th>
                          <th>Customer Since</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerList}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
       
            <Modal show={showCreate} onHide={createClose}>
              <Modal.Body>
                <Form onSubmit={(evt) => handleSubmit(evt)}>
                  <Row>
                    <Col>
                      <p className='modalHeading'>Create New Customer</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <span className='modalFormLabel'>Name:</span>
                        <Form.Control className='createCustomerFormInputs' name="name" type="text" required="required" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <span className='modalFormLabel'>Phone:</span>
                        <Form.Control className='createCustomerFormInputs' name="phone" type="text" value={customerPhone} required="required" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <span className='modalFormLabel'>Year:</span>
                        <Form.Control className='createCustomerFormInputs' name="year" type="text" required="required" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <span className='modalFormLabel'>Make:</span>
                        <Form.Control className='createCustomerFormInputs' name="make" type="text" required="required" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <span className='modalFormLabel'>Model:</span>
                        <Form.Control className='createCustomerFormInputs' name="model" type="text" required="required" />
                      </Form.Group>
                    </Col>
                    <Col>
                      {showVin ?
                        <Form.Group>
                          <span className='modalFormLabel'>VIN:</span>
                          <Form.Control className='createCustomerFormInputs' name="vin" type="text" />
                        </Form.Group>
                        :
                        ''
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button variant="primary" type="submit">
                        Create Customer
                      </Button>
                      {
                        vinBtn ? 
                        <Button variant="success" type="submit" onClick={(evt) => addVin(evt)} style={{ marginLeft: '20px' }}>
                          Add Vin
                        </Button> 
                        : 
                        ''
                      }
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
            </Modal>
              
          </>
          : 
          <Row>
            <Col>
              <Row>
                <Col>
                  <p id='searchCustomerHeading' onClick={() => setModalState('Search')}>
                    Customer Information
                  </p>
                </Col>
                <Col>
                  <Button id="createCustomerButton" onClick={() => openCreate()}>Create Appointment</Button>
                  <Button id='addVehicle' onClick={() => openNewVehicle()}>New Vehicle</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card id='customerInformationCard'>
                    <Card.Body>
                      <Row>
                        <Col>
                          <span className='customerInfoLabel'>Name:</span>
                          <FormControl onChange={(evt) => changeName(evt, customer.id)} defaultValue={customer.name} className='customerInfoFormControl' />
                        </Col>
                        <Col>
                          <span className='customerInfoLabel'>Phone Number:</span>
                          <FormControl onChange={(evt) => changePhoneNumber(evt, customer.id)} defaultValue={customer.phone_number} className='customerInfoFormControl' />
                        </Col>
                        <Col>
                          <span className='customerInfoLabel'>Total Spent:</span>
                          <p className='customerInfoHeading'>${totalSpent}</p>
                        </Col>
                        <Col>
                          <span className='customerInfoLabel'>Customer Since:</span>
                          <p className='customerInfoHeading'>{moment(customer.createdAt).fromNow()}</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card className='border-radius-4'>
                    <Card.Body>
                      <p className="mainCardPurpleHeading">Customer Vehicles</p>
                      <Accordion defaultActiveKey="0">
                        {vehicles}
                      </Accordion>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="8">
                    
                      <Card className='border-radius-4'>
                        <Card.Body className='techLegendCardBody'>
                          <p className="mainCardPurpleHeading">Technicians</p>
                          <ListGroup horizontal>
                            {techLegend}
                          </ListGroup>
                        </Card.Body>
                      </Card>
                     
                    
                      <Card className='calendarCard border-radius-4'>
                        <Card.Body>
                        <p className="mainCardPurpleHeading">Upcoming Appointments</p>
                        <Calendar
                          localizer={localizer}
                          events={calendarEvents}
                          defaultView={Views.DAY}
                          min={moment().set('hour', 8).set('minutes', 0).toDate()}
                          max={moment().set('hour', 17).set('minutes', 0).toDate()}
                          startAccessor="start"
                          endAccessor="end"
                          eventPropGetter={event => ({
                            style: {
                              backgroundColor: event.color,
                            },
                          })}
                        />
                        </Card.Body>
                      </Card>
                </Col>
              </Row>
              <Modal show={showNewVehicle} onHide={newVehicleClose}>
                <Row>
                  <Col>
                    <p className='modalHeading'>Add New Vehicle</p>
                  </Col>
                </Row>
                <Form onSubmit={(evt) => addVehicle(evt)}>
                  {showVin ?
                    <Col>
                      <Form.Group>
                        <span className='modalFormLabel'>VIN:</span>
                        <Form.Control name="vin" type="text" className='createCustomerFormInputs' />
                      </Form.Group>
                    </Col>
                    :
                    ''
                  }
                  <Row>
                    <Col>
                      <Form.Control name="customer_id" type="hidden" value={customer.id} className='createCustomerFormInputs' />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className='modalFormLabel'>Year:</span>
                    <Form.Control name="year" type="text" className='createCustomerFormInputs' />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className='modalFormLabel'>Make:</span>
                    <Form.Control name="make" type="text" className='createCustomerFormInputs' />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className='modalFormLabel'>Model:</span>
                      <Form.Control name="model" type="text" className='createCustomerFormInputs' />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button variant="primary" type="submit">
                        Add Vehicle
                      </Button>
                      {vinBtn ? <Button variant="success" type="submit" onClick={(evt) => addVin(evt)} style={{ marginLeft: '20px' }}>
                        Add Vin
                      </Button> : ''}
                    </Col>
                  </Row>
                </Form>
              </Modal>
              <Modal show={showCreate} onHide={createClose}>
                <Row>
                  <Col>
                    <p className='modalHeading'>Create New Appointment</p>
                  </Col>
                </Row>
                <Form onSubmit={(evt) => createAppointment(evt)}>
                  <Row>
                    <Col>
                      <span className='modalFormLabel'>Date & Time:</span>
                      <DateTimePicker type="text" name="appointment_date" required className='calendarInputStyle' />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className='modalFormLabel'>Service Description:</span>
                      <Form.Control name="description" required type="text" className='createCustomerFormInputs' />
                    </Col>
                    <Col>
                      <span className='modalFormLabel'>Vehicle:</span>
                      <select name="vehicle_id" required className="form-control createCustomerFormInputs">
                        {appointmentVehicles}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className='modalFormLabel'>Technician Assigned:</span>
                      <select  name="technician" className="form-control createCustomerFormInputs">
                        {techSelectId}
                      </select>
                    </Col>
                    <Col>
                      <span className='modalFormLabel'>Duration Of Job:</span>
                      <Form.Control type='number' name="appointment_duration" required className='createCustomerFormInputs' />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button variant="primary" type="submit">
                        Add Appointment
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Col>
          </Row>
        }
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#4439bf"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
    </div>
  );
}

export default Customers;

