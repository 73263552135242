import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area"
import { Input } from "@/components/ui/copy-input";
import { Label } from "@/components/ui/label";


import "../../style/Listings.css";

function FinanceApplications(props) {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);

  const getApplications = async () => {
    const financeAppRes = await axios.get('https://redline-autohaus-api.herokuapp.com/sales/finance-applications/get');
    setApplications(financeAppRes.data);
    console.log(financeAppRes.data);
    setLoading(true);
  }

  const sendApplication = async (id) => {
    const financeAppRes = await axios.get(`https://redline-autohaus-api.herokuapp.com/sales/finance-applications/${id}/send`);
    alert(financeAppRes.data);
  }

  useEffect(() => {
    
    getApplications();
    
  }, []);

  const applicationList = applications.map((application) =>
    <Card>
      <CardHeader>
        <CardTitle>
          {application.firstName} {application.lastName} - {application.creditRating}
        </CardTitle>
        <CardDescription>
          Address: {application.customerLocation}
        </CardDescription>
        <CardDescription>
          Phone Number: {application.phoneNumber}
        </CardDescription>
        <Button onClick={() => sendApplication(application.id)} className="float-right w-48">Mark Completed</Button>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] w-[100%] rounded-md border p-4">
          <div className="grid grid-cols-2">
            <div className="space-y-1">
              <Label htmlFor="carType">carType</Label>
              <Input id="carType" value={application.carType} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="budget">budget</Label>
              <Input id="budget" value={application.budget} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="tradeIn">tradeIn</Label>
              <Input id="tradeIn" value={application.tradeIn} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="creditRating">creditRating</Label>
              <Input id="creditRating" value={application.creditRating} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="income">income</Label>
              <Input id="income" value={application.income} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="incomeType">incomeType</Label>
              <Input id="incomeType" value={application.incomeType} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="annualSalary">annualSalary</Label>
              <Input id="annualSalary" value={application.annualSalary} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="hourlyWage">hourlyWage</Label>
              <Input id="hourlyWage" value={application.hourlyWage} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="averageHours">averageHours</Label>
              <Input id="averageHours" value={application.averageHours} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="monthlyIncome">monthlyIncome</Label>
              <Input id="monthlyIncome" value={application.monthlyIncome} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="employmentStatus">employmentStatus</Label>
              <Input id="employmentStatus" value={application.employmentStatus} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="yearsEmployed">yearsEmployed</Label>
              <Input id="yearsEmployed" value={application.yearsEmployed} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="monthsEmployed">monthsEmployed</Label>
              <Input id="monthsEmployed" value={application.monthsEmployed} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="companyName">companyName</Label>
              <Input id="companyName" value={application.companyName} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="jobTitle">jobTitle</Label>
              <Input id="jobTitle" value={application.jobTitle} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="customerLocation">customerLocation</Label>
              <Input id="customerLocation" value={application.customerLocation} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="monthsAtAddress">monthsAtAddress</Label>
              <Input id="monthsAtAddress" value={application.monthsAtAddress} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="yearsAtAddress">yearsAtAddress</Label>
              <Input id="yearsAtAddress" value={application.yearsAtAddress} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="homeOwnership">homeOwnership</Label>
              <Input id="homeOwnership" value={application.homeOwnership} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="monthlyRentMortgage">monthlyRentMortgage</Label>
              <Input id="monthlyRentMortgage" value={application.monthlyRentMortgage} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="citizenshipStatus">citizenshipStatus</Label>
              <Input id="citizenshipStatus" value={application.citizenshipStatus} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="driversLicenseStatus">driversLicenseStatus</Label>
              <Input id="driversLicenseStatus" value={application.driversLicenseStatus} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="dateOfBirth">dateOfBirth</Label>
              <Input id="dateOfBirth" value={application.dateOfBirth} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="firstName">firstName</Label>
              <Input id="firstName" value={application.firstName} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="lastName">lastName</Label>
              <Input id="lastName" value={application.lastName} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="email">email</Label>
              <Input id="email" value={application.email} />
            </div>
            <div className="space-y-1">
              <Label htmlFor="phoneNumber">phoneNumber</Label>
              <Input id="phoneNumber" value={application.phoneNumber} />
            </div>
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1  gap-4 mb-6">    
          {applicationList}
        </div>
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default FinanceApplications;

