import React, {useEffect, useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import {Navbar, NavDropdown, Toast, Form, FormControl } from 'react-bootstrap';
import logo from '../assets/autoclever.png';
import aiAssistantImage from '../assets/ai-assistant.png';
import "../style/topBar.css";
import '../style/Custom.css';
import fire from '../config/Firebase';

import socketIOClient, { io } from "socket.io-client";
const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

function TopBarMenu(){

    const [unread, setUnread] = useState(0);
    const [aiResponse, setAiResponse] = useState('');
    const [show, setShow] = useState(false);
    const [socket, setSocket] = useState(null);

    let streamResponse = '';
    useEffect(() => {
        const newSocket = socketIOClient(ENDPOINT);
        setSocket(newSocket);

        newSocket.emit('getUnread');
        newSocket.on('getUnread', async (data) => {
            setUnread(data);
        });

        newSocket.on('error', async (data) => {
            alert(data);
        });

        newSocket.on('askAiAssistant', async (data) => {
            if(!show){
                setShow(true);                
            }
            streamResponse+=data;
            setAiResponse(streamResponse);
            console.log(streamResponse);
        });

        newSocket.on('streamDone', async (data) => {
            streamResponse = '';
        });

        return () => {
            newSocket.disconnect();
        };

    }, [setSocket]);

    const fetchResponse = async (event) => {
        event.preventDefault();
        setAiResponse('');
        console.log(event.target.children[0].value);
        socket.emit('askAiAssistant', event.target.children[0].value);
    }

    const toggleShow = () => setShow(false);
                

    return (
        <>
        <Navbar expand="lg" fixed="top" >
            <Navbar.Brand href="#home"><img src={logo} className="logo" alt="Redline Autohaus & Performance"></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Navbar.Collapse className="justify-content-end">
                <Form inline="true" onSubmit={(event) => fetchResponse(event)}>
                    <FormControl id="aiPrompt" type="text" placeholder="Hey I'm your AI assistant, ask me anything" className="mr-sm-2" />
                </Form>
                <Navbar.Text id="userSettings">
                <img src={aiAssistantImage} className="aiAssistant" alt="AI Assistant"></img>
                Notifications
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{unread}</span>
                </Navbar.Text>
                <NavDropdown id="basic-nav-dropdown" inline="true" title="">
                    <Link className='dropdown-item' to="/messages" style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}>Messages<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{unread}</span></Link>
                    <Link className='dropdown-item' to="/services">Services</Link>
                    <Link className='dropdown-item' to="/technicians" style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}>Technicians</Link>
                    <Link className='dropdown-item' to="/payout" style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}>Flat Rate Payout Report</Link>
                    <Link className='dropdown-item' to="/clock/view">Punch Clock Logs</Link>
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' to="/responsibilities" style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}>Responsibilities</Link>
                    :
                    <></>
                    }
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' to="/sales-listings" style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}>Sales Listings</Link>
                    :
                    <></>
                    }
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' to="/finance-application" style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}>Finance Applications</Link>
                    :
                    <></>
                    }
                    {/* {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' to="/vehicles/for/sale" style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}>Sales Inventory</Link>
                    :
                    <></>
                    } */}
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}} to="/vehicle/sell">New BOS</Link>
                    :
                    <></>
                    }
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}} to="/vehicles/sold">View All BOS</Link>
                    :
                    <></>
                    }
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}} to="/rin-log">RIN Log</Link>
                    :
                    <></>
                    }
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}} to="/store">Online Store</Link>
                    :
                    <></>
                    }
                    {fire.auth().currentUser.email === "info@redlineautomotiveservice.ca" ?
                    <Link className='dropdown-item' style={{color: "rgba(0,0,0,.5)", textDecoration: 'none'}}  to="/orders">Online Orders</Link>
                    :
                    <></>
                    }
                    {/* <NavDropdown.Item href="#action/3.2">Add Technician</NavDropdown.Item> */}
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                </NavDropdown>
            </Navbar.Collapse>
        </Navbar.Collapse>
        </Navbar>
        <Toast id="aiResponse" show={show} onClose={() => {toggleShow()}}>
            <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
            <strong className="mr-auto">ALL-E:</strong>
            </Toast.Header>
            <Toast.Body>{ aiResponse }</Toast.Body>
        </Toast>
        </>
    );

}

export default TopBarMenu;