import React from 'react';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Register from '../pages/Register';
import PublicInvoice from '../pages/PublicInvoice';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function GuestRoutes() {

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/public/invoice/:id/view" exact component={PublicInvoice} />
      </Switch>
    </Router>
  );

}

export default GuestRoutes;
