import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import { SwatchesPicker } from 'react-color';


const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";


function Services(props) {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [serviceToEdit, setServiceToEdit] = useState('');
  const [systemMessage, setSystemMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const createClose = () => setShowCreate(false);
  const openCreate = () => setShowCreate(true);

  const closeError = () => setShowError(false);

  const editClose = () => setShowEdit(false);
  const openEdit = (service) => {setServiceToEdit(service); setShowEdit(true);};
  
  useEffect(() => {

    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    const getServices = async () => {
      newSocket.emit('getServices', await fire.auth().currentUser.getIdToken());
    }
    getServices();

    newSocket.on('deleteService', async (data) => {
      if(data.length > 0){
        setServices(data);
      }
      setLoading(true);
    });

    newSocket.on('error', (data) => {
      setSystemMessage(data);
      setShowError(true);
    });

    newSocket.on('createService', async (data) => {
      if(data.length > 0){
        setServices(data);
        createClose();
      }
    });

    newSocket.on('editService', async (data) => {
      if(data.length > 0){
          setServices(data);
          editClose();
      }
    });

    newSocket.on('getServices', async (data) => {
      if(data.length > 0){
        setServices(data);
      }
      setLoading(true);
    });

    return () => {
      newSocket.disconnect();
    }

  }, [setSocket]);
  
  const serviceList = services.map((service) =>
    <tr>
      <td>{service.service_name}</td>
      <td>{service.service_rate_per_unit}</td>
      <td><Button className="btn-success" onClick={() => openEdit(service)}>Edit</Button></td>
      <td><Button className="btn-danger" onClick={() => deleteService(service.id)}>Delete</Button></td>
    </tr>
  );
 
  const handleEditSubmit = async (evt, id) => {
    evt.preventDefault();

    const service_name = evt.target.service_name.value;
    const service_rate_per_unit = evt.target.service_rate_per_unit.value;
    const technician_pay_per_unit = evt.target.technician_pay_per_unit.value;

    const payload = {
      idToken: await fire.auth().currentUser.getIdToken(),
      service_name,
      service_rate_per_unit,
      technician_pay_per_unit,
      id
    };


    socket.emit('editService', payload);
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const service_name = evt.target.service_name.value;
    const service_rate_per_unit = evt.target.service_rate_per_unit.value;
    const technician_pay_per_unit = evt.target.technician_pay_per_unit.value;

    const payload = {
      idToken: await fire.auth().currentUser.getIdToken(),
      service_name,
      service_rate_per_unit,
      technician_pay_per_unit
    };

    socket.emit('createService', payload);
    
  }

  const deleteService = async(id) => {
    const payload = {
      idToken: await fire.auth().currentUser.getIdToken(),
      id
    };

    socket.emit('deleteService', payload);
    setLoading(false);
  }
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button onClick={e => openCreate()}>Add Service</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Services</Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Service Name</th>
                      <th>Rate Per Unit</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showCreate} onHide={createClose}>
        <Row>
          <Col>
            <p className='modalHeading'>Add New Service</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={(evt) => handleSubmit(evt)}>
              <Form.Group>
                <Form.Control name="service_name" type="text" placeholder="Service Name" required />
              </Form.Group>
              <Form.Group>
                <Form.Control name="service_rate_per_unit" type="text" placeholder="Service Rate Per Unit/Hour" required />
              </Form.Group>
              <Form.Group>
                <Form.Control name="technician_pay_per_unit" type="text" placeholder="Flat Rate Payout Per Unit (i.e. 0.5 = half hour per unit billed)" required />
              </Form.Group>
              <Button variant="primary" type="submit">
                Create Service
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal show={showEdit} onHide={editClose}>
        <Row>
          <Col>
            <p className='modalHeading'>Edit Service</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={(evt) => handleEditSubmit(evt, serviceToEdit.id)}>
              <Form.Group>
                <Form.Control name="service_name" type="text" placeholder="Service Name" defaultValue={serviceToEdit.service_name} />
              </Form.Group>
              <Form.Group>
                <Form.Control name="service_rate_per_unit" type="text" placeholder="Service Rate Per Unit/Hour" defaultValue={serviceToEdit.service_rate_per_unit} />
              </Form.Group>
              <Form.Group>
                <Form.Control name="technician_pay_per_unit" type="text" placeholder="Flat Rate Payout Per Unit (i.e. 0.5 = half hour per unit billed)" defaultValue={serviceToEdit.technician_pay_per_unit} />
              </Form.Group>
              <Button variant="primary" type="submit">
                Save Service
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal show={showError} onHide={closeError}>
        <Row>
            <Col>
            <p className='modalHeading'>{systemMessage}</p>
            </Col>
        </Row>
      </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#4439bf"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
    </div>
  );
}

export default Services;

