import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, Button, FormControl } from 'react-bootstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import socketIOClient, { io } from "socket.io-client";

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

let sellingprice = 0;
let extendedWarrantyPrice = 0;
let tradeInAllowance = 0;
let licenseFees = 0;
let gasolineCost = 0;
let lienPayout = 0;
let hstPayable = 0;
let lienRegistrationFee = 0;

function VehicleBillSale(props) {
  
  const [loading, setLoading] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [socket, setSocket] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [vehicleData, setVehicleData] = useState({});

  useEffect(() => {

    const socket = socketIOClient(ENDPOINT);
    setSocket(socket);
    
    socket.on('getInventory', (data) => {
        //Initial Data
        setInventory(data);
        setLoading(true);
    });
    socket.emit('getInventory');

    
  }, []);

  const onSelectVehicle = async (vehicleId) => {
    //filter inventory array to find object with id: vehicleId
    const vehicle = inventory.filter((item) => item.id === parseInt(vehicleId));
    setVehicleData(vehicle[0]);

  }

  const newSalesContract = async (evt) => {
    evt.preventDefault();
    const payload = {
      firstName: evt.target.firstName.value,
      middleInitial: evt.target.middleInitial.value,
      lastName: evt.target.lastName.value,
      purchaserAddress: evt.target.purchaserAddress.value,
      city: evt.target.city.value,
      province: evt.target.province.value,
      postalCode: evt.target.postalCode.value,
      homeTelephone: evt.target.homeTelephone.value,
      businessTelephone: evt.target.businessTelephone.value,
      driverLicenseNumber: evt.target.driversLicenseNumber.value,
      expiryDate: evt.target.exipryDate.value,
      emailAddress: evt.target.emailAddress.value,
      insuranceCompany: evt.target.insuranceCompany.value,
      policyNumber: evt.target.policyNumber.value,
      policyExpiryDate: evt.target.policyExpiryDate.value,
      insurancePhone: evt.target.insurancePhone.value,
      tradeInYear: evt.target.tradeInYear.value,
      tradeInMake: evt.target.tradeInMake.value,
      tradeInModel: evt.target.tradeInModel.value,
      tradeInTrim: evt.target.tradeInTrim.value,
      tradeInColour: evt.target.tradeInColour.value,
      tradeInVIN: evt.target.tradeInVIN.value,
      tradeInKM: evt.target.tradeInKM.value,
      tradeInHST: evt.target.tradeInHST.value,
      tradeInLien: evt.target.tradeInLien.value,
      lienHolder: evt.target.lienHolder.value,
      lienAmount: evt.target.lienAmount.value,
      CAMVAP: evt.target.CAMVAP.value,
      salesPersonsName: evt.target.salesPersonsName.value,
      registrationNumber: evt.target.registrationNumber.value,
      dealerRegistrationNumber: evt.target.dealerRegistrationNumber.value,
      nameOfOfficial: evt.target.nameOfOfficial.value,
      acceptorsRegistrationNumber: evt.target.acceptorsRegistrationNumber.value,
      title: evt.target.title.value,
      vendorDate: evt.target.vendorDate.value,
      vehicleYear: evt.target.vehicleYear.value,
      vehicleMake: evt.target.vehicleMake.value,
      vehicleModel: evt.target.vehicleModel.value,
      vehicleTrim: evt.target.vehicleTrim.value,
      vehicleColour: evt.target.vehicleColour.value,
      stockNumber: evt.target.stockNumber.value,
      vinNumber: evt.target.vinNumber.value,
      distanceTravelled: evt.target.distanceTravelled.value,
      distanceUnit: evt.target.distanceUnit.value,
      distanceUnknown: evt.target.distanceUnknown.value,
      actualDistanceHigher: evt.target.actualDistanceHigher.value,
      lastKnownTravel: evt.target.lastKnownTravel.value,
      lastKnownTravelDate: evt.target.lastKnownTravelDate.value,
      lastKnownMileage: evt.target.lastKnownMileage.value,
      safetyStandardsCert: evt.target.safetyStandardsCert.value,
      dailyRental: evt.target.dailyRental.value,
      mtoBrand: evt.target.mtoBrand.value,
      dealerGuarantee: evt.target.dealerGuarantee.value,
      dealerGuaranteeDays: evt.target.dealerGuaranteeDays.value,
      dealerGuaranteeKM: evt.target.dealerGuaranteeKM.value,
      dealerGuaranteeDescription: evt.target.dealerGuaranteeDescription.value,
      extendedWarranty: evt.target.extendedWarranty.value,
      extendedWarrantyValue: evt.target.extendedWarrantyValue.value,
      extendedWarrantyDate: evt.target.extendedWarrantyDate.value,
      extendedWarrantyKM: evt.target.extendedWarrantyKM.value,
      extendedWarrantyDescription: evt.target.extendedWarrantyDescription.value,
      amountOfPayments: evt.target.amountOfPayments.value,
      numberOfPayments: evt.target.numberOfPayments.value,
      paymentsStartOn: evt.target.paymentsStartOn.value,
      creditApproval: evt.target.creditApproval.value,
      dealerIncentiveForFinancing: evt.target.dealerIncentiveForFinancing.value,
      sellingPrice: evt.target.sellingPrice.value,
      extendedWarrantyPrice: evt.target.extendedWarrantyPrice.value,
      tradeInAllowance: evt.target.tradeInAllowance.value,
      licenceFee: evt.target.licenceFees.value,
      gasolineCost: evt.target.gasolineCost.value,
      payoutLienOnTradeIn: evt.target.payoutLienOnTradeIn.value,
      hstPayableOnTradeIn: evt.target.hstPayableOnTradeIn.value,
      subTotal: evt.target.subTotal.value,
      deposit: evt.target.deposit.value,
      depositAmount: evt.target.depositAmount.value,
      lienRegistrationFee: evt.target.lienRegistrationFee.value,
      balanceFinanced: evt.target.balanceFinanced.value,
      netAmountFinanced: evt.target.netAmountFinanced.value,
      costOfBorrowing: evt.target.costOfBorrowing.value,
      dayOfSale: evt.target.dayOfSale.value,
      carfaxReportNumber: evt.target.carfaxReportNumber.value,
      notes: evt.target.notes.value,
      ppsa: evt.target.ppsa.value,
      lenderAdminFee: evt.target.lenderAdminFee.value,
      apr: evt.target.apr.value,
    };
    console.log(payload);
    const newSaleResponse = await axios.post('https://redline-autohaus-api.herokuapp.com/bill-of-sale/new', payload);
    props.history.push(`/vehicles/sold`);
    console.log(newSaleResponse);

  }

  const updateSellingPrice = async (evt) => {
    sellingprice = evt.target.value;
    await calculateSubtotal();
  }

  const updateExtendedWarrantyPrice = async (evt) => {
    extendedWarrantyPrice = evt.target.value;
    await calculateSubtotal();
  }

  const updateTradeInAllowance = async (evt) => {
    tradeInAllowance = evt.target.value;
    await calculateSubtotal();
  }
  
  const updateLicenceFee = async (evt) => {
    licenseFees = evt.target.value;
    await calculateSubtotal();
  }

  const updateGasolineCost = async (evt) => {
    gasolineCost = evt.target.value;
    await calculateSubtotal();
  }
  
  const updateLienPayout = async (evt) => {
    lienPayout = evt.target.value;
    await calculateSubtotal();
  }
  
  const updateHstPayable = async (evt) => {
    hstPayable = evt.target.value;
    await calculateSubtotal();
  }

  const updateLienRegistrationFee = async (evt) => {
    lienRegistrationFee = evt.target.value;
    await calculateSubtotal();
  }

  const calculateSubtotal = async () => {
    await setSubTotal(parseInt(sellingprice)+parseInt(extendedWarrantyPrice)-parseInt(tradeInAllowance)+parseInt(licenseFees)+parseInt(gasolineCost)+parseInt(lienPayout)-parseInt(hstPayable)+parseInt(lienRegistrationFee));
  }

  
  const inventoryList = inventory.map((item) =>
    <option value={item.id}>{`${item.year} ${item.make} ${item.model}`}</option>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Create New Bill Of Sale</Card.Title>
                <select name="inventory_id" type="text" className="form-control" required="required" onChange={(evt) => onSelectVehicle(evt.target.value)}>
                  <option>Select A Vehicle From Your Inventory</option>
                  {inventoryList}
                </select>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <form onSubmit={(evt) => newSalesContract(evt)}>
            <Row>
              <Col lg="5">
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Purchaser Information</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="First Name"
                              name="firstName"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Middle Initial"
                              name="middleInitial"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Last Name"
                              name="lastName"
                            />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="Address"
                              name="purchaserAddress"
                            />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="City/Town"
                              name="city"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Province"
                              name="province"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Postal Code"
                              name="postalCode"
                            />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="Home Telephone"
                              name="homeTelephone"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Business Telephone"
                              name="businessTelephone"
                            />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="Drivers License Number"
                              name="driversLicenseNumber"
                            />
                          </Col>
                          <Col>
                            <DateTimePicker
                              placeholder="Expiry Date"
                              name="exipryDate"
                            />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="Email Address"
                              name="emailAddress"
                            />
                          </Col>
                        </Row>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Insurance Information</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <FormControl placeholder="Name Of Insurance Company" name="insuranceCompany" />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormControl placeholder="Policy Number" name="policyNumber" />
                          </Col>
                          <Col>
                            <DateTimePicker placeholder="Expiry Date" name="policyExpiryDate" />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormControl placeholder="Insurance Agent Contact Info" name="insurancePhone" />
                          </Col>
                        </Row>

                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Vehicle To Be Traded In</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <FormControl name="tradeInYear" placeholder="Year" />
                          </Col>
                          <Col>
                            <FormControl name="tradeInMake" placeholder="Make" />
                          </Col>
                          <Col>
                            <FormControl name="tradeInModel" placeholder="Model" />
                          </Col>
                          <Col>
                            <FormControl name="tradeInTrim" placeholder="Trim" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControl name="tradeInColour" placeholder="Colour" />
                          </Col>
                          <Col>
                            <FormControl name="tradeInVIN" placeholder="VIN" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControl name="tradeInKM" placeholder="KM" />
                          </Col>
                          <Col>
                            <FormControl name="tradeInHST" placeholder="HST Of Registrant" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <select className="form-control" name="tradeInLien">
                              <option value="1">Vehicle Has A Lien</option>
                              <option value="0">Vehicle Does Not Have Lien</option>
                            </select>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControl name="lienHolder" placeholder="Lien Holder" />
                          </Col>
                          <Col>
                            <FormControl name="lienAmount" placeholder="Lien Amount" />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Recall Information</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <select className="form-control" name="CAMVAP">
                              <option value="1">Manufacturer Participates In CAMVAP</option>
                              <option value="0">Manufacturer Does Not Participate In CAMVAP</option>
                            </select>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Sales Persons Signature</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <FormControl name="salesPersonsName" placeholder="Sales Persons Name" />
                          </Col>
                          <Col>
                            <FormControl name="registrationNumber" placeholder="Registration No." />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Vendors Acceptance</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <FormControl name="dealerRegistrationNumber" placeholder="Dealer Registration No." />
                          </Col>
                          <Col>
                            <FormControl name="nameOfOfficial" placeholder="Name Of Official" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControl name="acceptorsRegistrationNumber" placeholder="Acceptors Registration No." />
                          </Col>
                          <Col>
                            <FormControl name="title" placeholder="Title" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <DateTimePicker name="vendorDate" placeholder="Date" />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Disclosures</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <FormControl name="carfaxReportNumber" placeholder="CarFax Report Number" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <textarea className="form-control" name="notes" placeholder="Notes" style={{minHeight: '300px'}} />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              
              <Col>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Vehicle Information</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="Year"
                              name="vehicleYear"
                              defaultValue={vehicleData ? vehicleData.year : null}
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Make"
                              name="vehicleMake"
                              defaultValue={vehicleData ? vehicleData.make : null}
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Model"
                              name="vehicleModel"
                              defaultValue={vehicleData ? vehicleData.model : null}
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Trim"
                              name="vehicleTrim"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Colour"
                              name="vehicleColour"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Stock #"
                              name="stockNumber"
                              defaultValue={vehicleData ? vehicleData.stockNumber : null}
                            />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="VIN Number"
                              name="vinNumber"
                              defaultValue={vehicleData ? vehicleData.vin : null}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControl
                              placeholder="Distance Travelled"
                              name="distanceTravelled"
                            />
                          </Col>
                          <Col>
                            <select className="form-control" name="distanceUnit">
                              <option value="KMS">KMS</option>
                              <option value="MILES">MILES</option>
                            </select>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <select className="form-control" name="distanceUnknown">
                              <option value="1">The Distance Driven Is Unknown</option>
                              <option value="0">The Distance Driven Is Known</option>
                            </select>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <select className="form-control" name="actualDistanceHigher">
                              <option value="1">The Distance Driven Is Known To Be Higher</option>
                              <option value="0">The Distance Driven Is Known To Be Accurate</option>
                            </select>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <select className="form-control" name="lastKnownTravel">
                              <option value="1">The Distance Driven Is Known To Be Accurate As Of Certain Date</option>
                              <option value="0">The Distance Driven Is Known To Be Accurate And Current</option>
                            </select>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <DateTimePicker
                              placeholder="Last Known Travel Date"
                              name="lastKnownTravelDate"
                            />
                          </Col>
                          <Col>
                            <FormControl
                              placeholder="Last Known Mileage"
                              name="lastKnownMileage"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <select className="form-control" name="safetyStandardsCert">
                              <option value="1">Vehicle Sold Saftied</option>
                              <option value="0">Vehicle Sold As-Is</option>
                            </select>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <select className="form-control" name="dailyRental">
                              <option value="1">Vehicle Has Been Used As Daily Rental</option>
                              <option value="0">Vehicle Has Not Been Used As Rental</option>
                            </select>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControl placeholder="MTO Brand" name="mtoBrand" />
                          </Col>
                        </Row>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Header>Dealer Guarantee</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col>
                                <select name="dealerGuarantee" className="form-control">
                                  <option value="1">Dealer Guarantees The Vehicle</option>
                                  <option value="0">Dealer Does Not Guarantee Vehicle</option>
                                </select>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormControl placeholder="Amount Of Days Guaranteed" name="dealerGuaranteeDays" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormControl placeholder="Amount Of KMS Guaranteed" name="dealerGuaranteeKM" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <textarea className="form-control" name="dealerGuaranteeDescription" placeholder="Dealer Guarantee Description"></textarea>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Header>Extended Warranty</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col>
                                <select name="extendedWarranty" className="form-control">
                                  <option value="1">The Vehicle Sold With Extended Warranty</option>
                                  <option value="0">The Vehicle Sold With Out Extended Warranty</option>
                                </select>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormControl placeholder="Retail Value If Included In Vehicle Selling Price" name="extendedWarrantyValue" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <DateTimePicker placeholder="Extended Warranty Expiry Date" name="extendedWarrantyDate" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormControl placeholder="Amount Of KM Warranty Valid For" name="extendedWarrantyKM" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <textarea name="extendedWarrantyDescription" placeholder="Extended Warranty Description" className="form-control"></textarea>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Header>Terms Of Financing</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col>
                                <FormControl placeholder="$ Amount Of Payments" name="amountOfPayments" />
                              </Col>
                              <Col>
                                <FormControl placeholder="No. Of Payments" name="numberOfPayments" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormControl placeholder="Payments Start On" name="paymentsStartOn" />
                              </Col>
                              <Col>
                                <FormControl placeholder="Credit Approval" name="creditApproval" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <select className="form-control" name="dealerIncentiveForFinancing">
                                  <option value="1">Dealer Or Sales Person Will Receive Incentive For Providing Financing</option>
                                  <option value="0">Dealer Or Sales Person Will Not Receive Incentive For Providing Financing</option>
                                </select>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Header>Terms Of Settlement</Card.Header>
                      <Card.Body>
                        <Row><Col><FormControl placeholder="Vehicle Selling Price" name="sellingPrice" onKeyUp={(evt) => updateSellingPrice(evt)} /></Col></Row>
                        <Row><Col><FormControl placeholder="Extended Warranty Price" name="extendedWarrantyPrice" onKeyUp={(evt) => updateExtendedWarrantyPrice(evt)} /></Col></Row>
                        <Row><Col><FormControl placeholder="Trade In Allowance" name="tradeInAllowance" onKeyUp={(evt) => updateTradeInAllowance(evt)} /></Col></Row>
                        <Row><Col><FormControl placeholder="Licence Fees" name="licenceFees" onKeyUp={(evt) => updateLicenceFee(evt)} /></Col></Row>
                        <Row><Col><FormControl placeholder="Gasoline Cost" name="gasolineCost" onKeyUp={(evt) => updateGasolineCost(evt)} /></Col></Row>
                        <Row><Col><FormControl placeholder="Lien Payout" name="payoutLienOnTradeIn" onKeyUp={(evt) => updateLienPayout(evt)} /></Col></Row>
                        <Row><Col><FormControl placeholder="HST Payable On Trade In (Business To Business Only)" onKeyUp={(evt) => updateHstPayable(evt)} name="hstPayableOnTradeIn" /></Col></Row>
                        <Row>
                          <Col>
                            <select className="form-control" name="deposit">
                              <option value="Cheque">Deposit Paid By Cheque</option>
                              <option value="Credit Card">Deposit Paid By Credit/Debit</option>
                              <option value="Cash">Deposit Paid By Cash</option>
                            </select>
                          </Col>
                        </Row>
                        <Row><Col><FormControl placeholder="Deposit Amount" name="depositAmount" /></Col></Row>
                        <Row><Col><FormControl placeholder="Lien Registration Fee" name="lienRegistrationFee" onKeyUp={(evt) => updateLienRegistrationFee(evt)} /></Col></Row>
                        <Row><Col><FormControl placeholder="Balance Financed" name="balanceFinanced" /></Col></Row>
                        <Row><Col><FormControl placeholder="Net Amount Financed" name="netAmountFinanced" /></Col></Row>
                        <Row><Col><FormControl placeholder="Cost Of Borrowing" name="costOfBorrowing" /></Col></Row>
                        <Row><Col><FormControl placeholder="PPSA" name="ppsa" /></Col></Row>
                        <Row><Col><FormControl placeholder="APR" name="apr" /></Col></Row>
                        <Row><Col><FormControl placeholder="Lender Admin Fee" name="lenderAdminFee" /></Col></Row>
                        <Row><Col><FormControl placeholder="Sub-Total" name="subTotal" disabled value={subTotal} /></Col></Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Button variant="success" style={{width: '100%', height: '50px'}} type="submit">Finalize Sale</Button>
                      </Col>
                      <Col>
                        <DateTimePicker placeholder="Date Of Sale" style={{width: '100%', height: '50px'}} name="dayOfSale" />
                      </Col>
                    </Row>                    
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        </form>  
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default VehicleBillSale;

