import React, {useEffect, useState} from 'react';
import AuthRoutes from './routes/AuthRoutes';
import GuestRoutes from './routes/GuestRoutes';
import TrialExpiredRoutes from './routes/TrialExpiredRoutes';
import fire from './config/Firebase';
import {Triangle} from 'react-loader-spinner';
import moment from 'moment';
import socketIOClient from "socket.io-client";
const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

function App() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [shop, setShop] = useState({});
  const [trialLeft, setTrialLeft] = useState('');
  const updateCountdown = (shop) => {
    if(shop){
        const futureDate = moment(shop.createdAt).add(30, 'days');
        const now = moment();
        const diff = futureDate.diff(now);
    
        // Convert the difference to days, hours, minutes, and seconds
        var duration = moment.duration(diff);
        let timeLeft = 1;
        // Set the duration to 0 if it is negative
        duration.seconds() < 0 ? timeLeft = 0 : timeLeft = 1;
        setTrialLeft(timeLeft);
    }
  }

  useEffect(() => {
    
    // Check to see if this is a redirect back from Checkout
    const socket = socketIOClient(ENDPOINT);
    setSocket(socket);
    

    socket.on('getShopData', (data) => {
        console.log('get shop data response', data);
        setShop(data);
        updateCountdown(data);
    });


    fire.auth().onAuthStateChanged((user) => {
      if(user){
        
        setUser({user});
        setLoading(true);
        const getShopData = async () => {
              socket.emit('getShopData', { idToken: await fire.auth().currentUser.getIdToken() });
        }
    
        getShopData();

      }else{

        setUser({});
        setLoading(true);

      }

    });

  }, []);



  if(Object.keys(user).length && loading === true && (trialLeft === 1 || shop.stripe_customer_id !== null)){
    return (
      <AuthRoutes />
    );
  }else if(Object.keys(user).length && loading === true && trialLeft === 0){
    return (
      <TrialExpiredRoutes />
    );
  }
  else if(loading === true && Object.keys(user).length < 1){
    return (
      <GuestRoutes />
    );
  }else{
    return (
      <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#4439bf"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
    </div>
    );
  }

}

export default App;
