import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, InputGroup, Badge, Form, Modal, TabContainer, Table } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import socketIOClient, { io } from "socket.io-client";
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

function Parts(props) {
  const [parts, setParts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [socket, setSocket] = useState(null);

  const createClose = () => setShowCreate(false);
  const openCreate = () => setShowCreate(true);

  const editClose = () => setShowEdit(false);
  const openEdit = () => setShowEdit(true);

  const getParts = async () => {
    const invoicesResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/parts/get/${await fire.auth().currentUser.getIdToken()}`);
    // const invoicesResponse = await axios.get(`http://localhost/parts/get/${await fire.auth().currentUser.getIdToken()}`);
    setParts(invoicesResponse.data);
  }

  const getSuppliers = async () => {
    const supplierResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/suppliers/get/${await fire.auth().currentUser.getIdToken()}`);
    // const supplierResponse = await axios.get(`http://localhost/suppliers/get/${await fire.auth().currentUser.getIdToken()}`);
    setSuppliers(supplierResponse.data);
  }
  
  const changeETA = async (evt, id) => {
    const value = evt.target.value;
    await axios.post('https://redline-autohaus-api.herokuapp.com/supplier/eta/change', {id , eta: value, idToken: await fire.auth().currentUser.getIdToken()});
    getSuppliers();
    getParts();
  }

  const changeSupplier = async (evt, id) => {
    const value = evt.target.value;
    await axios.post('https://redline-autohaus-api.herokuapp.com/supplier/ordered/change', {id , supplier: value, idToken: await fire.auth().currentUser.getIdToken()});
    getSuppliers();
    getParts();
  }

  useEffect(() => {

    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('saveSupplier', (data) => {
      setSuppliers(data);
    });

    getSuppliers();
    getParts();
    setLoading(true);
    
  }, [setSocket]);

  const supplierList = suppliers.map((supplier) => 
    <option value={supplier.name}>{supplier.name}</option>
  );

  const handleSubmit = async (evt) => {

    evt.preventDefault();

    const name = evt.target.name.value;

    const payload = {
      idToken: await fire.auth().currentUser.getIdToken(),
      name,
    };

    const supplierResponse = await axios.post('https://redline-autohaus-api.herokuapp.com/suppliers/add', payload); 
    // const supplierResponse = await axios.post('http://localhost/suppliers/add', payload); 
    setSuppliers(supplierResponse.data);

    createClose();

  }

  const saveSupplier = async (evt, id) => {
    //get the values from the form
    const name = evt.target.value;
    //get the idToken
    const idToken = await fire.auth().currentUser.getIdToken();
    //get id passed in
    const supplierId = id;
    //create payload
    const payload = {
      idToken,
      name,
      id: supplierId,
    };

    socket.emit('saveSupplier', payload);

}

//delete supplier function
const deleteSupplier = async (id) => {
  //get the idToken
  const idToken = await fire.auth().currentUser.getIdToken();
  //get id passed in
  const supplierId = id;
  //create payload
  const payload = {
    idToken,
    id: supplierId,
  };

  //api call
  const supplierResponse = await axios.post('https://redline-autohaus-api.herokuapp.com/suppliers/delete', payload);
  // const supplierResponse = await axios.post('http://localhost/suppliers/delete', payload);
  console.log(supplierResponse);
  setSuppliers(supplierResponse.data);

}
    


  const partsList = parts.map((part) =>
    {

    let partStatus;
    let statusPretty;

    if(moment().isBefore(part.eta)){
       partStatus = 'ordered';
       statusPretty = 'Ordered';
    }else if(part.eta !== null && moment().isSameOrAfter(part.eta)){
       partStatus = 'inStock';
       statusPretty = 'In Stock';
    }else{
       partStatus = 'notOrdered';
       statusPretty = 'Not Ordered';
    }

    return(
      <Col md="3">
        <Card className='marginBottom30 partsCard'>
          <Card.Body>
            <Row className="yearMakeModelRow">
              <Col>
                <h5 className="jobId">#{part.invoice.id + 1000}</h5>
              </Col>
              <Col>
                <Badge variant="warning" className={partStatus} style={{float: 'right'}}>{statusPretty}</Badge>
              </Col>
            </Row>
            <Row>
                <Col>
                <p className='kanbanVehicleModel'>{part.invoice.vehicle.year} {part.invoice.vehicle.make} {part.invoice.vehicle.model}</p>
                </Col>
            </Row>
            <Row>
              <Col>
                <p><span className='customerInfoLabel'>Part:</span> {part.description}</p> 
                <p><span className='customerInfoLabel'>Retail:</span> ${part.amount_charged}</p>
                <p><span className='customerInfoLabel'>Part Number:</span> {part.part_number ? part.part_number : 'Part Number Missing'}</p>
                <p><span className='customerInfoLabel'>ETA:</span> {moment(part.eta).format('dddd, MMMM Do h:mm a')}</p>
                <br />
                <p className='customerInfoLabel'>Supplier Ordered From:</p>
                <InputGroup>
                  
                    <select className="form-control" defaultValue={part.ordered_from} onChange={(evt) => changeSupplier(evt, part.id)}>
                      <option value='Not Ordered'>Not Ordered</option>
                      {supplierList}
                    </select>
                  
                </InputGroup>
                <br />
                <p className='customerInfoLabel'>ETA:</p>
                <InputGroup>
                  
                    <select onChange={(evt) => changeETA(evt, part.id)} className="form-control" defaultValue={part.eta_field}>
                      <option value="Not Ordered">Not Ordered</option>
                      <option value="In Stock">In Stock</option>
                      <option value="24 Hours/Over Night">24 Hours/Over Night</option>
                      <option value="48 Hours/2 Days">48 Hours/2 Days</option>
                      <option value="72 Hours/3 Days">72 Hours/3 Days</option>
                      <option value="Online Order/7 Days">Online Order/7 Days</option>
                      <option value="Back Ordered">Back Ordered</option>
                    </select>
                  
                </InputGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    );
    
    }
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button onClick={() => openCreate()}>Add Supplier</Button>
            <Button id='viewEditSuplliers' onClick={() => openEdit()}>View & Edit Suppliers</Button>
          </Col>
        </Row>
        <Row>
          {partsList}
        </Row>
      </Container>
      <Modal show={showCreate} onHide={createClose}>
        <Row>
          <Col>
            <p className='modalHeading'>Add Supplier</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={(evt) => handleSubmit(evt)}>
              <Form.Group>
                <Form.Control name="name" type="text" placeholder="Supplier Name" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Add Supplier
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal show={showEdit} onHide={editClose}>
        <Row>
          <Col>
            <p className='modalHeading'>Edit Suppliers</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                  {
                    suppliers.map((supplier) =>
                      <tr>
                        <td>
                          <Form.Control onChange={(evt) => saveSupplier(evt, supplier.id)} defaultValue={supplier.name}></Form.Control>
                        </td>
                        <td>
                          <Button onClick={() => deleteSupplier(supplier.id)}>Delete</Button>
                        </td>
                      </tr>
                    )
                  }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default Parts;

