import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal
} from "react-bootstrap";
import PropTypes from 'prop-types';
import axios from "axios";
import moment from 'moment';
import {Link} from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import { FaQuestion } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import { MdOutlineClose, MdOutlineSpeakerNotes  } from "react-icons/md";

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function EditWorkOrder(props) {

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [rows, setRows] = useState([]);
  const [payload, setPayload] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [vehicleId, setVehicleId] = useState(0);
  const [services, setServices] = useState([]);
  const [workStats, setWorkStats] = useState({});
  const [notes, setNotes] = useState('');
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(true);
  const [socket, setSocket] = useState(null);
  const [autoComplete, setAutoComplete] = useState([]);
  const [currentDescriptionInput, setCurrentDescriptionInput] = useState('');
  const [showCreateInspectionModal, setShowCreateInspectionModal] = useState(false);
  const [showAssignInspectionModal, setShowAssignInspectionModal] = useState(false);
  const [activeInspection, setActiveInspection] = useState({});
  const [inspections, setInspections] = useState([]);
  const [showCreateInspectionItem, setShowCreateInspectionItem] = useState(false);
  const [inspectionItemsList, setInspectionItemsList] = useState([]);
  const [selectedInspectionItems, setSelectedInspectionItems] = useState([]);
  const [performInspectionModal, setPerformInspectionModal] = useState(false);
  const [inspectionResults, setInspectionResults] = useState([]);
  const [activeInspectionResult, setActiveInspectionResult] = useState({});
  const [notesModalStatus, setNotesModalStatus] = useState(false);
  const [techs, setTechs] = useState([]);

  const [value, setValue] = React.useState(0);

  const createClose = () => setShowCreateInspectionModal(false);
  const openCreate = () => {setShowCreateInspectionModal(true);};

  const assignInspectionClose = () => setShowAssignInspectionModal(false);
  const openAssignInspectionItem = () => {setShowAssignInspectionModal(true);};

  const createInspectionItemClose = () => setShowCreateInspectionItem(false);
  const openCreateInspectionItem = () => {setShowCreateInspectionItem(true);};

  
  const showPerformInspection = () => {setPerformInspectionModal(true);};
  const performInspectionClose = () => setPerformInspectionModal(false);

  const notesModal = () => {setNotesModalStatus(true);};
  const notesModalClose = () => setNotesModalStatus(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setInvoiceItems([
      { className: "firstRow", name: "type", prettyName: 'Type' },
      { className: "firstRow", name: "description", prettyName: 'Description' },
      { className: "firstRow", name: "partNumber", prettyName: 'Part Number' },
      { className: "firstRow", name: "warranty", prettyName: 'Warranty' },
      { className: "firstRow", name: "cost", prettyName: 'Cost' },
      { className: "firstRow", name: "quantity", prettyName: 'Quantity' },
      { className: "firstRow", name: "pricePerUnit", prettyName: 'Price Per Unit' },
    ]);

    //define socket connection and set to state
    const socket = socketIOClient(ENDPOINT);
    setSocket(socket);

    const getAllData = async () => {

      const idToken = await fire.auth().currentUser.getIdToken();
      socket.emit('getAllInspections', {idToken});
      socket.emit('getAllInspectionItems', {idToken});
      socket.emit('getAllInspectionResults', {invoiceId: props.match.params.id, idToken});
      //getTechnicians
      socket.emit('getTechnicians', idToken);
    }

    getAllData();

    socket.on('getTechnicians', (data) => {
      setTechs(data);
    });

    socket.on('descriptionChanged', (data) => { 
      if(data){
        const autoCompleteSuggestions = [];
        //iterate data and set autocomplete as array of descriptions to upper case
        for(let index in data){
          //push description to array of autoCompleteSuggestions
          autoCompleteSuggestions.push(data[index].description.toUpperCase());
        }

        //if data is empty, setAutoComplete suggestions to array with currentDescriptionInput as only element
        if(data.length === 0){
          console.log('data is empty');
          setAutoComplete([currentDescriptionInput.toUpperCase()]);
        }else{
          setAutoComplete(autoCompleteSuggestions);
        }
      }

      console.log(data);
    });

    socket.on('deleteInvoiceItem', async (invoice) => {

      let payload = [];
      //loop through invoice items and add to payload
      for(let index in invoice.invoiceItems){
        const invoiceItem = invoice.invoiceItems[index];
        payload.push({
          name: "row",
          id: invoiceItem.id,
          type: invoiceItem.service_type,
          description: invoiceItem.description,
          partNumber: invoiceItem.part_number,
          warranty: invoiceItem.warranty,
          cost: invoiceItem.cost,
          quantity: invoiceItem.quantity,
          pricePerUnit: invoiceItem.price_per_unit,
        });
      }

      setRows([]);
      setRows(payload);
      setInvoice(invoice);

    });

    socket.on('createInvoiceItem', async (invoiceItems) => {

      let payload = [];
      //loop through invoice items and add to payload
      for(let index in invoiceItems){
        const invoiceItem = invoiceItems[index];
        payload.push({
          name: "row",
          id: invoiceItem.id,
          type: invoiceItem.service_type,
          description: invoiceItem.description,
          partNumber: invoiceItem.part_number,
          warranty: invoiceItem.warranty,
          cost: invoiceItem.cost,
          quantity: invoiceItem.quantity,
          pricePerUnit: invoiceItem.price_per_unit,
        });
      }

      setRows([]);
      setRows(payload);

    });

    socket.on('serviceChanged', (data) => {
      setInvoice(data);
 
      const invoiceItems = data.invoiceItems;

      let payload = [];
      //loop through invoice items and add to payload
      for(let index in invoiceItems){
        const invoiceItem = invoiceItems[index];
        payload.push({
          name: "row",
          id: invoiceItem.id,
          type: invoiceItem.service_type,
          description: invoiceItem.description,
          partNumber: invoiceItem.part_number,
          warranty: invoiceItem.warranty,
          cost: invoiceItem.cost,
          quantity: invoiceItem.quantity,
          pricePerUnit: invoiceItem.price_per_unit,
        });

      }
    
      setRows([...payload]);
    });

    socket.on('pricePerUnitChanged', (data) => {
      console.log(data);
      setInvoice(data);
    });

    socket.on('quantityChanged', (data) => {
      console.log(data);
      setInvoice(data);
    });

    // Listen for the 'inspectionSaved' event
    socket.on('inspectionSaved', (data) => {
      console.log('Inspection saved:', data);
      setActiveInspection(data);
      createClose();
      openAssignInspectionItem();
      // You can update the state or do other things here
    });

    socket.on('inspectionsFetched', (data) => {
      setInspections(data);
    });

    socket.on('inspectionItemCreated', (data) => {
      console.log('inspection item created:');
      console.log(data);
      setInspectionItemsList(data);
    });

    //inspectionItemFetched event
    socket.on('inspectionItemFetched', (data) => {
      console.log(data);
      setInspectionItemsList(data);
    });

    socket.on('inspectionItemsCurrentlySelected', (data) => {
      setSelectedInspectionItems(data);
    });

    socket.on('inspectionPerformed', (data) => {  
      console.log(data);
      setInspectionResults(data);
      performInspectionClose();
    });

    socket.on('inspectionResultsFetched', (data) => {
      console.log(data);
      setInspectionResults(data);
    });

    socket.on('itemResultChanged', (data) => {
      console.log(data);
      setInspectionResults(data);
    });

    socket.on('inspectionResultFetched', (data) => {
      setActiveInspectionResult(data);
      notesModal();
    });

    const getInvoice = async () => {

      const token = await fire.auth().currentUser.getIdToken(true);
      const invoiceResponse = await axios.post(`https://redline-autohaus-api.herokuapp.com/invoices/get`, {idToken: token, invoiceId: props.match.params.id});  
      
      //define invoice items
      const invoiceItems = invoiceResponse.data.invoiceItems;
      //define invoice
      const invoice = invoiceResponse.data;
      //define technician
      const technician = invoice.technician;
      //define vehicle
      const vehicle = invoice.vehicle;
      //define customer
      const customer = invoice.vehicle.customer;
      setNotes(invoice.notes);
      setCustomer(customer);
      setVehicle(vehicle);
      setInvoice(invoice);
      setVehicleId(vehicle.id);
      const vehicleData = await axios.get(`https://redline-autohaus-api.herokuapp.com/vehicles/${vehicle.id}/get`);
      await getWorkDone(vehicleData.data.workStats);

      let payload = [];
      //loop through invoice items and add to payload
      for(let index in invoiceItems){
        const invoiceItem = invoiceItems[index];
        payload.push({
          name: "row",
          id: invoiceItem.id,
          type: invoiceItem.service_type,
          description: invoiceItem.description,
          partNumber: invoiceItem.part_number,
          warranty: invoiceItem.warranty,
          cost: invoiceItem.cost,
          quantity: invoiceItem.quantity,
          pricePerUnit: invoiceItem.price_per_unit,
        });
      }
      console.log(payload);
      setRows(payload);
      getServices();
    }

    getInvoice();

  }, [props.match.params.id, setSocket]);


  const getServices = async () => {
    const serviceData = await axios.post(`https://redline-autohaus-api.herokuapp.com/services/get`, {idToken: await fire.auth().currentUser.getIdToken(true)});
    // const serviceData = await axios.post(`http://localhost/services/get`, {idToken: await fire.auth().currentUser.getIdToken(true)});
    console.log(serviceData);
    setServices(serviceData.data);
  }
  

  const getWorkDone = async(workData) => {
    const workDoneData = {
      labels: [],
      datasets: [
        {
          label: '# of Votes',
          data: [],
          backgroundColor: [

          ],
          borderColor: [

          ],
          borderWidth: 1,
        },
      ],
    }
    const chartData = {};
    for(let index in workData){
      console.log(index);
      console.log(workData[index]);
      if(chartData[workData[index].service_type]){
        chartData[workData[index].service_type] = ((parseFloat(workData[index].quantity)*parseFloat(workData[index].price_per_unit))+chartData[workData[index].service_type]);
      }else{
        chartData[workData[index].service_type] = (parseFloat(workData[index].quantity)*parseFloat(workData[index].price_per_unit));
      }
    }


    for (let index in chartData) {
      workDoneData.labels.push(index);
      workDoneData.datasets[0].data.push(parseFloat(chartData[index]).toFixed(2));
      const color = `rgba(${Math.random()*256|0}, ${Math.random()*256|0}, ${Math.random()*256|0}, 1)`;
      workDoneData.datasets[0].backgroundColor.push(color);
      workDoneData.datasets[0].borderColor.push(color);
    }

    setWorkStats(workDoneData);

  }

  const addInvoiceItem = async (id) => {
    //loop through rows and check if cost is null
    let notFilled = 0;
    for(let index in rows){
      if(rows[index].cost === null){
        alert('Please fill out the cost for all items before adding a new item');
        notFilled = 1;
        break;
      }
    }
    if(notFilled === 0){
      socket.emit('createInvoiceItem', {id, idToken: await fire.auth().currentUser.getIdToken()});
    }
  };

  const serviceSelected = (data) => {
    const selectedService = data.target.value;
    const selectedServiceMetaData = services.filter((service) => {return service.service_name==selectedService});
    const descriptionField = data.target.parentNode.parentNode.parentNode.childNodes[1].childNodes[0].childNodes[1];
    const quantityField = data.target.parentNode.parentNode.parentNode.childNodes[4].childNodes[0].childNodes[1];
    const pricePerUnitField = data.target.parentNode.parentNode.parentNode.childNodes[5].childNodes[0].childNodes[1];
    pricePerUnitField.value = selectedServiceMetaData[0].service_rate_per_unit;
    quantityField.value = 1;
    descriptionField.value = selectedServiceMetaData[0].service_description;
    console.log(selectedServiceMetaData);
  }

  const serviceList = (selectedService) => {
    return services.map((service) =>
      <option value={service.service_name} selected={service.service_name === selectedService ? 'selected' : null}>{service.service_name}</option>
    );
  }

  const getServicesNames = () => {
    //iterate through services and built array of service names
    const serviceNames = [];
    for(let index in services){
      serviceNames.push(services[index].service_name);
    }
    return serviceNames;
  }

  const deleteItem = async (id) => {
    socket.emit('deleteInvoiceItem', {id, idToken: await fire.auth().currentUser.getIdToken()});
  }

  //vin changed function
  const vinChanged = async (data, id) => {
    const vin = data.target.value;
    socket.emit('vinChanged', {vin: vin, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(vin);
    console.log(id);
  }

  //plate changed function
  const plateChanged = async (data, id) => {
    const plate = data.target.value;
    socket.emit('plateChanged', {plate: plate, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(plate);
    console.log(id);
  }

  //odometer changed function
  const odometerChanged = async (data, id) => {
    const odometer = data.target.value;
    socket.emit('odometerChanged', {odometer: odometer, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(odometer);
    console.log(id);
  }

  //notes changed function
  const notesChanged = async (data, id) => {
    const notes = data.target.value;
    socket.emit('notesChanged', {notes: notes, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(notes);
    console.log(id);
  }

  const descriptionChanged = async (value, id) => {
    console.log(value);
    setCurrentDescriptionInput(value);
    socket.emit('descriptionChanged', {description: value, id: id, idToken: await fire.auth().currentUser.getIdToken()});

  }

  const serviceChanged = async (data, id, value) => {
    const selectedService = value;
    socket.emit('serviceChanged', {service: selectedService, id: id, idToken: await fire.auth().currentUser.getIdToken()});
  }

  const partNumberChanged = async (data, id) => {
    const partNumber = data.target.value;
    socket.emit('partNumberChanged', {partNumber: partNumber, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(partNumber);
    console.log(id);
  }

  const warrantyChanged = async (data, id) => {
    const warranty = data.target.value;
    socket.emit('warrantyChanged', {warranty: warranty, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(warranty);
    console.log(id);
  }

  const quantityChanged = async (data, id) => {
    const quantity = data.target.value;
    const index = rows.findIndex(row => row.id === id);
    let newRows = [...rows];
    newRows[index].quantity = quantity;
    setRows(newRows);
    
    socket.emit('quantityChanged', {quantity: quantity, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(quantity);
  }

  const costChanged = async (data, id) => {
    const cost = data.target.value;
    const index = rows.findIndex(row => row.id === id);
    let newRows = [...rows];
    newRows[index].cost = cost;
    setRows(newRows);
    
    socket.emit('costChanged', {cost: cost, id: id, idToken: await fire.auth().currentUser.getIdToken()});
  }

  const pricePerUnitChanged = async (data, id) => {
    
    const pricePerUnit = data.target.value;

    const index = rows.findIndex(row => row.id === id);
    let newRows = [...rows];
    newRows[index].pricePerUnit = pricePerUnit;
    setRows(newRows);
    
    socket.emit('pricePerUnitChanged', {pricePerUnit: pricePerUnit, id: id, idToken: await fire.auth().currentUser.getIdToken()});
    console.log(pricePerUnit);
    console.log(id);
  }

  const excludeFee = async (id) => {
    console.log(id);
    await axios.get(`https://redline-autohaus-api.herokuapp.com/invoice/${id}/exclude-fees`);
    const invoiceResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/invoices/${props.match.params.id}/get`);  
    setInvoice(invoiceResponse.data);
  }

  const printInvoice = async (id) => {
    //history push to print page
    props.history.push(`/print/${id}`);

  }

  const createNewInspection = async(event) => {
    event.preventDefault();

    const formData = {
      idToken: await fire.auth().currentUser.getIdToken(true),
      name: event.target.name.value
    };

    socket.emit('saveInspection', formData);
  };

  const createInspectionItem = async (event) => {
    event.preventDefault();

    const formData = {
      idToken: await fire.auth().currentUser.getIdToken(true),
      name: event.target.name.value,
      description: event.target.description.value
    };

    socket.emit('createInspectionItem', formData);
  };

  const toggleInspectionItem = async (itemId, evt) => {
    if (selectedInspectionItems.includes(itemId)) {
      setSelectedInspectionItems(selectedInspectionItems.filter(item => item !== itemId));
    } else {
      setSelectedInspectionItems([...selectedInspectionItems, itemId]);
    }
    if(activeInspection && activeInspection.id){
      socket.emit('toggleInspectionItem', {inspectionId: activeInspection.id, itemId});
    }
  }

  const inspectionSelected = async (inspection) => {
    console.log('inspection selected');
    socket.emit('inspectionItemsCurrentlySelected', {inspectionId: inspection.id});
    setActiveInspection(inspection);
  }

  const changeItemResult = async (inspectionResultId, result) => {
    console.log('changeItemResult');
    socket.emit('changeItemResult', {inspectionResultId, result});
  }

  const performInspection = async (inspectionId) => {
    console.log(`performInspection`);
    const payload = {
      inspectionId,
      invoiceId: invoice.id,
    }
    socket.emit('performInspection', payload);
  }

  const uploadPhoto = async (e, resultId) => {
    e.preventDefault();
    const file = e.target.logo.files[0];
    const storageRef = fire.storage().ref();
    const fileRef = storageRef.child(file.name+' '+moment().format('YYYYMMDDHHmmss'));
    await fileRef.put(file);
    const currentUrl = await fileRef.getDownloadURL();
    socket.emit('uploadResultPhoto', { url: currentUrl, resultId });
  }

  const openNotes = async (inspectionResult) => {
    
    socket.emit('getInspectionResult', { resultId: inspectionResult.id });

  }

  const changeTechnician = async (invoiceId, technician) => {
    socket.emit('changeTechnician', { invoiceId, technician, idToken: await fire.auth().currentUser.getIdToken() });
  }

  const updateResultNotes = async (e) => {
    setActiveInspectionResult({ ...activeInspectionResult, notes: e.target.value });
    socket.emit('updateResultNotes', { notes: e.target.value, resultId: activeInspectionResult.id });
  }

  const pieChartOptions = {
    legend: { display: false },

    scales: {
        xAxes: [{
        display: false,
        gridLines: {
            display: false
        },
        ticks: {
            display: true,
            padding: 0
        }
        }],
        yAxes: [{
        display: false,
        gridLines: {
            display: false
        },
        ticks: {
            display: false, //this will remove only the label
            padding: 0
        }
        }]
    }
  }

  return (
    loading ? 
      <>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <Card.Body className="minHeight293 newQuoteCustomerInfoPadding">
                  <Row>
                    <Col>
                      <p className="mainCardPurpleHeading">Customer Information</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="newQuoteCustomerInfoLabel">Name:</label>
                      <Link to={`/customers/view/${customer.id}`}><p className="newQuoteCustomerInfoHeading">{customer.name}</p></Link>
                    </Col>
                    <Col>
                      <label className="newQuoteCustomerInfoLabel">Phone Number:</label>
                      <p className="newQuoteCustomerInfoHeading">{customer.phone_number}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="newQuoteCustomerInfoLabel">Customer Since:</label> 
                      <p className="newQuoteCustomerInfoHeading">{moment(customer.createdAt).fromNow()}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                  <Card.Body className="minHeight293 newQuoteCustomerInfoPadding">
                    <Row>
                      <Col>
                        <p className="mainCardPurpleHeading">Vehicle Information</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="newQuoteCustomerInfoLabel">Year:</label>
                        <p className="newQuoteCustomerInfoHeading">{vehicle.year}</p>
                      </Col>
                      <Col>
                        <label className="newQuoteCustomerInfoLabel">Make:</label>
                        <p className="newQuoteCustomerInfoHeading">{vehicle.make}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="newQuoteCustomerInfoLabel">Model:</label> 
                        <p className="newQuoteCustomerInfoHeading">{vehicle.model}</p>
                      </Col>
                    </Row>  
                  </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                  <Card.Body className="minHeight293 newQuoteCustomerInfoPadding">
                    <p className="mainCardPurpleHeading">Customer Work Done</p>
                    <Doughnut data={workStats} options={pieChartOptions} />
                  </Card.Body>
              </Card>
            </Col>
          </Row>  
          <Row>
            <Col>
              <Card>
                <Card.Body className="newQuoteCustomerInfoPadding">
                  <Row>
                    <Col>
                      <label className="newQuoteCustomerInfoLabel">Main Technician:</label>
                      {/* select technician*/}
                      <select className="form-control" name="technician" id="technician" onChange={(evt) => changeTechnician(invoice.id, evt.target.value)}>  
                        <option value=''>Select Technician</option>
                        {/* map techs */}
                        {techs ? techs.map((tech, index) => (
                          <option key={index} value={tech.name} selected={invoice.technician === tech.name ? true : false}>{tech.name}</option>
                        )) : null}
                      </select>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="marginTop20">
                <Card.Body className="newQuoteBody">
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="WORK ORDER" {...a11yProps(0)} />
                        <Tab label="INSPECTIONS" {...a11yProps(1)} />
                        <Tab label="ORDER PARTS" {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <p className="mainCardPurpleHeading">New Work Order / Quote</p>
                      <Form onSubmit={(evt) => evt.preventDefault()} style={{ width: '100%', padding: '10px' }}>
                          <Row>

                            <Col>
                              <label className='newQuoteLabel'>Plate:</label>
                              <Form.Control name="plate" onKeyUp={(data) => plateChanged(data, vehicle.id)} onChange={(data) => plateChanged(data, vehicle.id)} defaultValue={vehicle.plate} required className="form-control newQuoteFormControl"></Form.Control>
                            </Col>
                          
                            <Col>
                              <label className='newQuoteLabel'>VIN:</label>
                              <Form.Control name="vin" onKeyUp={(data) => vinChanged(data, vehicle.id)} onChange={(data) => vinChanged(data, vehicle.id)} defaultValue={vehicle.vin} required className="form-control newQuoteFormControl"></Form.Control>
                            </Col>

                            <Col>
                              <label className='newQuoteLabel'>Odometer:</label>
                              <Form.Control name="odometer" onKeyUp={(data) => odometerChanged(data, invoice.id)} onChange={(data) => odometerChanged(data, invoice.id)} required className="form-control newQuoteFormControl" defaultValue={invoice.odometer}></Form.Control>
                            </Col>

                          </Row>
                          <Row>
                              <Col>
                              <label className='newQuoteLabel'>Notes:</label>
                              <textarea onKeyUp={(data) => notesChanged(data, invoice.id)} onChange={(data) => notesChanged(data, invoice.id)} name="notes" className="form-control newQuoteFormControl height500" defaultValue={notes}></textarea>
                              </Col>
                          </Row>
                          
                          {rows.map((row, id) => {
                            return(
                            <Row>
                              <Col>
                                <Row>
                                  {invoiceItems.map((val, id) => (
                                    <>
                                      {
                                        val.name === "type" ? (
                                        <Col md='3' className="marginTop35">
                                          <Form.Group>
                                            <Autocomplete
                                              id={`service${row.id}`}  
                                              onChange={(data, value) => serviceChanged(data, row.id, value)}
                                              options={getServicesNames()}
                                              defaultValue={row.type}
                                              renderInput={(params) => <TextField {...params} id={`labelservice${row.id}`} label="Service Performed" />}
                                            />
                                          </Form.Group>
                                        </Col>
                                        ) 
                                        : val.name === 'description' ? (
                                        <Col md='9' className="marginTop35">
                                          <Form.Group>
                                            <Autocomplete
                                              id={`input${row.id}${id}`}
                                              freeSolo
                                              onInputChange={(data, value) => descriptionChanged(value, row.id)}
                                              options={autoComplete}
                                              includeInputInList={true}
                                              defaultValue={row.description}
                                              renderInput={(params) => <TextField {...params} label={val.prettyName} />}
                                            />
                                            <p className="inputSuggestion" style={{display: 'none'}} id={`suggestion${row.id}`}>{autoComplete}</p>
                                          </Form.Group>
                                        </Col>
                                        )
                                        : val.name === 'warranty' ?
                                        (
                                        <Col md='3' className="marginTop35">
                                          <Form.Group>
                                            <label className='newQuoteLabel'>{val.prettyName}:</label>
                                            <select className="form-control newQuoteFormControl" onChange={(data) => warrantyChanged(data, row.id)} name={val.name} key={id}>
                                            {<><option value="1 Year/20,000 KM" selected={row.warranty === '1 Year/20,000 KM' ? 'selected' : ''}>1 Year/20,000 KM</option><option value="90 Days/5,000 KM" selected={row.warranty === '90 Days/5,000 KM' ? 'selected' : ''}>90 Days/5,000 KM</option><option value="Lifetime Warranty" selected={row.warranty === 'Lifetime Warranty' ? 'selected' : ''}>Lifetime Warranty</option><option value="Manufaturer Warranty" selected={row.warranty === 'Manufaturer Warranty' ? 'selected' : ''}>Manufaturer Warranty</option></>}
                                            </select>
                                          </Form.Group>
                                        </Col>
                                        )
                                        : val.name === 'partNumber' ?
                                        (
                                        <Col md='3' className="marginTop35">
                                          <Form.Group>
                                            <label className='newQuoteLabel'>{val.prettyName}:</label>
                                            <Form.Control
                                                className={`${val.className} newQuoteFormControl`}
                                                name={val.name}
                                                id={`input${row.id}${id}`}
                                                defaultValue={row.partNumber}
                                                onChange={(data) => partNumberChanged(data, row.id)}
                                                key={id}
                                                style={{textTransform: 'uppercase'}}
                                                type="text"
                                            />
                                          </Form.Group>
                                        </Col>
                                        )
                                        : val.name === 'cost' ?
                                        (
                                        <Col md='2' className="marginTop35">
                                          <Form.Group>
                                            <label className='newQuoteLabel'>{val.prettyName}:</label>
                                            <Form.Control
                                                  className={`${val.className} newQuoteFormControl`}
                                                  name={val.name}
                                                  value={row.cost}
                                                  onChange={(data) => costChanged(data, row.id)}
                                                  key={id}
                                                  type="number"
                                                  step="1"
                                              />
                                          </Form.Group>
                                        </Col>
                                        )
                                        : val.name === 'quantity' ?
                                        (
                                        <Col md='2' className="marginTop35">
                                          <Form.Group>
                                            <label className='newQuoteLabel'>{val.prettyName}:</label>
                                            <Form.Control
                                                  className={`${val.className} newQuoteFormControl`}
                                                  name={val.name}
                                                  value={row.quantity}
                                                  onChange={(data) => quantityChanged(data, row.id)}
                                                  key={id}
                                                  type="number"
                                                  step="1"
                                              />
                                          </Form.Group>
                                        </Col>
                                        )
                                        :
                                        (
                                        <Col md='2' className="marginTop35">
                                          <Form.Group>
                                            <label className='newQuoteLabel'>{val.prettyName}:</label>
                                            <Form.Control
                                                className={`${val.className} newQuoteFormControl`}
                                                name={val.name}
                                                value={row.pricePerUnit}
                                                onChange={(data) => pricePerUnitChanged(data, row.id)}
                                                key={id}
                                                type="number"
                                                step=".01"
                                            />
                                          </Form.Group>
                                        </Col>
                                        )
                                      }
                                    </>
                                  ))}
                                </Row>
                                <Row>
                                  <Col></Col>
                                  <Col>
                                    <Button className="invoiceItemDeleteBtn" onClick={() => deleteItem(row.id)}>Delete Item</Button> 
                                  </Col>
                                </Row>
                              </Col>
                              <hr className="quoteHR" />
                            </Row>
                            )}
                          )}
                              
                          <Row>
                              <Col md='5'>
                                  <Button onClick={(evt) => addInvoiceItem(invoice.id)} className="workOrderBtns">Add Quote Item</Button>
                                  <Button variant="success" onClick={() => printInvoice(invoice.id)} className='marginLeft15 workOrderBtns'>Print</Button>
                                  <Button onClick={() => excludeFee(invoice.id)} variant="success" className='marginLeft15 workOrderBtns'>Exclude Shop Supplies</Button>
                              </Col>  
                              <Col md='7'>
                              <Row>
                                <Col>
                                  <label className="newQuoteCustomerInfoLabel">SUBTOTAL</label>
                                  <p className="newQuoteCustomerInfoHeading">${invoice.total_amount_charged ? (invoice.total_amount_charged).toFixed(2) : null}</p>
                                </Col>
                                  {invoice.shop_supplies ? 
                                    <>
                                    <Col>
                                      <label className="newQuoteCustomerInfoLabel">DISPOSAL FEE/SHOP SUPPLIES</label>
                                      <p className="newQuoteCustomerInfoHeading">${(invoice.total_amount_charged*0.03).toFixed(2)}</p>                              
                                    </Col>
                                    <Col>
                                      <label className="newQuoteCustomerInfoLabel">HST (13%)</label>
                                      <p className="newQuoteCustomerInfoHeading">${(invoice.final_amount_charged*0.13).toFixed(2)}</p>
                                    </Col>
                                    </>
                                  : 
                                    <Col>
                                      <label className="newQuoteCustomerInfoLabel">HST (13%)</label>
                                      <p className="newQuoteCustomerInfoHeading">${(invoice.total_amount_charged*0.13).toFixed(2)}</p>
                                    </Col>
                                  }
                                
                                <Col>
                                  <label className="newQuoteCustomerInfoLabel">Total:</label>
                                  <p className="newQuoteCustomerInfoHeading">${invoice.shop_supplies ? ((invoice.final_amount_charged)*1.13).toFixed(2) : ((invoice.total_amount_charged)*1.13).toFixed(2)}</p>
                                </Col>
                              </Row>
                              </Col>
                          </Row>                      
                      </Form>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <Row>
                        <Button onClick={() => showPerformInspection()} className="inspectionButtons">Perform Inspection</Button>
                        <Button variant="success" onClick={() => openCreate()} className="inspectionButtons">Create New Inspection Type</Button>
                        <Button variant="success" onClick={() => openAssignInspectionItem()} className="inspectionButtons">Assign Items To Inspection Type</Button>
                        <Button variant="success" onClick={() => openCreateInspectionItem()} className="inspectionButtons">Add New Inspection Item</Button>
                      </Row>
                      <Row>
                        {/* map over inspectionResults and display a list item for each result and display child inspectionItem attributes of description and name */}
                        <Col>
                        {inspectionResults.length > 0 ? inspectionResults.map((inspectionResult, id) => {
                          return(
                            <>
                            <Row alignItems="flex-start">
                              <Col>
                                {inspectionResult.result === 'Pass' ? <GiCheckMark style={{display: 'block', margin: 'auto', fontSize: '50px', color: 'green'}} /> : inspectionResult.result === 'Fail' ? <MdOutlineClose style={{display: 'block', margin: 'auto', fontSize: '50px', color: 'red'}}/> : <FaQuestion  style={{display: 'block', margin: 'auto', fontSize: '50px', color: 'gold'}} />}
                              </Col>
                              <Col>
                                <h3>{inspectionResult.result}</h3>
                                <h6>{inspectionResult.inspectionItem.name}</h6>
                                <p>{inspectionResult.inspectionItem.description}</p>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Button onClick={() => changeItemResult(inspectionResult.id, 'Pass')} variant="success" className="inspectionItemButton"><GiCheckMark /></Button>
                                  <Button onClick={() => changeItemResult(inspectionResult.id, 'Fail')} variant="success" className="inspectionItemButton"><MdOutlineClose /></Button>  
                                  <Button onClick={() => openNotes(inspectionResult)} variant="success" className="inspectionItemButton"><MdOutlineSpeakerNotes  /></Button>  
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form onSubmit={(e) => uploadPhoto(e, inspectionResult.id)}>
                                  <Form.Group>
                                    {inspectionResult.photo_url ? <img src={inspectionResult.photo_url} alt='inspection findings' style={{width: '100px', height: '100px'}} /> : null}
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Label>Upload Photo</Form.Label>
                                    <Form.Control name="logo" type="file" className='form-control' placeholder="Logo" required />
                                  </Form.Group>
                                  <Button className='btn btn-primary' type="submit">
                                  Upload
                                  </Button>
                                </Form>
                              </Col>
                            </Row>
                            <Divider variant="inset" style={{marginBottom: '30px'}} />
                            </>
                          )
                        }) : null}
                        </Col>
                      </Row>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                      Coming Soon
                    </CustomTabPanel>
                  </Box>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Modal show={showCreateInspectionModal} onHide={createClose}>
            <Row>
              <Col>
                <p className='modalHeading'>Create New Inspection Type</p>
              </Col>
            </Row>
            <Form onSubmit={(evt) => createNewInspection(evt)}>
              <Row>
                <Col>
                  <span className='modalFormLabel'>Inspection Name:</span>
                  <Form.Control name="name" required className='createCustomerFormInputs' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal show={showAssignInspectionModal} onHide={assignInspectionClose}>
            <Row>
              <Col>
                <p className='modalHeading'>Assign Items To Inspections</p>
                <span className='modalFormLabel'>Adding To: {activeInspection.name}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* map through inspectionList and display */}
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {inspections.map((inspection, id) => {
                    return(
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={activeInspection.id === inspection.id ? true : false}
                              tabIndex={-1}
                              disableRipple
                              onChange={() => inspectionSelected(inspection)}
                              inputProps={{ 'aria-labelledby': `label${inspection.id}` }}
                            />
                          </ListItemIcon>
                          <ListItemText id={`label${inspection.id}`} primary={`${inspection.name}`} />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
              </Col>
              <Col>
                {inspectionItemsList.map((inspectionItem, id) => {
                  return(
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Switch 
                          checked={selectedInspectionItems ? selectedInspectionItems.includes(inspectionItem.id) : false} 
                          onChange={(evt) => toggleInspectionItem(inspectionItem.id, evt)} 
                          />
                        </ListItemIcon>
                        <ListItemText id={`label${inspectionItem.id}`} primary={`${inspectionItem.name}`} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </Col>
            </Row>
          </Modal>
          <Modal show={showCreateInspectionItem} onHide={createInspectionItemClose}>
            <Row>
              <Col>
                <p className='modalHeading'>Create New Inspection Type</p>
              </Col>
            </Row>
            <Form onSubmit={(evt) => createInspectionItem(evt)}>
              <Row>
                <Col>
                  <span className='modalFormLabel'>Item Name:</span>
                  <Form.Control name="name" required className='createCustomerFormInputs' />
                </Col>
                <Col>
                  <span className='modalFormLabel'>Item Description:</span>
                  <Form.Control name="description" required className='createCustomerFormInputs' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal show={performInspectionModal} onHide={performInspectionClose}>
            <Row>
              <Col>
                <p className='modalHeading'>Perform Inspection</p>
              </Col>
            </Row>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {inspections.map((inspection) => {
                const labelId = `checkbox-list-label-${inspection.id}`;

                return (
                  <ListItem
                    key={value}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={() => performInspection(inspection.id)} dense>
                      <ListItemText id={labelId} primary={`${inspection.name}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Modal>
          <Modal show={notesModalStatus} onHide={notesModalClose}>
            <Row>
              <Col>
                <p className='modalHeading'>Inspection Item Notes</p>
              </Col>
            </Row>
            <Row>
              <Form.Control
                className="inspectionNotes"
                as="textarea"
                placeholder="Leave notes here"
                value={activeInspectionResult.notes}
                style={{ height: '400px' }}
                onChange={(e) => updateResultNotes(e)}
              />
            </Row>
          </Modal>
        </Container>
      </>
    :
    <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
      <Triangle
        type="Puff"
        color="#000"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    </div>
  );
}

export default EditWorkOrder;