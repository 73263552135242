import React, { useEffect, useState } from "react";
import moment from 'moment';
import fire from '../../config/Firebase';

import {
  Container,
  Row,
  Col,
  Card,
  Table
} from "react-bootstrap";
import axios from "axios";

function ViewClock(props) {

  const [clockData, setClockData] = useState([]);

  const getClockIns = async () => {
    const clockDataRes = await axios.post(
      "https://redline-autohaus-api.herokuapp.com/clocks/get",
      // "http://localhost/clocks/get",
      {
        idToken: await fire.auth().currentUser.getIdToken(),
      }
    );
    setClockData(clockDataRes.data);
  };

  useEffect(() => {
    getClockIns();
  }, []);

  const clockInList = clockData.map((clock) =>
    <tr>
      <td>{clock.id}</td>
      <td>{clock.technician}</td>
      <td>{clock.type}</td>
      <td>{moment(clock.clock_capture).format('YYYY-MM-DD h:mm A')}</td>
    </tr>
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>Employee Punch Cards</Card.Header>
              <Card.Body>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Technician</th>
                      <th>Punch In/Out</th>
                      <th>Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clockInList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ViewClock;
