import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
} from "react-bootstrap";
import axios from "axios";
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

function Payout(props) {
  const [techs, setTechs] = useState([]);
  const [report, setReport] = useState({});

  const getTechs = async () => {
    const techResponse = await axios.get(
      "https://redline-autohaus-api.herokuapp.com/technicians/get"
    );
    setTechs(techResponse.data);
  };

  useEffect(() => {
    getTechs();
  }, []);

  const techList = techs.map((tech) => (
      <option value={tech.name}>{tech.name}</option>
  ));

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const payload = {
      technician: evt.target.technician.value,
      startDate: moment(evt.target.startDate.value).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(evt.target.endDate.value).format('YYYY-MM-DD HH:mm:ss')
    };
  
    const generateHours = await axios.post(
      "https://redline-autohaus-api.herokuapp.com/payroll/generate",
      payload
    );

    setReport(generateHours.data);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>Generate Payout Report </Card.Header>
              <Card.Body>
                <Form onSubmit={(evt) => handleSubmit(evt)}>
                  <Form.Group>
                    <Form.Label>Technician</Form.Label>
                    <select className="form-control" name="technician" id="technician">  
                      {techList}
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <DateTimePicker type="text" placeholder="Choose Start Date" name="startDate" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <DateTimePicker type="text" placeholder="Choose End Date" name="endDate" />
                  </Form.Group>
                  
                  <Button type="submit" variant="success">Generate</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Header>Report</Card.Header>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Weekly Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{ report.name }</td>
                      <td>{ report.totalHours }</td>
                    </tr>
                  </tbody>
                </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Payout;
