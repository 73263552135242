import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Calendar, Views, momentLocalizer } from 'react-big-calendar';
import momentLocalizerWidget from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import { CookiesProvider, useCookies } from "react-cookie";




const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";


moment.locale('en');
momentLocalizerWidget();
const localizer = momentLocalizer(moment);

function Schedule(props) {

  const [calendarEvents, setCalendarEvents] = useState({});
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [currentDateTimeEnd, setCurrentDateTimeEnd] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentTechnician, setCurrentTechnician] = useState('');
  const [editAppointmentId, setEditAppointmentId] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [vehicleActive, setActiveVehicle] = useState({});
  const [techs, setTechs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [socket, setSocket] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["technician"]);


  function calendarEventTitle(props) {
    return <>{props.vehicle.year} {props.vehicle.make} {props.vehicle.model} <br/> {props.description} </>;
  }

  useEffect(() => {
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);
    console.log(cookies);
    newSocket.on('getAppointments', async (data) => { 
      const calendarEventsArr = [];
      for(let item of data){
        if(cookies.technician && cookies.technician.length > 0 && item.technician && item.technician.id === cookies.technician[0].id){
          calendarEventsArr.push({vehicle: item.vehicle, description: item.description ,technicianId: item.technician ? item.technician.id : 0, color: item.technician ? item.technician.color : '#007bff', id: item.id, title: calendarEventTitle(item), allDay: false, start: moment(item.appointment_date).toDate(), end: item.appointment_date_end ? moment(item.appointment_date_end).toDate() : moment(item.appointment_date).toDate()});
        }else if(!cookies.technician){
          calendarEventsArr.push({vehicle: item.vehicle, description: item.description ,technicianId: item.technician ? item.technician.id : 0, color: item.technician ? item.technician.color : '#007bff', id: item.id, title: calendarEventTitle(item), allDay: false, start: moment(item.appointment_date).toDate(), end: item.appointment_date_end ? moment(item.appointment_date_end).toDate() : moment(item.appointment_date).toDate()});
        }
      }
      setCalendarEvents(calendarEventsArr);
      setLoading(true);
    });

    newSocket.on('getTechnicians', async (data) => {
      if (data.length > 0) { 
        setTechs(data);
      }
    });

    newSocket.on("newQuote", (data) => {
      props.history.push(`/invoices/${data.id}`);
    });

    const fetchData = async () => {
      newSocket.emit('getAppointments', await fire.auth().currentUser.getIdToken());
      newSocket.emit('getTechnicians', await fire.auth().currentUser.getIdToken());
    }
    
    fetchData();
    return () => newSocket.disconnect();
  }, [setSocket]);


    const techSelectId = techs.map((tech) =>
    currentTechnician == tech.id ?  <option value={tech.id} selected>{tech.name}</option> : <option value={tech.id} >{tech.name}</option>
  );

  const deleteAppointmentClose = () => setShowDelete(false);
  const deleteAppointmentShow = () => {setShowDelete(true);};

  const editAppointment = async (event) => {
    event.preventDefault();
    const payload = {
      date: event.target.appointment_date.value,
      end_date: event.target.appointment_date_end.value,
      description: event.target.description.value,
      appointmentId: editAppointmentId,
      technician: event.target.technician.value
    };
    event.target.reset();
    await axios.post('https://redline-autohaus-api.herokuapp.com/appointment/edit', payload);
    await getAppointments();
    setModalState(false);
  }

  const createNewQuote = async (evt, vehicleId) => {

    evt.preventDefault();
    console.log('create new quote');

    let filters;

    if(cookies.technician && cookies.technician.length > 0){
      filters = {
        vehicle_id: vehicleId, 
        idToken: await fire.auth().currentUser.getIdToken(),
        technician: cookies.technician[0].name
      };
    }else{
      filters = {
        vehicle_id: vehicleId, 
        idToken: await fire.auth().currentUser.getIdToken()
      };
    }

    socket.emit('newQuote', filters);

  }

  const viewAppointment = async (data) => {
    setCurrentDateTime(moment(data.start).format('LLLL'));
    setCurrentDateTimeEnd(moment(data.end).format('LLLL'));
    setCurrentDescription(data.description);
    setCurrentTechnician(data.technicianId);
    setEditAppointmentId(data.id);
    setActiveVehicle(data.vehicle);
    setModalState(true);
    console.log(data);
  }

  const getAppointments = async () => {
  
    socket.emit('getAppointments', await fire.auth().currentUser.getIdToken());
    
  };

  const hideModal = async () => {
    setModalState(false);
  }

  const deleteAppointment = async (id) => {
    await axios.get(`https://redline-autohaus-api.herokuapp.com/appointment/delete/${id}`);
    await getAppointments();
    deleteAppointmentClose();
    setModalState(false);
  };

  const techLegend = techs.map((tech) =>
    
  <ListGroup.Item><div style={{ width: '15px', backgroundColor: tech.color, height: '15px', borderRadius: '15px', display: 'inline-block'}}></div> <span>{ tech.name }</span></ListGroup.Item>

  );
  return (
    loading ?
              <>
              <div className="calendarBG">
                <ListGroup horizontal>
                  {techLegend}
                </ListGroup>
                <Calendar
                  localizer={localizer}
                  events={calendarEvents}
                  defaultView={Views.WEEK}
                  min={moment().set('hour', 8).set('minutes', 0).toDate()}
                  max={moment().set('hour', 17).set('minutes', 0).toDate()}
                  startAccessor="start"
                  endAccessor="end"
                  eventPropGetter={event => ({
                    style: {
                      backgroundColor: event.color,
                    },
                  })}
                    onSelectEvent={ data => viewAppointment(data) }
                />
              </div>
              <Modal show={modalState} onHide={hideModal}>
                <Modal.Body>
                  {vehicleActive.customer ? 
                  <Row className="yearMakeModelRow">
                    { vehicleActive.photo ? <Col md="5"><img src={vehicleActive.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px'}}></img></Col> : '' }
                    <Col md="7">
                      <Row className="firstKanbanModelRow">
                        <Col>
                          <Form.Control className="kanbanVehicleModel" disabled defaultValue={vehicleActive.model} />  
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Form.Control className="kanbanMakeYear" disabled defaultValue={vehicleActive.year} />
                        </Col>
                        <Col md="8">
                          <Form.Control className="kanbanMakeYear" disabled defaultValue={vehicleActive.make} />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <span className="kanbanLabel">Name</span><br />
                      <Link to={`/customers/view/${vehicleActive.customer.id}`} className="kanbanMainText">{vehicleActive.customer.name}</Link>
                    </Col>
                    <Col>
                      <span className="kanbanLabel">Phone</span><br />
                      <Card.Text className="kanbanMainText" onClick={() => { navigator.clipboard.writeText(vehicleActive.customer.phone_number) }}> {vehicleActive.customer.phone_number}</Card.Text>
                    </Col>
                  </Row>
                  : null}
                  <Row>
                    <Col className='marginTopScheduleModalStartDate'>
                      <Form id="editAppointmentForm" onSubmit={(evt) => editAppointment(evt)}>
                        <Form.Group>
                          <span className='modalFormLabel'>Start Date & Time:</span>
                          <DateTimePicker input='true' type="text" name="appointment_date" className='calendarInputStyle' required placeholder={currentDateTime} />
                        </Form.Group>
                        <Form.Group>
                          <span className='modalFormLabel'>End Date & Time:</span>
                          <DateTimePicker type="text" name="appointment_date_end" className='calendarInputStyle' required placeholder={currentDateTimeEnd} />
                        </Form.Group>
                        <Form.Group>
                          <span className='modalFormLabel'>Description:</span>
                          <textarea name="description" className="form-control scheduleNotesTextarea" style={{height: '200px'}} type="text" placeholder="Service Description" defaultValue={currentDescription} />
                        </Form.Group>
                        <Form.Group>
                          <span className='modalFormLabel'>Technician:</span>
                          <select  name="technician" className="form-control scheduleTechnicianSelect">
                            {techSelectId}
                          </select>
                        </Form.Group>
                        <Button className="btn-edit-appointment" type="submit" style={{marginRight: '20px'}}>
                            Confirm Changes
                        </Button>
                        <Link to='#' onClick={(evt) => createNewQuote(evt, vehicleActive.id)} style={{marginRight: '20px'}} className="a-edit-appointment"><Button className="btn-edit-appointment btn-quote-appointment" variant="success" type="submit">
                          Quote
                        </Button></Link>
                        <Button className="btn-edit-appointment delete-appointment-btn" variant="success" onClick={() => deleteAppointmentShow()}>
                          Delete Appointment
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
              <Modal show={showDelete} onHide={deleteAppointmentClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p style={{color: '#fff'}}>Are You Sure You Want To Delete This Appointment?</p>
                  <Button variant="success" onClick={() => deleteAppointment(editAppointmentId)}>Yes</Button>
                  <Button variant="danger" onClick={deleteAppointmentClose}>No</Button>
                </Modal.Body>
              </Modal>
              </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#4439bf"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
    </div>
  );
}

export default Schedule;

