import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, Button, FormLabel, Accordion, ListGroup, Form } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import * as moment from 'moment';
import socketIOClient, { io } from "socket.io-client";
import fire from '../../config/Firebase';
const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

function ViewCustomer(props) {
  
  const [customer, setCustomer] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [showVin, setShowVin] = useState(false);
  const [vinBtn, setVinBtn] = useState(true);
  const [totalSpent, setTotalSpent] = useState(0);
  const [socket, setSocket] = useState(null);

  const getCustomers = async () => {
    console.log();
    const customerResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/customers/${props.match.params.id}/get`); 
    console.log(customerResponse);
    setCustomer(customerResponse.data);
    
    customerResponse.data.vehicles.map((vehicle) => {
      vehicle.invoices.map((invoice) => {
        let newTotal = totalSpent+invoice.total_amount_charged;
        setTotalSpent(newTotal);
      });
    });

    setVehicles(customerResponse.data.vehicles.map((vehicle) =>
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {vehicle.year} {vehicle.make} {vehicle.model} 
        </Accordion.Toggle>
        <Link className="float-right" onClick={(evt) => createNewQuote(evt, vehicle.id)}><Button className="btn-success">New Quote</Button></Link>
      </Card.Header>
      <Accordion.Collapse eventKey="0">
          <Card.Body><ListGroup>{vehicle.invoices.map((invoice) => <ListGroup.Item>${invoice.total_amount_charged} - {moment(invoice.createdAt).format('LLLL')} <Link to={`/invoices/${invoice.id}`} className="float-right"><Button>View</Button></Link></ListGroup.Item>)}</ListGroup></Card.Body>
      </Accordion.Collapse>
    </Card>
  ));
  }

  useEffect(() => {
    const fetchData = async () => {
      await getCustomers();
    }

    const socket = socketIOClient(ENDPOINT);
    setSocket(socket);
    socket.on("newInvoice", (data) => {
      props.history.push(`/invoices/${data.id}`);
    });

    fetchData();
  }, [setSocket]);

  const addVin = (event) => {
    event.preventDefault();
    setShowVin(true);
    setVinBtn(false);
    console.log('add Vin');
  }

  const createNewQuote = async (evt, vehicleId) => {
    evt.preventDefault();
    socket.emit('newInvoice', {vehicle_id: vehicleId, tokenId: await fire.auth().currentUser.getIdToken()});
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      customer_id: props.match.params.id,
      vin: showVin ? event.target.vin.value : null,
      year: event.target.year.value,
      make: event.target.make.value,
      model: event.target.model.value
    };
    console.log(payload);
    const createCustomerResponse = await axios.post('https://redline-autohaus-api.herokuapp.com/vehicle/add', payload); 
    getCustomers();
    console.log(createCustomerResponse);
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title>{customer.name}</Card.Title>
                    <FormLabel>Phone:</FormLabel> {customer.phone_number}
                    <br />
                    Total Spent: {totalSpent}
                    <br />
                    Customer Since: {moment(customer.createdAt).fromNow()}
                    <br />
                    <br />
                    <h6>Add Vehicle</h6>
                    <Form onSubmit={(evt) => handleSubmit(evt)}>
                      {showVin ? 
                      <Form.Group>
                        <Form.Control name="vin" type="text" placeholder="VIN" />
                      </Form.Group>
                        : 
                        ''
                      }
                      <Form.Group>
                        <Form.Control name="year" type="text" placeholder="Year" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control name="make" type="text" placeholder="Make" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control name="model" type="text" placeholder="Model" />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Add Vehicle
                      </Button>
                      {vinBtn ? <Button variant="success" type="submit" onClick={(evt) => addVin(evt)} style={{marginLeft: '20px'}}>
                        Add Vin
                      </Button> : ''}
                      
                    </Form>
                  </Col>
                  
                  <Col>
                    <Card.Title>Vehicles</Card.Title>
                    <Accordion defaultActiveKey="0">
                      {vehicles}
                    </Accordion>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ViewCustomer;

