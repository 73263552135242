import React, {useState} from 'react';
import fire from '../config/Firebase';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import autoLogo from '../assets/auth-logo.png';
import loginArt from '../assets/login.png';
import topRightArt from '../assets/top-right-login-art.png'
import '../style/Login.css';

function Login() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (evt) => {
    evt.preventDefault();
    console.log(username);
    console.log(password);
    console.log('login');
    fire.auth().signInWithEmailAndPassword(username, password).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      alert(errorCode+' '+errorMessage);
      // ...
    });
  };

  return (
    <>
    <Container fluid className='authContainer'>
        <Navbar expand="lg" className='transparentBG frontNav authNav desktop'>
              <Link to='/login'><Button variant='success' className='frontBtn authLoginNavLink'>Login</Button></Link>
              <Link to='/register'><Button className='frontBtn'>Sign Up</Button></Link>
        </Navbar>
        <Row className='authRow' style={{height: window.innerHeight+'px'}}>
          <Col className='leftSideLogin'>
              <Link to='/'><img src={autoLogo}></img></Link>
              <h5 className="card-title login-heading">Login</h5>
              <form className="form-signin" onSubmit={handleLogin}>
                <label className="auth-labels" for="inputEmail">Email address</label>
                <div className="form-label-group">
                  <input type="email" id="inputEmail" onChange={evt => setUsername(evt.target.value)} className="form-control auth-form" placeholder="Email address" required autofocus />
                </div>
                <label className="auth-labels" for="inputPassword">Password</label>
                <div className="form-label-group">
                  <input type="password" onChange={evt => setPassword(evt.target.value)} id="inputPassword" className="form-control auth-form" placeholder="Password" required />
                </div>
                <button className="btn sign-in-btn" type="submit">Login</button>
              </form>
          </Col>
          <Col className="rightSideLogin desktop">
            <img className='topRightArtLogin' src={topRightArt}></img>
            <img src={loginArt} className='loginArt'></img>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;

