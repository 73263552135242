import React from 'react';
import { Container, Row, Navbar, Nav, Col, Button, Form, Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/autoclever.png';
import '../style/Frontend.css';
import '../style/Responsive.css';
import heroImg from '../assets/frontend/hero-image.png';
import squareHero from '../assets/frontend/squares-hero.png';
import x from '../assets/frontend/x.png';
import featuresBG from '../assets/frontend/featuresBG.png';
import aiIcon from '../assets/frontend/ai-icon.png';
import scheduleIcon from '../assets/frontend/schedule-icon.png';
import estimatesIcon from '../assets/frontend/estimates-icon.png';
import workflowIcon from '../assets/frontend/workflow-icon.png';
import reportIcon from '../assets/frontend/reports-icon.png';
import invoiceIcon from '../assets/frontend/invoice-icon.png';
import techTrackingBG1 from '../assets/frontend/technician-tracking-bg-1.png';
import techTrackingBG2 from '../assets/frontend/technician-tracking-bg-2.png';
import trackTech from '../assets/frontend/track-tech.png';
import featureCardLowerBG from '../assets/frontend/featureCardLowerBG.png';
import featureCardUpperBG from '../assets/frontend/featureCardUpperBG.png';
import pricingTopBG from '../assets/frontend/pricing-top-bg.png';
import pricingBottomBG from '../assets/frontend/pricing-bottom-bg.png';
import pricingCheck from '../assets/frontend/pricing-check.png';
import pricingBg1 from '../assets/frontend/pricing-bg-1.png';
import pricingBg2 from '../assets/frontend/pricing-bg-2.png';

function Home() {

  return (
    <>
      <Navbar expand="lg" className='transparentBG frontNav'>
        <Container className='frontContainer' fluid>
          <Navbar.Brand href="#home"><img className="frontLogo" src={logo}></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Form inline>
            <Navbar.Collapse className="justify-content-end">
              <Nav className="mr-auto">
                <Nav.Link href="#home" className='navText homeNavText'>Home</Nav.Link>
                <Nav.Link href="#features" className='navText homeNavText'>Features</Nav.Link>
                <Nav.Link href="#reports" className='navText homeNavText'>Reports</Nav.Link>
                <Nav.Link href="#pricing" className='navText homeNavText'>Pricing</Nav.Link>
                <Link to='/login' className='mobile navText homeNavText nav-link'>Login</Link>
                <Link to='/register' className='mobile navText homeNavText nav-link'>Sign Up</Link>
              </Nav>
            </Navbar.Collapse>
            <Link to='/login'><Button variant='success' className='frontBtn desktop'>Login</Button></Link>
            <Link to='/register'><Button className='frontBtn desktop'>Sign Up</Button></Link>
          </Form>
        </Container>
      </Navbar>
      <Container className='frontContainer' fluid>
          <Row className='heroSection'>
            <Col className='heroLeft'>
            <img className='squareHero' src={squareHero}></img>
            <img className='x1' src={x}></img>
            <h1>Welcome <br />to <span className='purple'>Auto Clever</span></h1>
            <p className='heroDescription'>AutoClever is a cloud based auto repair shop management software. It features an AI assistant for efficient customer communication and work order summaries. AutoClever streamlines operations, increases customer satisfaction, tracks technician efficiency and provides valuable business insights. Try AutoClever today and take your auto repair business to the next level.</p>
            <img className='x2' src={x}></img>
            </Col>
            <Col>
            <img className='heroImage' src={heroImg}></img>
            </Col>
          </Row>
          <Row id='features' className='featureSection'>
            <Col>
              <h2 className='sectionTitle'><span className='purple'>Features</span> for every<br /> repair shop <span className='purple'>Function</span></h2>
              <img src={featuresBG} className='featuresSectionBG'></img>
              <Row>
                <Col>
                  <Card className='featuresCard'>
                    <img src={featureCardUpperBG} className='featureCardUpperBG'></img>
                    <div className='featuresIcon'>
                      <img src={aiIcon} style={{marginTop: '30px'}}></img>
                    </div>
                    <h3>Artificial Intelligence</h3>
                    <p className='featuresParagraph'>Our AI technology automates scheduling of customers for service and keeps them informed of their vehicle status through SMS updates. It also generates notes and summaries on work performed, enabling you to focus on running your business without sacrificing customer service or work quality.</p>
                    <img src={featureCardLowerBG} className='featureCardLowerBG'></img>
                  </Card>
                </Col>
                <Col>
                  <Card className='featuresCard'>
                    <img src={featureCardUpperBG} className='featureCardUpperBG'></img>
                    <div className='featuresIcon'>
                      <img src={scheduleIcon} style={{marginTop: '25px'}}></img>
                    </div>
                    <h3>Scheduling</h3>
                    <p className='featuresParagraph'>Our scheduling feature helps repair shops schedule appointments with customers, reducing no-shows by up to 90% with automated SMS reminders. The feature streamlines the scheduling process for repair shops, saving time and improving efficiency.</p>
                    <img src={featureCardLowerBG} className='featureCardLowerBG'></img>
                  </Card>
                </Col>
                <Col>
                  <Card className='featuresCard'>
                    <img src={featureCardUpperBG} className='featureCardUpperBG'></img>
                    <div className='featuresIcon'>
                      <img src={estimatesIcon} style={{marginTop: '25px'}}></img>
                    </div>
                    <h3>Estimates</h3>
                    <p className='featuresParagraph'>Our estimate creation feature allows auto repair shops to quickly and efficiently create accurate estimates and send them to customers via SMS. The feature includes all relevant repair costs, making it easy for customers to understand their repair costs. This feature streamlines the repair process, saves time, and improves efficiency and customer satisfaction.</p>
                    <img src={featureCardLowerBG} className='featureCardLowerBG'></img>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className='featuresCard'>
                    <img src={featureCardUpperBG} className='featureCardUpperBG'></img>
                    <div className='featuresIcon'>
                      <img src={workflowIcon} style={{marginTop: '30px'}}></img>
                    </div>
                    <h3>Workflows</h3>
                    <p className='featuresParagraph'>Our kanban-style workflow feature helps auto repair shops organize estimates and invoices in a clear and efficient manner. The feature allows repair shops to visualize the entire process, from quotes to finalizing and delivering the repaired vehicle. This results in increased efficiency and improved customer satisfaction, as repair shops can ensure that all jobs are completed on time and to a high standard.</p>
                    <img src={featureCardLowerBG} className='featureCardLowerBG'></img>
                  </Card>
                </Col>
                <Col>
                  <Card className='featuresCard'>
                    <img src={featureCardUpperBG} className='featureCardUpperBG'></img>
                    <div className='featuresIcon'>
                      <img src={reportIcon} style={{marginTop: '25px'}}></img>
                    </div>
                    <h3>Reports</h3>
                    <p className='featuresParagraph'>Our revenue tracking feature provides auto repair shops with a comprehensive view of their financial performance. The feature tracks monthly and yearly revenue, giving repair shops valuable insights into their business such as which services are most profitable and overall financial performance. This information can be used to make informed decisions and drive growth, improving the bottom line and providing a better understanding of financial performance.</p>
                    <img src={featureCardLowerBG} className='featureCardLowerBG'></img>
                  </Card>
                </Col>
                <Col>
                  <Card className='featuresCard'>
                    <img src={featureCardUpperBG} className='featureCardUpperBG'></img>
                    <div className='featuresIcon'>
                      <img src={invoiceIcon} style={{marginTop: '25px'}}></img>
                    </div>
                    <h3>Invoices</h3>
                    <p className='featuresParagraph'>Our invoice management feature for auto repair shops simplifies the process of creating and managing invoices for repairs done to customer vehicles. Repair shops can easily generate invoices that include all relevant information, such as labor costs, parts costs, and any applicable taxes. This feature helps repair shops to streamline their billing process, saving time and improving efficiency, while also providing customers with clear and concise invoices that clearly outline the cost of their repairs.</p>
                    <img src={featureCardLowerBG} className='featureCardLowerBG'></img>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row id="reports" className='technicianTrackingSection'>
            <Col>
              <img className='techTrackBG1' src={techTrackingBG1}></img>
              <h2 className='sectionTitleLeft'>Easily track & view<br /> your <span className='purple'>technicians</span> and<br /> <span className='purple'>revenue</span></h2>
              <p className='heroDescription'>Our technician and revenue tracking feature provides auto repair shops with a simple and effective way to track the performance of their technicians and the revenue they generate. The feature presents this information in an easy-to-read format, allowing repair shops to quickly and easily see how each technician is performing and how much revenue they are generating. This information provides valuable insights into the performance of the business, allowing repair shops to make informed decisions and drive growth. With this feature, repair shops can stay on top of their finances and ensure that their business is running smoothly and efficiently.</p>
              <Link to='/register' className='tryFree'><Button className='frontBtn'>Try It For Free Now!</Button></Link>
            </Col>
            <Col>
              <img className='techTrackBG2' src={techTrackingBG2}></img>
              <img className='trackTech' src={trackTech}></img>
            </Col>
          </Row>
          <Row id='pricing' className='pricingSection'>
            <Col>
              <Card className='pricingCard'>
                <img src={pricingTopBG} className='pricingCardUpperBG'></img>
                <p className='pricingTitle'>Get Clever</p>
                <p className='pricingParagraph'>One package , one low cost, no limits, access to all existing and future features. Built for shop owners by shop owners to be the most cost efficient shop management software in the market!</p>
                <p className='price'>$49/m</p>
                <p className='priceBG'>$49/m</p>
                <hr className='priceTableLine' />
                <ListGroup className='priceTable'>
                  <ListGroup.Item className='priceTableItem'>Scheduling <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Estimates <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Workflows <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Reports <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Invoices <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Technician Tracking <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>SMS Messaging <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Artificial Intelligence <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Punch Clock <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                  <ListGroup.Item className='priceTableItem'>Flate Rate Payout Reports <img src={pricingCheck} className='pricingCheckMark'></img></ListGroup.Item>
                </ListGroup>
                <Link to='/register'><Button variant='success' className='frontBtn' id="pricingRegister">Try Now Free</Button></Link>
                <img src={pricingBottomBG} className='pricingCardLowerBG'></img>
              </Card>
              <img src={pricingBg1} className='pricingBg1'></img>
              <img src={pricingBg2} className='pricingBg2'></img>
            </Col>
            <Col className='pricingRightCol'>
              <h2 className='sectionTitleLeft'>Try Auto <span className='purple'>Clever</span> out for<br /> <span className='purple'>free</span> for <span className='purple'>30</span> days.</h2>
              <p className='heroDescription'>Auto Clever invites you to try our product without any financial obligation for 30 days. You won't be required to enter any credit card information, making this trial completely risk-free. This is an excellent opportunity for you to try Auto Clever and see if it meets your needs and expectations before making a purchase. Take advantage of this offer and enjoy a stress-free trial period.</p>
            </Col>
          </Row>
      </Container>
    </>
  );
}

export default Home;

