import firebase from 'firebase';

  const firebaseConfig = {
    apiKey: "AIzaSyCpdytcPVswNIial11wcbXa0FvnSRceSnM",
    authDomain: "watchdog-3a67c.firebaseapp.com",
    databaseURL: "https://watchdog-3a67c.firebaseio.com",
    projectId: "watchdog-3a67c",
    storageBucket: "watchdog-3a67c.appspot.com",
    messagingSenderId: "638923428670",
    appId: "1:638923428670:web:710bc04d41504503ee126f",
    measurementId: "G-7BX1K4BGWB"
  };

  const fire = firebase.initializeApp(firebaseConfig);
  export default fire;