import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Badge, Card, Form, Button, Nav, Modal, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as axios from 'axios';
import moment from 'moment';
import 'react-widgets/dist/css/react-widgets.css';
import momentLocalizerWidget from 'react-widgets-moment';
import {Triangle} from 'react-loader-spinner';
import {momentLocalizer } from 'react-big-calendar';
import { FiSend } from "react-icons/fi";
import { BiCar } from "react-icons/bi";
//SVGs
import smsSVG from '../../assets/sms.svg';
import viewSVG from '../../assets/view.svg';
import deleteSVG from '../../assets/delete.svg';
import unknownCar from '../../assets/unknown.png';
import '../../style/Kanban.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../style/Calendar.css';
import fire from '../../config/Firebase';
import socketIOClient, { io } from "socket.io-client";
import { CookiesProvider, useCookies } from "react-cookie";

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

moment.locale('en');
momentLocalizerWidget();
momentLocalizer(moment);
let deleteId = 0;
function Dashboard(props) {

  const [loading, setLoading] = useState(false);
  const [quotesList, setQuotesList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [techs, setTechs] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showInvoiceDelete, setShowInvoiceDelete] = useState(false);
  const [deliveredItems, setDeliveredItems] = useState([]);
  const [socket, setSocket] = useState(null);
  const [firebaseIdToken, setFirebaseIdToken] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(["technician"]);
  const elRef = {};

  const deleteAppointmentClose = () => setShowDelete(false);

  const deleteInvoiceClose = () => setShowInvoiceDelete(false);
  const deleteInvoiceShow = (invoiceId) => {setShowInvoiceDelete(true); deleteId = invoiceId};

  useEffect(() => {
    console.log(cookies);
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);
    const getAllDataFunc = async () => {

      let filters;

      if(cookies.technician && cookies.technician.length > 0){
        filters = {
          idToken: await fire.auth().currentUser.getIdToken(),
          technician: cookies.technician[0].name,
        };
      }else{
        filters = {
          idToken: await fire.auth().currentUser.getIdToken()
        };
      }

      setFirebaseIdToken(await fire.auth().currentUser.getIdToken());
      newSocket.emit('getAllData', filters);
    }
    getAllDataFunc();

    newSocket.on('searchWorkOrder', async (searchResponse) => {
      console.log(searchResponse);
      const quotesListMap = searchResponse.data.map((quote) => {

        return <Card className="kanban-item" key={quote.id} draggable onDragStart={e => onDragStart(e, quote.id, quote.status)} ref={r => {elRef[quote.id] = r}}>
        <Card.Body>
          {/* {quoteCount < 3 ? <Badge variant="primary">First Time</Badge> : ''}
          {quoteCount <= 7 && quoteCount >= 3 ? <Badge variant="success" style={{marginBottom:'15px'}}>Regular</Badge> : ''}
          {quoteCount > 7 ? <Badge variant="warning" style={{marginBottom:'15px'}}>VIP</Badge> : ''} */}
          <Row>
            <Col>
              <h5 className="jobId">#{quote.id + 1000}</h5>
            </Col>
          </Row>
          <Row className="yearMakeModelRow">
            { quote.vehicle.photo ? <Col md="5" xs="6"><img src={quote.vehicle.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px'}}></img></Col> : <Col md='5' xs="6"><img src={unknownCar} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '-20px' , borderRadius: '10px'}}></img></Col> }
            <Col md="7" xs="6">
              <Row className="firstKanbanModelRow">
                <Col>
                  <Form.Control className="kanbanVehicleModel" defaultValue={quote.vehicle.model} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'model') } />  
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Control className="kanbanMakeYear" defaultValue={quote.vehicle.year} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'year') } />
                </Col>
                <Col md="8">
                  <Form.Control className="kanbanMakeYear" defaultValue={quote.vehicle.make} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'make') } />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <span className="kanbanLabel">Name</span><br />
              <Link to={`/customers/view/${quote.vehicle.customer.id}`} className="kanbanMainText">{quote.vehicle.customer.name}</Link>
            </Col>
            <Col xs="6">
              <span className="kanbanLabel">Phone</span><br />
              <Card.Text className="kanbanMainText" onClick={() => { navigator.clipboard.writeText(quote.vehicle.customer.phone_number) }}> {quote.vehicle.customer.phone_number}</Card.Text>
            </Col>
          </Row>
          <Row>
            {quote.shop_supplies ?
              <>
                <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(quote.final_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((quote.final_amount_charged)*((quote.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
              : 
              <>
               <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(quote.total_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((quote.total_amount_charged)*((quote.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
            }
          </Row>
          

        </Card.Body>
        <Card.Footer className="footer-card">
          <Row>
            <Col md="4">
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey="link-2" onClick={() => approveQuote(socket, quote.id)} className="approveButtonKanban">Approved</Nav.Link>
                </Nav.Item>
              </Nav>  
            </Col>
            <Col md="8">
              <Nav style={{float: 'right'}}>
                <Nav.Item>
                  <Link to={`/invoices/${quote.id}`}><img src={viewSVG} alt="view invoice" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={`/message/${quote.vehicle.customer.id}`}><img src={smsSVG} alt="message" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => sendInvoice(quote.id)}><FiSend /></Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-1"><Link to={`/print/${quote.id}`}><FcPrint /></Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link onClick={() => deleteInvoiceShow(quote.id)}><img src={deleteSVG} alt="delete" /></Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      });

      switch(searchResponse.type){
        case 'Quote':
          setQuotesList([]);
          setQuotesList(quotesListMap);
          break;
        case 'Approved':
          setJobs([]);
          setJobs(searchResponse.data);
          break;
        case 'Invoice':
          setInvoices([]);
          setInvoices(searchResponse.data);
          break;
        case 'Delivered':
          setDeliveredItems([]);
          setDeliveredItems(searchResponse.data);
          break;
      }
    });

    newSocket.on('jobFinalized', async () => {
      setJobs([]);
      setInvoices([]);

      let filters;

      if(cookies.technician && cookies.technician.length > 0){
        filters = {
          idToken: await fire.auth().currentUser.getIdToken(),
          technician: cookies.technician[0].name,
        };
      }else{
        filters = {
          idToken: await fire.auth().currentUser.getIdToken()
        };
      }

      newSocket.emit('getJobs', filters);
      newSocket.emit('getInvoices', filters);
    });

    newSocket.on('changeWorkOrderStatus', async (socketData) => {
      setJobs(socketData.jobs);
      setTechs(socketData.technicians);
      setInvoices(socketData.invoices);
      console.log(socketData);
      setDeliveredItems(socketData.delivered);

      const quotesListMap = socketData.quotes.map((quote) => {
        console.log(elRef[quote.id]);
        console.log(quote.id);
        return <Card className="kanban-item" key={quote.id} draggable ref={r => {elRef[quote.id] = r}} onDragStart={e => onDragStart(e, quote.id, quote.status)}>
        <Card.Body>
          {/* {quoteCount < 3 ? <Badge variant="primary">First Time</Badge> : ''}
          {quoteCount <= 7 && quoteCount >= 3 ? <Badge variant="success" style={{marginBottom:'15px'}}>Regular</Badge> : ''}
          {quoteCount > 7 ? <Badge variant="warning" style={{marginBottom:'15px'}}>VIP</Badge> : ''} */}
          <Row>
            <Col>
              <h5 className="jobId">#{quote.id + 1000}</h5>
            </Col>
          </Row>
          <Row className="yearMakeModelRow">
            { quote.vehicle.photo ? <Col md="5" xs="6"><img src={quote.vehicle.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px'}}></img></Col> : <Col md='5' xs="6"><img src={unknownCar} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '-20px' , borderRadius: '10px'}}></img></Col> }
            <Col md="7" xs="6">
              <Row className="firstKanbanModelRow">
                <Col>
                  <Form.Control className="kanbanVehicleModel" defaultValue={quote.vehicle.model} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'model') } />  
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Control className="kanbanMakeYear" defaultValue={quote.vehicle.year} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'year') } />
                </Col>
                <Col md="8">
                  <Form.Control className="kanbanMakeYear" defaultValue={quote.vehicle.make} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'make') } />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <span className="kanbanLabel">Name</span><br />
              <Link to={`/customers/view/${quote.vehicle.customer.id}`} className="kanbanMainText">{quote.vehicle.customer.name}</Link>
            </Col>
            <Col xs="6">
              <span className="kanbanLabel">Phone</span><br />
              <Card.Text className="kanbanMainText" onClick={() => { navigator.clipboard.writeText(quote.vehicle.customer.phone_number) }}> {quote.vehicle.customer.phone_number}</Card.Text>
            </Col>
          </Row>
          <Row>
            {quote.shop_supplies ?
              <>
                <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(quote.final_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((quote.final_amount_charged)*((quote.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
              : 
              <>
               <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(quote.total_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((quote.total_amount_charged)*((quote.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
            }
          </Row>
          

        </Card.Body>
        <Card.Footer className="footer-card">
          <Row>
            <Col md="4">
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() => approveQuote(newSocket, quote.id)}
                    className="approveButtonKanban flex items-center space-x-2 bg-blue-600 text-white py-2 px-4 rounded-full"
                  >
                    <span>Approved</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="8">
              <Nav style={{float: 'right'}}>
                <Nav.Item>
                  <Link to={`/invoices/${quote.id}`}><img src={viewSVG} alt="view invoice" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={`/message/${quote.vehicle.customer.id}`}><img src={smsSVG} alt="message" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => (newSocket.emit('sendInvoice', {id: quote.id}))}><FiSend /></Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-1"><Link to={`/print/${quote.id}`}><FcPrint /></Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link onClick={() => deleteInvoiceShow(quote.id)}><img src={deleteSVG} alt="delete" /></Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      });
      setQuotesList([]);
      setQuotesList(quotesListMap);
    });

    newSocket.on("getAllData", async (socketData) => {
      
      setJobs(socketData.jobs);
      setTechs(socketData.technicians);
      setInvoices(socketData.invoices);
      console.log(socketData);
      setDeliveredItems(socketData.delivered);

      const quotesListMap = socketData.stats.quotes.map((quote) => {
        
        return <Card className="kanban-item" key={quote.id} draggable ref={r => {elRef[quote.id] = r}} onDragStart={e => onDragStart(e, quote.id, quote.status)}>
        <Card.Body>
          {/* {quoteCount < 3 ? <Badge variant="primary">First Time</Badge> : ''}
          {quoteCount <= 7 && quoteCount >= 3 ? <Badge variant="success" style={{marginBottom:'15px'}}>Regular</Badge> : ''}
          {quoteCount > 7 ? <Badge variant="warning" style={{marginBottom:'15px'}}>VIP</Badge> : ''} */}
          <Row>
            <Col>
              <h5 className="jobId">#{quote.id + 1000}</h5>
            </Col>
          </Row>
          <Row className="yearMakeModelRow">
            { quote.vehicle.photo ? <Col md="5" xs="6"><img src={quote.vehicle.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px'}}></img></Col> : <Col md='5' xs="6"><img src={unknownCar} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '-20px' , borderRadius: '10px'}}></img></Col> }
            <Col md="7" xs="6">
              <Row className="firstKanbanModelRow">
                <Col>
                  <Form.Control className="kanbanVehicleModel" defaultValue={quote.vehicle.model} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'model') } />  
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Control className="kanbanMakeYear" defaultValue={quote.vehicle.year} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'year') } />
                </Col>
                <Col md="8">
                  <Form.Control className="kanbanMakeYear" defaultValue={quote.vehicle.make} onChange={ (evt) => vehicleChangeData(evt, quote.vehicle.id, 'make') } />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <span className="kanbanLabel">Name</span><br />
              <Link to={`/customers/view/${quote.vehicle.customer.id}`} className="kanbanMainText">{quote.vehicle.customer.name}</Link>
            </Col>
            <Col xs="6">
              <span className="kanbanLabel">Phone</span><br />
              <Card.Text className="kanbanMainText" onClick={() => { navigator.clipboard.writeText(quote.vehicle.customer.phone_number) }}> {quote.vehicle.customer.phone_number}</Card.Text>
            </Col>
          </Row>
          <Row>
            {quote.shop_supplies ?
              <>
                <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(quote.final_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((quote.final_amount_charged)*((quote.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
              : 
              <>
               <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(quote.total_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((quote.total_amount_charged)*((quote.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
            }
          </Row>
          

        </Card.Body>
        <Card.Footer className="footer-card">
          <Row>
            <Col md="4">
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() => approveQuote(newSocket, quote.id)}
                      className="approveButtonKanban flex items-center justify-center bg-blue-600 text-white py-2 px-4 rounded-full"
                  >
                      <span className="text-sm leading-none">Approved</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="8">
              <Nav style={{float: 'right'}}>
                <Nav.Item>
                  <Link to={`/invoices/${quote.id}`}><img src={viewSVG} alt="view invoice" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={`/message/${quote.vehicle.customer.id}`}><img src={smsSVG} alt="message" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => (newSocket.emit('sendInvoice', {id: quote.id}))}><FiSend /></Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-1"><Link to={`/print/${quote.id}`}><FcPrint /></Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link onClick={() => deleteInvoiceShow(quote.id)}><img src={deleteSVG} alt="delete" /></Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      });
      setQuotesList([]);
      setQuotesList(quotesListMap);
      setLoading(true);

    });

    newSocket.on('getDelivered', async (data) => {
      setDeliveredItems([]);
      setDeliveredItems(data);
    });

    newSocket.on('getInvoices', async (data) => {
      setInvoices([]);
      setInvoices(data);
    });

    newSocket.on('getJobs', async (data) => {
      setJobs([]);
      setJobs(data);
    });

    return () => {
      newSocket.disconnect();
    };
    
  }, [setSocket]);

  const getInvoices = async () => {

    let filters;

    if(cookies.technician && cookies.technician.length > 0){
      filters = {
        idToken: await fire.auth().currentUser.getIdToken(),
        technician: cookies.technician[0].name,
      };
    }else{
      filters = {
        idToken: await fire.auth().currentUser.getIdToken()
      };
    }

    socket.emit('getInvoices', filters);
  }

  const sendInvoice = async (id) => {
    socket.emit('sendInvoice', {id});
  }

  const getDelivered = async () => {
    socket.emit('getDelivered', {idToken: firebaseIdToken});
  }

  const getJobs = async (newSocket) => {

    let filters;

    if(cookies.technician && cookies.technician.length > 0){
      filters = {
        idToken: await fire.auth().currentUser.getIdToken(),
        technician: cookies.technician[0].name,
      };
    }else{
      filters = {
        idToken: await fire.auth().currentUser.getIdToken()
      };
    }

    if(socket){
      socket.emit('getJobs', filters);
    }else{
      newSocket.emit('getJobs', filters);
    }
  }

  const deleteInvoice = async (id) => {
    await axios.get(`https://redline-autohaus-api.herokuapp.com/invoice/delete/${id}`);
    setLoading(false);
    setJobs([]);
    setInvoices([]);
    setDeliveredItems([]);
    setQuotesList([]);
    await getJobs();
    await getInvoices();
    await getDelivered();
    await getStats();
    deleteInvoiceClose();
  }

  const finalizeJob = async (evt, id) => {

    evt.preventDefault();
    const payload = {
      id: id,
      technician: evt.target.technician.value,
      vin: evt.target.vin.value,
      plate: evt.target.plate.value,
      odometer: evt.target.odometer.value,
      idToken: firebaseIdToken
    };
    
    if(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/g.test(payload.vin) && evt.target.technician.value !== 'select'){
      socket.emit('jobFinalized', payload);
    }else{
      alert('Please enter a valid VIN number, mileage & select the correct technician that did the job!');
    }

  };

  const markDelivered = async (evt, id) => {
    evt.preventDefault();
    const payload = {
      id: id,
      cash: evt.target.cash.value,
      smsrev: evt.target.smsrev.value,
      idToken: firebaseIdToken
    };

    await axios.post(`https://redline-autohaus-api.herokuapp.com/active/jobs/delivered`, payload);
    // await axios.post(`http://localhost/active/jobs/delivered`, payload);
    setInvoices([]);
    setDeliveredItems([]);
    await getInvoices();
    await getDelivered();
  };

  const approveQuote = async (newSocket, id) => {
    console.log('approved clicked');
    axios.get(`https://redline-autohaus-api.herokuapp.com/quotes/${id}/approve`);
    setJobs([]);
    setQuotesList([]);
    getJobs(newSocket);
    getStats(newSocket);
  };

  const invoiceList = invoices.map((invoice) => {
    return <Card className="kanban-item" key={invoice.id} draggable ref={r => {elRef[invoice.id] = r}} onDragStart={e => onDragStart(e, invoice.id, invoice.status)}>
      <form onSubmit={(evt) => markDelivered(evt, invoice.id)}>
      <Card.Body>
        <Row className="yearMakeModelRow">
          <Col>
            <h5 className="jobId">#{invoice.id + 1000}</h5>
          </Col>
          <Col>
          {
            moment().diff(invoice.vehicle.customer.createdAt, 'days') > 30 ?
            <Badge variant="warning" style={{float: 'right'}}>VIP</Badge>
            :
            <Badge variant="success" style={{float: 'right'}}>New Customer</Badge>
          }
          </Col>
        </Row>
        <Row className="yearMakeModelRow">
          { invoice.vehicle.photo ? <Col md="5" xs="6"><img src={invoice.vehicle.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px'}}></img></Col> : <Col md='5' xs="6"><img src={unknownCar} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '-20px' , borderRadius: '10px'}}></img></Col> }
          <Col md="7" xs="6">
            <Row className="firstKanbanModelRow">
              <Col>
                <Form.Control className="kanbanVehicleModel" defaultValue={invoice.vehicle.model} onChange={ (evt) => vehicleChangeData(evt, invoice.vehicle.id, 'model') } />  
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Control className="kanbanMakeYear" defaultValue={invoice.vehicle.year} onChange={ (evt) => vehicleChangeData(evt, invoice.vehicle.id, 'year') } />
              </Col>
              <Col md="8">
                <Form.Control className="kanbanMakeYear" defaultValue={invoice.vehicle.make} onChange={ (evt) => vehicleChangeData(evt, invoice.vehicle.id, 'make') } />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <span className="kanbanLabel">Name</span><br />
            <Link to={`/customers/view/${invoice.vehicle.customer.id}`} className="kanbanMainText">{invoice.vehicle.customer.name}</Link>
          </Col>
          <Col xs="6">
            <span className="kanbanLabel">Phone</span><br />
            <Card.Text className="kanbanMainText" onClick={() => { navigator.clipboard.writeText(invoice.vehicle.customer.phone_number) }}> {invoice.vehicle.customer.phone_number}</Card.Text>
          </Col>
        </Row>
        <Row>
          {invoice.shop_supplies ?
            <>
              <Col xs="6">
                <span className="kanbanLabel">Pre Tax Amount</span>
                <Card.Text className="kanbanMainText">
                  ${(invoice.final_amount_charged).toFixed(2)}
                </Card.Text>
              </Col>
              <Col xs="6">
                <span className="kanbanLabel">Total Amount</span>
                <Card.Text className="kanbanMainText">
                  ${((invoice.final_amount_charged)*((invoice.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                </Card.Text>
              </Col>
            </>
            : 
            <>
              <Col xs="6">
                <span className="kanbanLabel">Pre Tax Amount</span>
                <Card.Text className="kanbanMainText">
                  ${(invoice.total_amount_charged).toFixed(2)}
                </Card.Text>
              </Col>
              <Col xs="6">
                <span className="kanbanLabel">Total Amount</span>
                <Card.Text className="kanbanMainText">
                  ${((invoice.total_amount_charged)*((invoice.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                </Card.Text>
              </Col>
            </>
          }
        </Row>
        <Row>
          <Col md="12">
            <span className="kanbanLabel">Assigned Technician</span>
            <Card.Text className="kanbanMainText">{invoice.technician}</Card.Text>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <span className="kanbanLabel">Finalized Date & Time</span>
            <Card.Text className="kanbanMainText">{moment(invoice.finalized_at).format('YYYY-MM-DD - h:mm A')}</Card.Text>
          </Col>
        </Row>
        
          <Form.Group>
              <Row>
                <Col xs="6">
                  <FormControl
                    placeholder="Cash Received"
                    aria-label="Cash Received"
                    name="cash"
                    required="required"
                    type="text"
                  />
                </Col>
                <Col xs="6">
                  <select name="smsrev" className="form-control">
                    <option value="1">Send SMS Review</option>
                    <option value="0">Do Not Send SMS Review</option>
                  </select>
                </Col>
              </Row>
            </Form.Group>
        
      </Card.Body>
      <Card.Footer className="footer-card">
          <Row>
            <Col md="4">
              <Nav>
                <Nav.Item>
                  <Button type="submit" className="approveButtonKanban sendToPaid">Paid</Button>
                </Nav.Item>
              </Nav>  
            </Col>
            <Col md="8">
              <Nav style={{float: 'right'}}>
                <Nav.Item>
                  <Link to={`/invoices/${invoice.id}`}><img src={viewSVG} alt="view invoice" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={`/message/${invoice.vehicle.customer.id}`}><img src={smsSVG} alt="message" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => sendInvoice(invoice.id)}><FiSend /></Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-1"><Link to={`/print/${invoice.id}`}><FcPrint /></Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link onClick={() => deleteInvoiceShow(invoice.id)}><img src={deleteSVG} alt="delete" /></Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Card.Footer>
      </form>
    </Card>
});

  const deliveredList = deliveredItems.map((invoice) => {
    return <Card className="kanban-item" key={invoice.id} draggable ref={r => {elRef[invoice.id] = r}} onDragStart={e => onDragStart(e, invoice.id, invoice.status)}>
      <Card.Body>
      <Row className="yearMakeModelRow">
          <Col>
            <h5 className="jobId">#{invoice.id + 1000}</h5>
          </Col>
          <Col>
          {
            moment().diff(invoice.vehicle.customer.createdAt, 'days') > 30 ?
            <Badge variant="warning" style={{float: 'right'}}>VIP</Badge>
            :
            <Badge variant="success" style={{float: 'right'}}>New Customer</Badge>
          }
          </Col>
        </Row>
        <Row className="yearMakeModelRow">
          { invoice.vehicle.photo ? <Col md="5" xs="6"><img src={invoice.vehicle.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px'}}></img></Col> : <Col md='5' xs="6"><img src={unknownCar} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '-20px' , borderRadius: '10px'}}></img></Col> }
          <Col md="7" xs="6">
            <Row className="firstKanbanModelRow">
              <Col>
                <Form.Control className="kanbanVehicleModel" defaultValue={invoice.vehicle.model} onChange={ (evt) => vehicleChangeData(evt, invoice.vehicle.id, 'model') } />  
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Control className="kanbanMakeYear" defaultValue={invoice.vehicle.year} onChange={ (evt) => vehicleChangeData(evt, invoice.vehicle.id, 'year') } />
              </Col>
              <Col md="8">
                <Form.Control className="kanbanMakeYear" defaultValue={invoice.vehicle.make} onChange={ (evt) => vehicleChangeData(evt, invoice.vehicle.id, 'make') } />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <span className="kanbanLabel">Name</span><br />
            <Link to={`/customers/view/${invoice.vehicle.customer.id}`} className="kanbanMainText">{invoice.vehicle.customer.name}</Link>
          </Col>
          <Col xs="6">
            <span className="kanbanLabel">Phone</span><br />
            <Card.Text className="kanbanMainText" onClick={() => { navigator.clipboard.writeText(invoice.vehicle.customer.phone_number) }}> {invoice.vehicle.customer.phone_number}</Card.Text>
          </Col>
        </Row>
        <Row>
          {invoice.shop_supplies ?
            <>
              <Col xs="6">
                <span className="kanbanLabel">Pre Tax Amount</span>
                <Card.Text className="kanbanMainText">
                  ${(invoice.final_amount_charged).toFixed(2)}
                </Card.Text>
              </Col>
              <Col xs="6">
                <span className="kanbanLabel">Total Amount</span>
                <Card.Text className="kanbanMainText">
                  ${((invoice.final_amount_charged)*((invoice.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                </Card.Text>
              </Col>
            </>
            : 
            <>
              <Col xs="6">
                <span className="kanbanLabel">Pre Tax Amount</span>
                <Card.Text className="kanbanMainText">
                  ${(invoice.total_amount_charged).toFixed(2)}
                </Card.Text>
              </Col>
              <Col xs="6">
                <span className="kanbanLabel">Total Amount</span>
                <Card.Text className="kanbanMainText">
                  ${((invoice.total_amount_charged)*((invoice.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                </Card.Text>
              </Col>
            </>
          }
        </Row>
        <Row>
          <Col md="12">
            <span className="kanbanLabel">Assigned Technician</span>
            <Card.Text className="kanbanMainText">{invoice.technician}</Card.Text>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <span className="kanbanLabel">Delivered Date & Time</span>
            <Card.Text className="kanbanMainText">{moment(invoice.updatedAt).format('YYYY-MM-DD - h:mm A')}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="footer-card">
        <Nav style={{float: 'right'}}>
          <Nav.Item>
            <Link to={`/invoices/${invoice.id}`}><img src={viewSVG} alt="view invoice" /></Link>
          </Nav.Item>
          <Nav.Item>
            <Link to={`/message/${invoice.vehicle.customer.id}`}><img src={smsSVG} alt="message" /></Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => sendInvoice(invoice.id)}><FiSend /></Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="link-1"><Link to={`/print/${invoice.id}`}><FcPrint /></Link>
          </Nav.Item> */}
          <Nav.Item>
            <Nav.Link onClick={() => deleteInvoiceShow(invoice.id)}><img src={deleteSVG} alt="delete" /></Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Footer>
    </Card>
});

  const techSelect = (technician) => {
    return techs.map((tech) =>
      <option key={tech.id} value={tech.name} selected={tech.name === technician ? true : false}>{tech.name}</option>
    );
  }

  // const techSelectId = techs.map((tech) =>
  //   currentTechnician == tech.id ?  <option value={tech.id} selected>{tech.name}</option> : <option value={tech.id} >{tech.name}</option>
  // );

  const jobList = jobs.map((job) =>
      {
      let rowClassBg;
      let partCurrentStatus;
      let partStatus = {ordered: 0, inStock: 0, notOrdered: 0};
      job.invoiceItems.map((item) => {
        if(item.service_type == 'Parts'){
          switch(true){
            case moment().isSameOrAfter(moment(item.eta).format('YYYY-MM-DD')):
              partStatus.inStock++;
              break;
            case moment().isBefore(moment(item.eta).format('YYYY-MM-DD')):
              partStatus.ordered++;
              break;
            default:
              partStatus.notOrdered++;
              break;
          }
        }
      });

      switch(true){
      case partStatus.ordered > 0:
        rowClassBg = 'orderedBg';
        partCurrentStatus = 'Parts Ordered';
        break;
      case partStatus.notOrdered > 0:
        rowClassBg = 'notOrderedBg';
        partCurrentStatus = 'Parts Not Ordered';
        break;
      default:
        rowClassBg = 'inStockBg';
        partCurrentStatus = 'Parts In Stock';
        break;
      }
        
      return(
        <Card className="kanban-item" key={job.id} draggable onDragStart={e => onDragStart(e, job.id, job.status)} ref={r => {elRef[job.id] = r}}>
        <form onSubmit={(evt) => finalizeJob(evt, job.id)} className='finalizeJobForm'>
        <Card.Body>
          <Row className="yearMakeModelRow">
            <Col>
              <h5 className="jobId">#{job.id + 1000}</h5>
            </Col>
            <Col>
              <Badge variant="warning" style={{float: 'right'}} className={partStatus.inStock > 0 || partStatus.ordered > 0 || partStatus.notOrdered > 0 ? rowClassBg : ''}>{partCurrentStatus}</Badge>
            </Col>
          </Row>
          <Row className="yearMakeModelRow">
            { job.vehicle.photo ? <Col md="5" xs="6"><img src={job.vehicle.photo} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '20px' , borderRadius: '10px'}}></img></Col> : <Col md='5' xs="6"><img src={unknownCar} alt="Redline Autohaus & Performance" style={{ maxWidth: '100%', marginBottom: '-20px' , borderRadius: '10px'}}></img></Col> }
            <Col md="7" xs="6">
              <Row className="firstKanbanModelRow">
                <Col>
                  <Form.Control className="kanbanVehicleModel" defaultValue={job.vehicle.model} onChange={ (evt) => vehicleChangeData(evt, job.vehicle.id, 'model') } />  
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Control className="kanbanMakeYear" defaultValue={job.vehicle.year} onChange={ (evt) => vehicleChangeData(evt, job.vehicle.id, 'year') } />
                </Col>
                <Col md="8">
                  <Form.Control className="kanbanMakeYear" defaultValue={job.vehicle.make} onChange={ (evt) => vehicleChangeData(evt, job.vehicle.id, 'make') } />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <span className="kanbanLabel">Name</span><br />
              <Link to={`/customers/view/${job.vehicle.customer.id}`} className="kanbanMainText">{job.vehicle.customer.name}</Link>
            </Col>
            <Col xs="6">
              <span className="kanbanLabel">Phone</span><br />
              <Card.Text className="kanbanMainText" onClick={() => { navigator.clipboard.writeText(job.vehicle.customer.phone_number) }}> {job.vehicle.customer.phone_number}</Card.Text>
            </Col>
          </Row>
          <Row>
            {job.shop_supplies ?
              <>
                <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(job.final_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((job.final_amount_charged)*((job.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
              : 
              <>
               <Col xs="6">
                  <span className="kanbanLabel">Pre Tax Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${(job.total_amount_charged).toFixed(2)}
                  </Card.Text>
                </Col>
                <Col xs="6">
                  <span className="kanbanLabel">Total Amount</span>
                  <Card.Text className="kanbanMainText">
                    ${((job.total_amount_charged)*((job.vehicle.customer.shop.tax_amount/100)+1)).toFixed(2)}
                  </Card.Text>
                </Col>
              </>
            }
          </Row>
         
            <Form.Group>
              <Row>
                <Col xs="6">
                  <select name="technician" className="form-control">
                    <option value="select">Select Technician</option> 
                    {techSelect(job.technician)}
                  </select>
                </Col>
                <Col xs="6">
                  <FormControl
                    placeholder="License Plate #"
                    aria-label="License Plate #"
                    name="plate"
                    required="required"
                    type="text"
                    defaultValue={job.vehicle.plate ? (job.vehicle.plate).toUpperCase() : ''}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xs="6">
                  <FormControl
                  placeholder="Odometer"
                  aria-label="Odometer"
                  name="odometer"
                  required="required"
                  type="text"
                  />
                </Col>
                <Col xs="6">
                  <FormControl
                    placeholder="VIN #"
                    aria-label="VIN #"
                    name="vin"
                    required="required"
                    type="text"
                    defaultValue={job.vehicle && job.vehicle.vin ? (job.vehicle.vin).toUpperCase() : null}
                  />
                </Col>
              </Row>
            </Form.Group>
            {/* <Form.Group>
              <Button type="submit" className="btn-success btn100width">Generate Final Invoice</Button>
            </Form.Group> */}
          

        </Card.Body>
        <Card.Footer className="footer-card">
          <Row>
            <Col md="4">
              <Nav>
                <Nav.Item>
                  <Button type="submit" className="approveButtonKanban sendToUnpaid">Finalized</Button>
                </Nav.Item>
              </Nav>  
            </Col>
            <Col md="8">
              <Nav style={{float: 'right'}}>
                <Nav.Item>
                  <Link to={`/invoices/${job.id}`}><img src={viewSVG} alt="view invoice" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={`/message/${job.vehicle.customer.id}`}><img src={smsSVG} alt="message" /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => sendInvoice(job.id)}><FiSend /></Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-1"><Link to={`/print/${invoice.id}`}><FcPrint /></Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link onClick={() => deleteInvoiceShow(job.id)}><img src={deleteSVG} alt="delete" /></Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Card.Footer>
        </form>
      </Card>
      )
    }
  );

  const vehicleChangeData = async (evt, vehicleId, type) => {
    const newVal = evt.target.value;
    await axios.get(`https://redline-autohaus-api.herokuapp.com/update/vehicle/${vehicleId}/${type}/${newVal}`);
  }

  
  
  const getStats = async (newSocket) => {

    let filters;

    if(cookies.technician && cookies.technician.length > 0){
      filters = {
        idToken: await fire.auth().currentUser.getIdToken(),
        technician: cookies.technician[0].name,
      };
    }else{
      filters = {
        idToken: await fire.auth().currentUser.getIdToken()
      };
    }

    if(socket){
      socket.emit('getAllData', filters);
    }else{
      newSocket.emit('getAllData', filters);
    }

  }


  const getAppointments = async () => {
    const appointmentsResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/appointment/get');
 
    const calendarEventsArr = [];
    for(let item of appointmentsResponse.data){
      calendarEventsArr.push({color: item.technician ? item.technician.color : '#007bff', id: item.id, title: item.description, allDay: false, start: moment(item.appointment_date).toDate(), end: item.appointment_date_end ? moment(item.appointment_date_end).toDate() : moment(item.appointment_date).toDate()});
    }
    // setAppointments(appointmentsResponse.data);
  };

  // const searchForAppointment = async (evt) => {
  //   if(evt.target.value){
  //     const searchResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/appointment/search/${evt.target.value}`);
  //     setAppointments([]);
  //     setAppointments(searchResponse.data);
  //   }else{
  //     getAppointments();
  //   }
  // }

  const searchWorkOrder = async (evt, type) => {
    console.log(evt.target.value);
    if(evt.target.value){
      socket.emit('searchWorkOrder', {type: type, value: evt.target.value, idToken: await fire.auth().currentUser.getIdToken()});
    }else{
      switch(type){
        case 'Quote':
          getStats();
          break;
        case 'Approved':
          getJobs();
          break;
        case 'Invoice':
          getInvoices();
          break;
        case 'Delivered':
          getDelivered();
          break;
      }
    }
  }

  const deleteAppointment = async (id) => {
    await axios.get(`https://redline-autohaus-api.herokuapp.com/appointment/delete/${id}`);
    await getAppointments();
    deleteAppointmentClose();
  };

  const onDragOver = async (e) => {
    e.preventDefault();
  };
  
  const onDropInQuotes = async (e) => {
    let id = e.dataTransfer.getData("id");
    socket.emit('changeWorkOrderStatus', {invoiceId: id, status: 'Quote', idToken: await fire.auth().currentUser.getIdToken()});
  }

  const onDropInJobs = async (e) => {
    let id = e.dataTransfer.getData("id");
    approveQuote(socket, id);
    socket.emit('changeWorkOrderStatus', {invoiceId: id, status: 'Approved', idToken: await fire.auth().currentUser.getIdToken()});
  }

  const onDropInUnpaid = async (e) => {
    let id = e.dataTransfer.getData("id");
    let status = e.dataTransfer.getData("status");
    if(elRef[id] && elRef[id].getElementsByClassName('sendToUnpaid')[0]){
      elRef[id].getElementsByClassName('sendToUnpaid')[0].click();
    }else if(status === 'Delivered'){
      socket.emit('changeWorkOrderStatus', {invoiceId: id, status: 'Invoice', idToken: await fire.auth().currentUser.getIdToken()});
    }else{
      alert('An error has occured. Invoices can only be moved to unpaid once they have been sent to the approved section.');
    }
  }

  const onDropInPaid = async (e) => {
    let id = e.dataTransfer.getData("id");
    if(elRef[id] && elRef[id].getElementsByClassName('sendToPaid')[0]){
      elRef[id].getElementsByClassName('sendToPaid')[0].click();
    }else{
      alert('An error has occured. Invoices can only be moved to paid once they have been sent to the unpaid section.');
    }
  }

  const onDragStart = async (e, id, status) => {
    e.dataTransfer.setData("id", id);
    e.dataTransfer.setData("status", status);
  };

  return (
    loading ?
      <>
          <Container fluid>
            <Row>
              {/* <Col>
                <Card>
                  <Card.Header>
                    Appointments
                    <Form.Control className="transparentSearch" name="name" type="text" onChange={(evt) => searchForAppointment(evt)} placeholder="Search..." />
                  </Card.Header>
                </Card>
                {appointmentList}
              </Col> */}
              <Col xs='12' sm='12' md='6' lg='6' xl='3'onDragOver={(evt) => onDragOver(evt)} onDrop={(evt) => onDropInQuotes(evt)}>
                <div className="kanbanHeader">
                  <span className="kanbanHeading">Quotes</span>
                  <Form.Control className="kanbanSearch" name="name" type="text" onChange={(evt) => searchWorkOrder(evt, 'Quote')} placeholder="Search..." />
                </div>
                
                {quotesList}
              </Col>
              <Col xs='12' sm='12' md='6' lg='6' xl='3' className='droppable' onDragOver={(evt) => onDragOver(evt)} onDrop={(evt) => onDropInJobs(evt)}>
                <div className="kanbanHeader">
                  <span className="kanbanHeading">Approved Jobs</span>
                  <Form.Control className="kanbanSearch" name="name" onChange={(evt) => searchWorkOrder(evt, 'Approved')} type="text" placeholder="Search..." />
                </div>
                {
                  jobList
                }
              </Col>
              <Col xs='12' sm='12' md='6' lg='6' xl='3' onDragOver={(evt) => onDragOver(evt)} onDrop={(evt) => onDropInUnpaid(evt)}>
                <div className="kanbanHeader">
                  <span className="kanbanHeading">Unpaid</span>
                  <Form.Control className="kanbanSearch" name="name" onChange={(evt) => searchWorkOrder(evt, 'Invoice')} type="text" placeholder="Search..." />
                </div>
                {
                  invoiceList
                }
              </Col>
              <Col xs='12' sm='12' md='6' lg='6' xl='3' onDragOver={(evt) => onDragOver(evt)} onDrop={(evt) => onDropInPaid(evt)}>
              <div className="kanbanHeader">
                  <span className="kanbanHeading">Paid</span>
                  <Form.Control className="kanbanSearch" name="name" type="text" onChange={(evt) => searchWorkOrder(evt, 'Delivered')} placeholder="Search..." />
                </div>
                {deliveredList}
              </Col>
            </Row>
          </Container>
          <Modal show={showDelete} onHide={deleteAppointmentClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{color: '#fff'}}>Are You Sure You Want To Delete This Appointment?</p>
              <Button variant="success" onClick={() => deleteAppointment(deleteId)}>Yes</Button>
              <Button variant="danger" onClick={deleteAppointmentClose}>No</Button>
            </Modal.Body>
          </Modal>
          <Modal show={showInvoiceDelete} onHide={deleteInvoiceClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{color: '#fff'}}>Are You Sure You Want To Delete This Invoice?</p>
              <Button variant="success" onClick={() => deleteInvoice(deleteId)}>Yes</Button>
              <Button variant="danger" onClick={deleteInvoiceClose}>No</Button>
            </Modal.Body>
          </Modal>
      </>
      :
      <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
        <Triangle
         color="#4439bf"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
      </div>
  );
}

export default Dashboard;

