import React, { useEffect, useState } from 'react';
import { Container, Row, Card, Button } from 'react-bootstrap';
import {Triangle} from 'react-loader-spinner';
import Draggable from 'react-draggable';
import {IoClose} from 'react-icons/io5';
import socketIOClient from "socket.io-client";
import fire from '../../config/Firebase';

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";

function Notes() {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [techs, setTechs] = useState([]);
  const [socket, setSocket] = useState(null);
  const [currentFilter, setCurrentFilter] = useState('All');
  const stoppedDragging = async (e, data, id) => {
    console.log(e);
    console.log(data);
    socket.emit('updateNotePosition', {id: id, x: data.x, y: data.y, idToken: await fire.auth().currentUser.getIdToken()});
  }

  const updateNoteStatus = async (id) => {
    console.log(id);
    socket.emit('updateNoteStatus', {id, idToken: await fire.auth().currentUser.getIdToken(), technician: currentFilter});
  }
  
  const newNote = async () => {
    socket.emit('newNote', {idToken: await fire.auth().currentUser.getIdToken(), technician: currentFilter});
  }

  const updateNote = async (evt, id) => {
    console.log(evt.target.style);
    console.log(id);
    evt.target.style.height = 'inherit';
    evt.target.style.height = `${evt.target.scrollHeight}px`; 
    socket.emit('updateNotes', {notes: evt.target.value, id: id, idToken: await fire.auth().currentUser.getIdToken()});
  }
  
  const assignTechnician = async (e, id) => {
    socket.emit('assignTechnician', {techId: e.target.value, id, idToken: await fire.auth().currentUser.getIdToken(), technician: currentFilter});
  }
  
  const filterNotesByTechnician = async (e) => {
    if(e.target.value == 'All'){
      socket.emit('getNotes', await fire.auth().currentUser.getIdToken());
      setCurrentFilter('All');
    }else{
      setCurrentFilter(e.target.value);
      socket.emit('filterNotesByTechnician', {technician: e.target.value, idToken: await fire.auth().currentUser.getIdToken()});
    }
  }

  useEffect(() => {

    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    const getTechs = async () => {
      newSocket.emit('getTechnicians', await fire.auth().currentUser.getIdToken());
      newSocket.emit('getNotes', await fire.auth().currentUser.getIdToken());
    }

    getTechs();

    newSocket.on('getTechnicians', async (data) => {
      setTechs(data);
    });

    newSocket.on("notes", async (data) => {

      setNotes([]);
      setNotes(data);
      setLoading(true);

      const allWithClass = Array.from(
        document.getElementsByClassName('notesTextarea')
      );

      for(let c in allWithClass){
        allWithClass[c].style.height = allWithClass[c].scrollHeight+'px'
      }

    });

    return () => newSocket.disconnect();

  }, [setSocket]);
  console.log(techs);
  const technicianList = techs.length > 0 ? techs.map((tech) => {
    return <option value={tech.name}>{tech.name}</option>
  }) : null;

  const notesList = notes.map((note) => {

    const techList = techs.length > 0 ? techs.map((tech) => {
      if(note.technician == tech.name)
      return <option selected value={tech.id}>{tech.name}</option>
      else
      return <option value={tech.id}>{tech.name}</option>
    }) : null;

    if(note.x)
    return <Draggable defaultPosition={{x: note.x, y: note.y}} onStop={(e, data) => stoppedDragging(e, data, note.id)}>
        <Card className="notes">
          <Card.Header><IoClose onClick={() => updateNoteStatus(note.id)} style={{color: '#000', float: 'right'}}></IoClose></Card.Header>
          <Card.Body>
            <select onChange={(e) => assignTechnician(e, note.id)} className='form-control noteSelect'>
              <option>Assign To:</option>
              {techList}
            </select>
            <textarea className="notesTextarea" onKeyUp={(e) => {updateNote(e, note.id)}} defaultValue={note.notes} style={{width: '100%', background: 'rgba(0,0,0,0.0)', border: '0px', color: '#000', padding: '10px'}}></textarea>
          </Card.Body>
        </Card>
      </Draggable>
    else
    return <Draggable onStop={(e, data) => stoppedDragging(e, data, note.id)}>
        <Card className="notes">
          <Card.Header><IoClose onClick={() => updateNoteStatus(note.id)} style={{color: '#000', float: 'right'}}></IoClose></Card.Header>
          <Card.Body>
            <select onChange={(e) => assignTechnician(e, note.id)} className='form-control noteSelect'>
              <option>Assign To:</option>
              {techList}
            </select>
            <textarea className="notesTextarea" onKeyUp={(e) => {updateNote(e, note.id)}} defaultValue={note.notes} style={{width: '100%', background: 'rgba(0,0,0,0.0)', border: '0px', color: '#000', padding: '10px'}}></textarea>
          </Card.Body>
        </Card>
      </Draggable>
  });

  return (
    loading ?
    <>
      <Container fluid style={{padding: '0 30px'}}>
        <Row>
          <Button onClick={() => newNote()} style={{display: 'inline'}}>New Note</Button>
          <select onChange={(e) => filterNotesByTechnician(e)}  className="form-control" style={{width: '250px', display: 'inline', marginLeft: '20px'}}>
            <option value="All">Filter By Technician:</option>
            <option value="All">View All</option>
            {technicianList}
          </select>
        </Row>
        <Row>
          {notesList}
        </Row>
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default Notes;

