import React from 'react';
import Draggable from 'react-draggable';
import '../../style/Responsibilities.css';

function Responsibilities(props) {

  return (
    <Draggable>
      <div className='responsibilities'>
      <div class="tree">
        <ul>
          <li>
            <a onClick={e => e.preventDefault()}><b>Dillon</b><br />Service Manager<br /><b>Responsibilities:</b><br /><br />
                    Answer All Phone Calls<br /><br />
                      Send work orders to approved once job is sold.<br /><br />
                      Take care of customer service and front desk<br /><br />
                      Bill customers and cash them out<br /><br />
                      Advise customers of service required
                  <br /><br />
                  Perform safety inspections<br /><br />
                  Review all estimates or quotes prior to advising service<br /><br />
              Order any specialty parts required (Performance Parts, Modules, Engines, Transmissions, Clutches etc.)<br /><br />
              Order any tires once quoted and approved<br /><br />
              Remain at the front desk to ensure highest level of customer service, only to go out on shop floor for safety's and possible diags
                    </a>
            <ul>
              <li>
                <a onClick={e => e.preventDefault()}><b>Jason</b><br />Shop Floor Man<br /><b>Responsibilities:</b><br /><br />
                    Distribute work load to the best technician fit for the job<br /><br />
                      Diagnose all vehicles that come in for service and do a complete check over for additional work that needs to be advised on a seperate work order.<br /><br />
                      Create quotes for all vehicles that come in for service on AutoLogic with VIN, odometer, and plate<br /><br />
                      Order any and all parts required for approved work orders (except tires and specialty parts that have already been stated as items the service manager will order)<br /><br />
                      Test drive every vehicle prior to finalizing to ensure the work has been done correctly and that the vehicle drives in an acceptable manner.
                  <br /><br />
                  Aid and mentor other technicians to get their jobs done quickly and efficiently<br /><br />
                  Keep specialty tools organized and ensure they are easy to find when required<br /><br />
                  Remain on shop floor and shop office to ensure highest level of vehicle service.<br /><br />
                  Report to Dillon via notes and invoicing system in AutoClever or cellular phone
                    </a>
                <ul>
                  <li>
                    <a onClick={e => e.preventDefault()}><b>Christian</b><br />Technician<br /><b>Responsibilities:</b><br /><br />
                    Perform "Specialty Labour" jobs<br /><br />
                      Repair & service automotive vehicles<br /><br />
                      Clean & organize shop<br /><br />
                      Keep shop floor clean of fluids and coolant, ensure floor is not slippery<br /><br />
                      Clean lunch room, office, and washrooms every Monday 8-9 AM<br /><br />
                      Remain on the shop floor and report to Jason of any findings, do not report to Management.
                    </a>
                  </li>
                  <li>
                    <a onClick={e => e.preventDefault()}><b>Rei</b><br />Technician<br /><b>Responsibilities:</b><br /><br />
                      Repair & service automotive vehicles<br /><br />
                      Detail automotive vehicles<br /><br />
                      Clean & organize shop<br /><br />
                      Keep a list of stock and notify Andi shortages that need to be ordered<br /><br />
                      Keep shop floor clean of fluids and coolant, ensure floor is not slippery<br /><br />
                      Clean lunch room, office, and washrooms every Monday 8-9 AM<br /><br />
                      Remain on the shop floor and report to Jason of any findings, do not report to Management.
                    </a>
                  </li>
                  <li>
                    <a onClick={e => e.preventDefault()}><b>Razvan</b><br />Lead Technician<br /><b>Responsibilities:</b><br /><br />
                        Greater emphasis on vehicle diagnosis.<br /><br />
                    Perform "Specialty Labour" jobs<br /><br />
                    Repair & service automotive vehicles<br /><br />
                      Remain on the shop floor and report to Jason of any findings, do not report to Management.
                    </a>
                  </li>
                  <li>
                    <a onClick={e => e.preventDefault()}><b>Hadad</b><br />Technician<br /><b>Responsibilities:</b><br /><br />
                      Repair & service automotive vehicles<br /><br />
                      Detail automotive vehicles<br /><br />
                      Clean & organize shop<br /><br />
                      Keep shop floor clean of fluids and coolant, ensure floor is not slippery<br /><br />
                      Clean lunch room, office, and washrooms every Monday 8-9 AM<br /><br />
                      Remain on the shop floor and report to Jason of any findings, do not report to Management.
                    </a>
                  </li>
                  <li>
                    <a onClick={e => e.preventDefault()}><b>Justin</b><br />Technician<br /><b>Responsibilities:</b><br /><br />
                      Repair & service automotive vehicles<br /><br />
                      Clean & organize shop<br /><br />
                      Keep shop floor clean of fluids and coolant, ensure floor is not slippery<br /><br />
                      Clean lunch room, office, and washrooms every Monday 8-9 AM<br /><br />
                      Remain on the shop floor and report to Jason of any findings, do not report to Management.
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      </div>
    </Draggable>
  );
}

export default Responsibilities;

