import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import axios from "axios";
import moment from 'moment';
import fire from '../../config/Firebase';
import { CookiesProvider, useCookies } from "react-cookie";

function PunchClock(props) {
  const [techs, setTechs] = useState([]);
  const [timeTable, setTimetable] = useState({});
  const [prevTimeTable, setTimetablePrev] = useState({});
  const [lastClock, setLastClock] = useState([]);
  const [clockInDisable, setClockInDisable] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["technician"]);

  const getTechs = async () => {
    const clockResponse = await axios.get(
      `https://redline-autohaus-api.herokuapp.com/employees/hours/calculate/${await fire.auth().currentUser.getIdToken()}`
      // `http://localhost/employees/hours/calculate/${await fire.auth().currentUser.getIdToken()}`
    );

    const clockResponsePrevWeek = await axios.get(
      `https://redline-autohaus-api.herokuapp.com/employees/hours/prev/calculate/${await fire.auth().currentUser.getIdToken()}`
      // `http://localhost/employees/hours/prev/calculate/${await fire.auth().currentUser.getIdToken()}`

    );
   
    setTechs(clockResponse.data.employees);
    setTimetable(clockResponse.data.timeTable);
    setTimetablePrev(clockResponsePrevWeek.data.timeTable);
    setLastClock(clockResponse.data.lastClock);
    console.log(clockResponse.data.lastClock);
    console.log(clockResponse.data);
  };

  useEffect(() => {
    getTechs();
  }, []);

  const techList = techs.map((tech) => (
      <option value={tech.name} selected={cookies.technician && cookies.technician.length > 0 && cookies.technician[0].name === tech.name ? true : false}>{tech.name}</option>
  ));

  const handleSubmitIn = async (evt) => {

    evt.preventDefault();
    setClockInDisable(true);
    console.log(evt.target);
    const payload = {
      technician: evt.target.technician.value,
      idToken: await fire.auth().currentUser.getIdToken(),
    };
  
    const response = await axios.post(
      "https://redline-autohaus-api.herokuapp.com/clock/in",
      payload
    );

    alert(response.data);
    setClockInDisable(false);
    getTechs();
  };

  const handleSubmitOut = async (evt) => {
    
    evt.preventDefault();
    setClockInDisable(true);
    const payload = {
      technician: evt.target.technician.value,
      idToken: await fire.auth().currentUser.getIdToken(),
    };
  
    const response = await axios.post(
      "https://redline-autohaus-api.herokuapp.com/clock/out",
      payload
    );

    alert(response.data);
    setClockInDisable(false);
    getTechs();
  };
  
  const lastClockView = lastClock.map((clock) => {
        return <Row className='clockRow'><Col>{clock.technician}</Col><Col><p style={clock.type == 'Clock In' ? {color: '#11ee69', fontWeight:600} : {color: '#f50a28', fontWeight:600}}>{clock.type}</p></Col><Col>{moment(clock.clock_capture).format('dddd, MMMM Do h:mm a')}</Col></Row>
  });

  const timeTableView = Object.keys(timeTable).map(function(key, index) {
       return <Row className='clockRow'><Col>{key}</Col><Col>{(timeTable[key].hours).toFixed(2)}</Col></Row>
  });

  const prevTimeTableView = Object.keys(prevTimeTable).map(function(key, index) {
    return <Row className='clockRow'><Col>{key}</Col><Col>{(prevTimeTable[key].hours).toFixed(2)}</Col></Row>
  });

  return (
    <>
      <Container fluid>
      <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row className="yearMakeModelRow">
                  <Col>
                    <h5 className="jobId">Clock In</h5>
                  </Col>
                </Row>
                <Form onSubmit={(evt) => handleSubmitIn(evt)}>
                  <Row>
                    <Col md="9">
                      <Form.Group style={{marginLeft: '-10px'}}>
                        <Form.Label>Technician</Form.Label>
                        <select className="form-control" name="technician" id="technician">  
                          {techList}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Button type="submit" className='clockBtn' disabled={clockInDisable}>Clock In</Button>                    
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row className="yearMakeModelRow">
                  <Col>
                    <h5 className="jobId">Clock Out</h5>
                  </Col>
                </Row>
                <Form onSubmit={(evt) => handleSubmitOut(evt)}>
                  <Row>
                    <Col md='9'>
                      <Form.Group style={{marginLeft: '-10px'}}>
                        <Form.Label>Technician</Form.Label>
                        <select className="form-control" name="technician" id="technician">  
                          {techList}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Button type="submit" className='clockBtn' disabled={clockInDisable}>Clock Out</Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row className="yearMakeModelRow">
                  <Col>
                    <h5 className="jobId">Clock Information</h5>
                  </Col>
                </Row>
                { lastClockView }
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row className="yearMakeModelRow">
                  <Col>
                    <h5 className="jobId">Current Week Timesheet</h5>
                  </Col>
                </Row>
                { timeTableView }
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row className="yearMakeModelRow">
                  <Col>
                    <h5 className="jobId">Last Weeks Timesheet</h5>
                  </Col>
                </Row>
                { prevTimeTableView }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PunchClock;
