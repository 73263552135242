import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import { FiSend } from "react-icons/fi";
import moment from 'moment';
import '../../style/Message.css';

import socketIOClient from "socket.io-client";
const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";





function Message(props) {
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const getMessages = async () => {

    const customerResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/customers/${props.match.params.id}/get`); 
    const messageResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/messages/${customerResponse.data.phone_number}/get`); 
    // const customerResponse = await axios.get(`http://localhost:3001/customers/${props.match.params.id}/get`); 
    // const messageResponse = await axios.get(`http://localhost:3001/messages/${customerResponse.data.phone_number}/get`);

    setCustomer(customerResponse.data);
    setMessages(messageResponse.data);
    setLoading(true);
    scrollToBottom();

  }

  useEffect(() => {
    
    getMessages();

    const socket = socketIOClient(ENDPOINT, { transports : ['websocket'] });
    socket.on("newMessage", async () => {
        await getMessages();
    });

  }, []);

  const scrollToBottom = async () => {
    const element = document.getElementById('messagesBox');
    console.log(element);
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  }

  const sendMessage = async (event) => {
    
    event.preventDefault();

    const message = event.target.message.value;
    event.target.message.value = '';
    console.log(customer, message);
    const messagesResponse = await axios.post(`https://redline-autohaus-api.herokuapp.com/message/send`, {customer, message});
    // const messagesResponse = await axios.post(`http://localhost:3001/message/send`, {customer, message});
    
    setMessages(messagesResponse.data);
    scrollToBottom();

  };

  const messageList = messages.map((message) => {
    {
      if(message.type == 'incoming'){
        return  <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
          </div>
          <div className="msg_cotainer">
            {message.message}
            <br />
            <span className="msg_time">{moment(message.createdAt).fromNow()}</span>
          </div>
        </div>
      }else{
        return <div className="d-flex justify-content-end mb-4">
          <div className="msg_cotainer_send">
            {message.message}
            <br />
            <span className="msg_time_send">{moment(message.createdAt).fromNow()}</span>
          </div>
        </div>
      }
    }
  });

  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="card" style={{height: '800px'}}>
              <div className="card-header msg_head">
                <div className="d-flex bd-highlight">
                  <div className="img_cont">
                    <span className="online_icon"></span>
                  </div>
                  <div className="user_info">
                    <span>Chat with {customer.name}</span>
                    <p>{customer.phone_number}</p>
                  </div>
                  <div className="video_cam">
                    <span><i className="fas fa-video"></i></span>
                    <span><i className="fas fa-phone"></i></span>
                  </div>
                </div>
                <span id="action_menu_btn"><i className="fas fa-ellipsis-v"></i></span>
                <div className="action_menu">
                  <ul>
                    <li><i className="fas fa-user-circle"></i> View profile</li>
                    <li><i className="fas fa-users"></i> Add to close friends</li>
                    <li><i className="fas fa-plus"></i> Add to group</li>
                    <li><i className="fas fa-ban"></i> Block</li>
                  </ul>
                </div>
              </div>
              <div id="messagesBox" className="card-body msg_card_body">
                {messageList}
              </div>
              <form onSubmit={(evt) => sendMessage(evt)} className="card-footer">
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text attach_btn"><i className="fas fa-paperclip"></i></span>
                  </div>
                  <textarea name="message" className="form-control type_msg" placeholder="Type your message..."></textarea>
                  <div className="input-group-append">
                    <Button type="submit" className="input-group-text send_btn"><FiSend /></Button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default Message;

