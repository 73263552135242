import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, FormControl, Modal, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import { FiSend } from "react-icons/fi";
import moment from 'moment';
import momentLocalizerWidget from 'react-widgets-moment';
import {momentLocalizer } from 'react-big-calendar';
import '../../style/Message.css';
import user from '../../assets/user.png';
import socketIOClient from "socket.io-client";
import fire from '../../config/Firebase';

moment.locale('en');
momentLocalizerWidget();
momentLocalizer(moment);

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";
let customerId = 0;
let deleteId = 0;

function MessageCenter(props) {
  const [customer, setCustomer] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messagesData, setMessages] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [socket, setSocket] = useState(null);
  const [messageLoading, setMessageLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const deleteAppointmentClose = () => setShowDelete(false);
  const deleteAppointmentShow = (appointmentId) => {setShowDelete(true); deleteId = appointmentId};
  
  useEffect(() => {
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    const getAllData = async () => {
      console.log(props.match.params.id);
      if(typeof props.match.params.id !== 'undefined'){
        newSocket.emit('getAllMessages', {idToken: await fire.auth().currentUser.getIdToken(), startingId: props.match.params.id});
      }else{
        newSocket.emit('getAllMessages', {idToken: await fire.auth().currentUser.getIdToken(), startingId: null});
      }
    };

    getAllData();

    newSocket.on("getAllMessages", async (data) => {
      console.log(data);
      setCustomer(data.customer);
      customerId = data.customer.id;
      setMessages(data.messages);
      newSocket.emit('setRead', {messages: data.messages});
      setMessagesList(data.messagesList);
      
      const vehicleList = [];
      const appointmentList = [];
      const invoiceList = [];

      data.customer.vehicles.forEach((vehicle) => {
        vehicleList.push(vehicle);
        vehicle.appointments.forEach((appointment)=>{
          appointmentList.push(appointment);
        });
        vehicle.invoices.forEach((invoice)=>{
          invoiceList.push(invoice);
        });
      });

      setVehicles(vehicleList);
      setAppointments(appointmentList);
      setInvoices(invoiceList);

      setLoading(true);
      scrollToBottom();

    });

    newSocket.on("getCustomerMessages", async (data) => {
      console.log(data);
      setMessages(data.messages);
      // newSocket.emit('setRead', {messages: data.messages});
      customerId = data.customer.id;
      setCustomer(data.customer);
      const vehicleList = [];
      const appointmentList = [];
      const invoiceList = [];

      data.customer.vehicles.forEach((vehicle) => {
        vehicleList.push(vehicle);
        vehicle.appointments.forEach((appointment)=>{
          appointmentList.push(appointment);
        });
        vehicle.invoices.forEach((invoice)=>{
          invoiceList.push(invoice);
        });
      });

      setVehicles(vehicleList);
      setAppointments(appointmentList);
      setInvoices(invoiceList);
      setMessageLoading(false);
      scrollToBottom();

    });

    newSocket.on('searchMessages', async (data) => {
      setMessagesList(data);
      setListLoading(false);
    });

    newSocket.on("newMessage", async (data) => {
      console.log(data);
      console.log(data.customer.id , customerId);
      if (data.customer.id == customerId) {
        setMessages(data.messages);
        scrollToBottom();
      }
  
      setMessagesList(data.messageListData.messagesList);

    });

    return () => {
      newSocket.disconnect();
    };

  }, [setSocket]);

  const scrollToBottom = async () => {
    const element = document.getElementById('messagesBox');
    console.log(element);
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  }

  const sendMessage = async (event) => {
    
    event.preventDefault();

    const message = event.target.message.value;
    event.target.message.value = '';
    console.log(customer, message);
    const messagesResponse = await axios.post(`https://redline-autohaus-api.herokuapp.com/message/send`, {customer, message});
    // const messagesResponse = await axios.post(`http://localhost:3001/message/send`, {customer, message});
    
    setMessages(messagesResponse.data);
    socket.emit('getAllMessages', {idToken: await fire.auth().currentUser.getIdToken(), startingId: null});
    scrollToBottom();

  };

  const sendInvoice = async (id) => {
    socket.emit('sendInvoice', {id});
  }

  const getCustomerMessages = async (customer_id) => {
    setMessageLoading(true);
    socket.emit('getCustomerMessages', { customer_id });
  }

  const deleteAppointment = async (id) => {
    await axios.get(`https://redline-autohaus-api.herokuapp.com/appointment/delete/${id}`);
    getCustomerMessages(customer.id);
    deleteAppointmentClose();
  };

  const searchMessages = async(evt) => {
    evt.preventDefault();
    if(socket && evt.target.value){
      const searchTerm = evt.target.value;
      const idToken = await fire.auth().currentUser.getIdToken()
      socket.emit('searchMessages', {idToken, searchTerm});
      setListLoading(true);
    }
  }

  const messageList = messagesList.map((item) => {
    return <li onClick={ () => getCustomerMessages(item.customer_id) } key={item.id}>
      <div className="d-flex bd-highlight">
        <div className="img_cont">
          <img src={user} className="rounded-circle user_img" />
          <span className="online_icon"></span>
        </div>
        <div className="user_info">
          <span>{item.customer_name}</span>
          <p>{ item.message }</p>
          <div className="transparentGradient"></div>
        </div>
      </div>
      {item.read === 0 ? <span className="unread" style={{width: "10px", background: "#82ccdd", height: "10px", borderRadius: "5px", float: "right", marginTop: "-40px", position: 'relative', zIndex: '9999'}}></span> : ''}
    </li>
  });

  const vehicleDataList = vehicles.map((vehicle) => {
    return <div key={vehicle.id}><p className='vehiclesMainP'>{vehicle.model}</p><span className="mainLabel">{vehicle.year} - {vehicle.make}</span></div>
  });

  const invoiceDataList = invoices.map((invoice) => {
    return <div key={invoice.id}><span className="mainLabel" style={{paddingTop: '20px'}}>Invoice</span><p className="mainP">#{invoice.id+1000}</p> <span className='mainLabel'>Amount</span><p className='mainP'>{(invoice.total_amount_charged*1.13).toFixed(2)}</p><hr /><Link className="customButton" style={{paddingTop: '11px', paddingBottom: '11px'}} to={`/invoices/${invoice.id}`}>View</Link> <button className="customButton" onClick={() => sendInvoice(invoice.id)}>Send Invoice</button></div>
  });

  const appointmentDataList = appointments.map((appointment) => {
    return <div key={appointment.id}><span className="mainLabel">Day</span><p className="mainP">{moment(appointment.appointment_date).format('dddd')}</p><span className="mainLabel">Date & Time</span><p className="mainP">{moment(appointment.appointment_date).format('MMM DD, YYYY - h:m A')}</p><hr/><button className="customButton" onClick={() => deleteAppointmentShow(appointment.id)}>Delete</button></div>
  });

  const messages = messagesData.map((message) => {
    {
      if(message.type == 'incoming'){
        return  <div className="d-flex justify-content-start mb-4" key={message.id}>
          <div className="img_cont_msg">
          </div>
          <div className="msg_cotainer">
            {message.message}
            <br />
            <span className="msg_time">{moment(message.createdAt).fromNow()}</span>
          </div>
        </div>
      }else{
        return <div className="d-flex justify-content-end mb-4" key={message.id}>
          <div className="msg_cotainer_send">
            {message.message}
            <br />
            <span className="msg_time_send">{moment(message.createdAt).fromNow()}</span>
          </div>
        </div>
      }
    }
  });

  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col md="3">
            <div className="card mb-sm-3 mb-md-0 contacts_card">
            <div className="card-header" id="searchContactsHeader">
              <Card.Title className="messagesCardTitle">Messages</Card.Title>
              <div className="input-group">
                <FormControl type="text" placeholder="Search..." onChange={(e) => searchMessages(e)} name="" className="form-control search" />
              </div>
            </div>
            <div className="card-body contacts_body" style={{height: '600px'}}>
              <div className="contacts">
                    {listLoading ? 
                    <div style={{position: 'absolute', top:'50%', left: '40%'}}>
                    <Triangle
                        type="Puff"
                        color="#000"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                    </div>
                    : 
                    messageList
                  }
              </div>
              <div id="bottomMessageListGradient"></div>
            </div>
          </div>
          </Col>
          <Col md="6">
            <div className="card" id="messagingCard" style={{height: '740px'}}>
              <div className="card-header msg_head">
                <div className="d-flex bd-highlight">
                  <div className="img_cont">
                    <img src={user} className="rounded-circle user_img" style={{width: '70px', height: '70px'}} />
                    <span className="online_icon"></span>
                  </div>
                  {messageLoading ? 
                    <div style={{display: 'block', margin:'auto'}}>
                    <Triangle
                        type="Puff"
                        color="#000"
                        height={25}
                        width={25}
                        timeout={30000} //3 secs
                    />
                    </div>
                    : 
                    <div className="user_info">
                      <span><Link to={`/customers/view/${customer.id}`}>{customer.name}</Link></span>
                      <p>{customer.phone_number}</p>
                    </div> 
                  }
                  <div className="video_cam">
                    <span><i className="fas fa-video"></i></span>
                    <span><i className="fas fa-phone"></i></span>
                  </div>
                </div>
                <span id="action_menu_btn"><i className="fas fa-ellipsis-v"></i></span>
                <div className="action_menu">
                  <ul>
                    <li><i className="fas fa-user-circle"></i> View profile</li>
                    <li><i className="fas fa-users"></i> Add to close friends</li>
                    <li><i className="fas fa-plus"></i> Add to group</li>
                    <li><i className="fas fa-ban"></i> Block</li>
                  </ul>
                </div>
              </div>
              <div id="messagesBox" className="card-body msg_card_body">
                {messageLoading ? 
                <div style={{position: 'fixed', top:'50%', left: '50%'}}>
                <Triangle
                    type="Puff"
                    color="#000"
                    height={100}
                    width={100}
                    timeout={30000} //3 secs
                />
                </div>
                :
                messages
                }
              </div>
              <form onSubmit={(evt) => sendMessage(evt)} className="card-footer" id="messageCardFooter">
                <div className="input-group">
                  {/* <div className="input-group-append">
                    <span className="input-group-text attach_btn"><i className="fas fa-paperclip"></i></span>
                  </div> */}
                  <textarea name="message" className="form-control type_msg" placeholder="Type your message..."></textarea>
                  <div className="input-group-append">
                    <Button type="submit" className="input-group-text send_btn"><FiSend /></Button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
          <Col md="3">
          <Row>
            <Col>
            <div className="card height250 borderNone">
                <div className="card-header rightSidebarHead">
                      <span>Appointments</span>
                </div>
                <div className="card-body overflow-scroll paddingSide25px">
                  {messageLoading ? 
                    <div style={{display: 'block', margin:'auto'}}>
                    <Triangle
                        type="Puff"
                        color="#000"
                        height={25}
                        width={25}
                        timeout={30000} //3 secs
                    />
                    </div>
                    : 
                    appointmentDataList
                  }
                </div>
            </div>
            </Col>
          </Row>
          <Row>
            <Col>
            <div className="card height150 borderNone">
                <div className="card-header rightSidebarHead">
                      <span>Vehicles</span>
                </div>
                <div className="card-body overflow-scroll paddingSide25px">
                  {messageLoading ? 
                    <div style={{display: 'block', margin:'auto'}}>
                    <Triangle
                        type="Puff"
                        color="#000"
                        height={25}
                        width={25}
                        timeout={30000} //3 secs
                    />
                    </div>
                    : 
                    vehicleDataList
                  }
                </div>
            </div>
            </Col>
          </Row>
          <Row>
            <Col>
            <div className="card height410 borderNone">
                <div className="card-header rightSidebarHead">
                      <span>Invoices</span>
                </div>
                <div className="card-body overflow-scroll paddingSide25px">
                  {messageLoading ? 
                    <div style={{display: 'block', margin:'auto'}}>
                    <Triangle
                        type="Puff"
                        color="#000"
                        height={25}
                        width={25}
                        timeout={30000} //3 secs
                    />
                    </div>
                    : 
                    invoiceDataList
                  }
                </div>
            </div>
            </Col>
          </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={showDelete} onHide={deleteAppointmentClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{color: '#fff'}}>Are You Sure You Want To Delete This Appointment?</p>
          <Button variant="success" onClick={() => deleteAppointment(deleteId)}>Yes</Button>
          <Button variant="danger" onClick={deleteAppointmentClose}>No</Button>
        </Modal.Body>
      </Modal>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         type="Puff"
         color="#000"
         height={100}
         width={100}
         timeout={30000} //3 secs
      />
    </div>
  );
}

export default MessageCenter;

