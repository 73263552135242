import React, {useEffect, useState} from 'react';
import { NavLink, useHistory, Link } from "react-router-dom";
import fire from '../config/Firebase';
import { FiCalendar, FiUsers, FiMessageSquare, FiClock, FiSettings, FiUserPlus } from "react-icons/fi";
import { TbSmartHome, TbReportAnalytics, TbCar, TbFileInvoice, TbCalendarTime, TbTools, TbPlaylistAdd, TbSearch } from "react-icons/tb";
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { BsSticky } from "react-icons/bs";
import { GiAutoRepair } from "react-icons/gi";
import "../style/Wrapper.css";
import "../style/Sidebar.css"
import homeSVG from '../assets/home.svg';
import logOut from '../assets/logout.svg';
import stats from '../assets/stats.svg';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import socketIOClient, { io } from "socket.io-client";

const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";
// const ENDPOINT = "http://localhost";
let currentTechnician = '';

function Sidebar() {
  const [showCreate, setShowCreate] = useState(false);
  const [showVin, setShowVin] = useState(false);
  const [vinBtn, setVinBtn] = useState(true);
  const [socket, setSocket] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [appointmentVehicles, setAppointmentVehicles] = useState();
  const [showNewAppointment, setShowNewAppointment] = useState(false);
  const [showNewVehicle, setShowNewVehicle] = useState(false);
  const [techs, setTechs] = useState([]);
  const [currentChecked, setCurrentChecked] = useState(0);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  useEffect(() => {
    
    const socket = socketIOClient(ENDPOINT);
    const getAllCustomersData = async () => {
      socket.emit('getTechnicians', await fire.auth().currentUser.getIdToken());
    }
    getAllCustomersData();
    setSocket(socket);
    
    socket.on('createCustomer', (data) => {
      console.log(data);
    });

    socket.on('searchCustomer', (data) => {
      console.log(data);
      setCustomerList(data);
    });

    socket.on('getTechnicians', async (data) => {
      if (data.length > 0) { 
        setTechs(data);
      }
    });

  }, [setSocket]);


  const createClose = () => setShowCreate(false);
  const openCreate = () => setShowCreate(true);
  const newAppointmentClose = () => setShowNewAppointment(false);
  const newAppointmentOpen = () => setShowNewAppointment(true);
  const newVehicleClose = () => setShowNewVehicle(false);
  const newVehicleOpen = () => setShowNewVehicle(true);

  let history = useHistory();

  const signOut = async () => {
    await fire.auth().signOut();
    history.push('/login');
    window.location.reload();
  };

  const searchPhoneNumber = async (event) => {
    event.preventDefault();
    const payload = {
      search: event.target.value,
      idToken: await fire.auth().currentUser.getIdToken()
    };
    socket.emit('searchCustomer', payload);
  };

  const addVin = (event) => {
    event.preventDefault();
    setShowVin(true);
    setVinBtn(false);
  }

  const actions = [
    {  name: 'Create New Customer', icon: <FiUserPlus fontSize={'18px'} />, onClick: openCreate },
    {  name: 'Create New Customer Vehicle', icon: <TbCar fontSize={'21px'} />, onClick: newVehicleOpen },
    {  name: 'Create New Invoice', icon: <TbFileInvoice fontSize={'21px'} />, onClick: null },
    {  name: 'Create New Appointment', icon: <TbCalendarTime fontSize={'21px'} />, onClick: newAppointmentOpen },
    {  name: 'Create New Service', icon: <TbPlaylistAdd fontSize={'21px'} />, onClick: null },
    {  name: 'Create New Technician', icon: <TbTools fontSize={'21px'} />, onClick: null },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      name: event.target.name.value,
      phone: event.target.phone.value,
      vin: showVin ? event.target.vin.value : null,
      year: event.target.year.value,
      make: event.target.make.value,
      model: event.target.model.value,
      idToken: await fire.auth().currentUser.getIdToken()
    };

    socket.emit('createCustomer', payload);

  }

  const createAppointment = async (event) => {

    event.preventDefault();
    const payload = {
      date: event.target.appointment_date.value,
      duration: event.target.appointment_duration.value,
      vehicle_id: event.target.vehicle_id.value,
      description: event.target.description.value,
      technician: event.target.technician.value,
      idToken: await fire.auth().currentUser.getIdToken()
    };
    
    socket.emit('createAppointment', payload);
    createClose();
    newAppointmentClose();
  }

  const techSelectId = techs.map((tech) =>
    currentTechnician == tech.id ?  <option value={tech.id} selected>{tech.name}</option> : <option value={tech.id} >{tech.name}</option>
  );

  const scheduleNewAppointment = (e, customer) => {
    e.preventDefault();
    setAppointmentVehicles(customer.vehicles.map((vehicle) =>
          <option value={vehicle.id}>{vehicle.year} {vehicle.make} {vehicle.model}</option>
    ));
    setShowCreate(false);
    setShowNewAppointment(true);
    console.log(e);
  }

  const handleToggle = (customerId, event, customer) => {
    setCurrentChecked(customerId);
    setCurrentCustomer(customer);
    setAppointmentVehicles(customer.vehicles.map((vehicle) =>
      <option value={vehicle.id}>{vehicle.year} {vehicle.make} {vehicle.model}</option>
    ));
    //set customerId to customer id
  };

  const addVehicle = async (event) => {
    event.preventDefault();
    const payload = {
      customer_id: currentCustomer.id ? currentCustomer.id : null,
      vin: showVin ? event.target.vin.value : null,
      year: event.target.year.value,
      make: event.target.make.value,
      model: event.target.model.value,
      idToken: await fire.auth().currentUser.getIdToken()
    };
    
    socket.emit('addVehicle', payload);
    newVehicleClose();
  }

  return (
    <>
    <div id="sidebar-wrapper">
        <aside id="sidebar">
          <ul id="sidemenu" className="sidebar-nav">
            <li>
              <NavLink to="/dashboard" activeClassName="active" className="flex justify-center items-center text-4xl">
                <TbSmartHome className="m-auto" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/messages" className="hideMobile flex justify-center items-center text-4xl mt-2" activeClassName="active">
                <FiMessageSquare className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/customers" activeClassName="active" className="flex justify-center items-center text-4xl mt-2">
                <FiUsers className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/schedule" activeClassName="active" className="flex justify-center items-center text-4xl mt-2">
                <FiCalendar className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/stats" activeClassName="active" className="flex justify-center items-center text-4xl mt-2">
                <TbReportAnalytics className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/parts" className="hideMobile flex justify-center items-center text-4xl mt-2" activeClassName="active">
                <GiAutoRepair className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/clock" activeClassName="active" className="flex justify-center items-center text-4xl mt-2">
                <FiClock className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/notes" className="hideMobile flex justify-center items-center text-4xl mt-2" activeClassName="active">
                <BsSticky className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings" className="hideMobile flex justify-center items-center text-4xl mt-2" activeClassName="active">
                <FiSettings className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/tech-settings" className="mobileOnly flex justify-center items-center text-4xl mt-2" activeClassName="active">
                <FiSettings className="m-auto mt-4" />
              </NavLink>
            </li>
            <li>
              <a href="#logout" className="flex justify-center items-center mt-2" onClick={() => signOut()}>
                <img src={logOut} alt="logout" className="m-auto mt-2" />
              </a>
            </li>
          </ul>
        </aside>
    </div>
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: 'absolute', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
      color='#4439BF'
      style={{ position: "fixed", bottom: "20px", right: "20px" }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          icon={action.icon}
          key={action.name}
          onClick={action.onClick}
          tooltipTitle={action.name}
        />
      ))}
    </SpeedDial>
    <Modal show={showCreate} onHide={createClose}>
      <Modal.Body>
        <Form onSubmit={(evt) => handleSubmit(evt)}>
          <Row>
            <Col>
              <p className='modalHeading'>Create New Customer</p>
            </Col>
          </Row>
          <Row>
            <Col>
                <Col>
                  <Form.Group>
                    <span className='modalFormLabel'>Search By Phone:</span>
                    <Form.Control className='createCustomerFormInputs' name="phone" type="text" required="required" onKeyUp={(e) => searchPhoneNumber(e)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <span className='modalFormLabel'>Name:</span>
                    <Form.Control className='createCustomerFormInputs' name="name" type="text" required="required" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <span className='modalFormLabel'>Year:</span>
                    <Form.Control className='createCustomerFormInputs' name="year" type="text" required="required" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <span className='modalFormLabel'>Make:</span>
                    <Form.Control className='createCustomerFormInputs' name="make" type="text" required="required" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <span className='modalFormLabel'>Model:</span>
                    <Form.Control className='createCustomerFormInputs' name="model" type="text" required="required" />
                  </Form.Group>
                </Col>
                <Col>
                  {showVin ?
                    <Form.Group>
                      <span className='modalFormLabel'>VIN:</span>
                      <Form.Control className='createCustomerFormInputs' name="vin" type="text" />
                    </Form.Group>
                    :
                    ''
                  }
                </Col>
            </Col>
            <Col>
              <List>
                {customerList.length > 0 ? customerList.map((customer, index) => {
                  return (
                   
                      <ListItem
                        secondaryAction={
                          <>
                            <Link onClick={(e) => scheduleNewAppointment(e, customer)}><IconButton edge="end" aria-label="delete">
                              <TbCalendarTime />
                            </IconButton></Link>
                            <Link to={`/customers/view/${customer.id}`}><IconButton edge="end" aria-label="delete">
                              <TbSearch />
                            </IconButton></Link>
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={customer.name}
                          secondary={customer.vehicles[0].make+' '+customer.vehicles[0].model}
                        />
                      </ListItem>
                    
                  )
                }) : null
                }
              </List>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" type="submit">
                Create Customer
              </Button>
              {
                vinBtn ? 
                <Button variant="success" type="submit" onClick={(evt) => addVin(evt)} style={{ marginLeft: '20px' }}>
                  Add Vin
                </Button> 
                : 
                ''
              }
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
    <Modal show={showNewAppointment} onHide={newAppointmentClose}>
      <Row>
        <Col>
          <p className='modalHeading'>Create New Appointment</p>
        </Col>
      </Row>
      <Form onSubmit={(evt) => createAppointment(evt)}>
        <Row>
        <Col>
            <Col>
              <Form.Group>
                <span className='modalFormLabel'>Search By Phone:</span>
                <Form.Control className='createCustomerFormInputs' name="phone" type="text" onKeyUp={(e) => searchPhoneNumber(e)} />
              </Form.Group>
            </Col>
            <Col>
              <span className='modalFormLabel'>Date & Time:</span>
              <DateTimePicker type="text" name="appointment_date" required className='calendarInputStyle' />
            </Col>
            <Col>
              <span className='modalFormLabel'>Service Description:</span>
              <Form.Control name="description" required type="text" className='createCustomerFormInputs' />
            </Col>
            <Col>
              <span className='modalFormLabel'>Vehicle:</span>
              <select name="vehicle_id" required className="form-control createCustomerFormInputs">
                {appointmentVehicles}
              </select>
            </Col>
            <Col>
              <span className='modalFormLabel'>Technician Assigned:</span>
              <select  name="technician" className="form-control createCustomerFormInputs">
                {techSelectId}
              </select>
            </Col>
            <Col>
              <span className='modalFormLabel'>Duration Of Job:</span>
              <Form.Control type='number' name="appointment_duration" required className='createCustomerFormInputs' />
            </Col>
            <Col>
              <Button variant="primary" type="submit">
                Add Appointment
              </Button>
            </Col>
        </Col>
        <Col>
          <List>
            {customerList.length > 0 ? customerList.map((customer, index) => {
              return (
                  <ListItem
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        className='selectCustomerCheckbox'
                        onChange={event => handleToggle(customer.id, event, customer)}
                        checked={currentChecked === customer.id ? true : false}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>
                      <ListItemText primary={`${customer.name}`} secondary={customer.vehicles[0].make+' '+customer.vehicles[0].model} />
                    </ListItemButton>
                  </ListItem>
                
              )
            }) : null
            }
          </List>
        </Col>
        </Row>
      </Form>
    </Modal>
    <Modal show={showNewVehicle} onHide={newVehicleClose}>
      <Row>
        <Col>
          <p className='modalHeading'>Create New Vehicle</p>
        </Col>
      </Row>
      <Form onSubmit={e => (addVehicle(e))}>
        <Row>
          <Col>

              <Col>
                <Form.Group>
                  <span className='modalFormLabel'>Search By Phone:</span>
                  <Form.Control className='createCustomerFormInputs' name="phone" type="text" onKeyUp={(e) => searchPhoneNumber(e)} />
                </Form.Group>
              </Col>
   
              <Col>
                <span className='modalFormLabel'>Customer:</span>
                <Form.Control name="customer_id" value={currentCustomer ? currentCustomer.name : null} required placeholder='Please Select A Customer' disabled className='createCustomerFormInputs' />
              </Col>

              <Col>
                <span className='modalFormLabel'>Year:</span>
              <Form.Control name="year" type="text" required className='createCustomerFormInputs' />
              </Col>

              <Col>
                <span className='modalFormLabel'>Make:</span>
              <Form.Control name="make" type="text" required className='createCustomerFormInputs' />
              </Col>

              <Col>
                <span className='modalFormLabel'>Model:</span>
                <Form.Control name="model" type="text" required className='createCustomerFormInputs' />
              </Col>
              {showVin ?
              <Col>
                <Form.Group>
                  <span className='modalFormLabel'>VIN:</span>
                  <Form.Control name="vin" type="text" className='createCustomerFormInputs' />
                </Form.Group>
              </Col>
              :
              ''
              }
              <Col>
                <Button variant="primary" type="submit">
                  Add Vehicle
                </Button>
                {vinBtn ? <Button variant="success" type="submit" onClick={(evt) => addVin(evt)} style={{ marginLeft: '20px' }}>
                  Add Vin
                </Button> : ''}
              </Col>
       
          </Col>
          <Col>
            <List>
              {customerList.length > 0 ? customerList.map((customer, index) => {
                return (

                    <ListItem
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          className='selectCustomerCheckbox'
                          onChange={event => handleToggle(customer.id, event, customer)}
                          checked={currentChecked === customer.id ? true : false}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar />
                        </ListItemAvatar>
                        <ListItemText primary={`${customer.name}`} secondary={customer.vehicles[0].make+' '+customer.vehicles[0].model} />
                      </ListItemButton>
                    </ListItem>
                  
                )
              }) : null
              }
            </List>
          </Col>
        </Row>  
      </Form>
    </Modal>
  </>
    
  );
}

export default Sidebar;
