import React, {useState, useEffect} from 'react';
import '../style/Login.css';
import socketIOClient, { io } from "socket.io-client";
import fire from '../config/Firebase';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import registerArt from '../assets/registration-splash-art.png';
import autoLogo from '../assets/auth-logo.png';
const ENDPOINT = "https://redline-autohaus-api.herokuapp.com";

//const ENDPOINT = "http://localhost:8080";


function Register() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [socket, setSocket] = useState(null);

  useEffect(() => { 
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);
    newSocket.on('register', (data) => {
      fire.auth().signInWithCustomToken(data)
      .then(function(userCredential) {
        // User signed in successfully
        console.log('User signed in:', userCredential.user);
      })
      .catch(function(error) {
        // An error occurred
        alert(error.message);
      });
    });

    newSocket.on('error', data => { 
      alert(data);
    });

    return () => {
      newSocket.disconnect();
    }

  }, [setSocket]);

  const handleRegister = (evt) => {

    evt.preventDefault();
    console.log(username);
    console.log(password);
    
    socket.emit('register', {
      username,
      password
    });
    // fire.auth().createUserWithEmailAndPassword(username, password).catch(function(error) {
  
    //   alert(error.message);
      
    // });

  };

  return (
    <>
    <Container fluid className='authContainer'>
        <Navbar expand="lg" className='transparentBG frontNav authNav desktop'>
              <Link to='/login'><Button className='frontBtn'>Login</Button></Link>
              <Link to='/register'><Button variant='success' className='frontBtn authLoginNavLink'>Sign Up</Button></Link>
        </Navbar>
        <Row className='authRow' style={{height: window.innerHeight+'px'}}>
          <Col className='leftSideLogin desktop'>
             <Link to='/'><img src={autoLogo}></img></Link>
             <img src={registerArt} className='registerArt'></img>
          </Col>
          <Col className="leftSideLogin">
            <Link to='/' className='mobile'><img src={autoLogo}></img></Link>
            <h5 className="card-title login-heading">Sign Up</h5>
            <form className="form-signin" onSubmit={handleRegister}>
              <label for="inputEmail" className="auth-labels">Email address</label>
              <div className="form-label-group">
                <input type="email" id="inputEmail" onChange={evt => setUsername(evt.target.value)} className="form-control auth-form" placeholder="Email address" required autofocus />
              </div>
              <label for="inputPassword" className="auth-labels">Password</label>
              <div className="form-label-group">
                <input type="password" onChange={evt => setPassword(evt.target.value)} id="inputPassword" className="form-control auth-form" placeholder="Password" required />
              </div>
              <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign Up</button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;

