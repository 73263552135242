import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';

function Invoices(props) {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getQuotes = async () => {
    const invoicesResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/quotes/get'); 
    setQuotes(invoicesResponse.data);
    setLoading(true);
  }

  useEffect(() => {
    
    getQuotes();
    
  }, []);

  const approveQuote = async (id) => {
    console.log(id);
    const approveResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/quotes/${id}/approve`);
    console.log(approveResponse);
    props.history.push(`/jobs`);
  };

  const deleteInvoice = async (evt, id) => {
    evt.preventDefault();
    const deleteRes = await axios.get(`https://redline-autohaus-api.herokuapp.com/invoice/delete/${id}`);
    console.log(deleteRes);
    getQuotes();

  }

  const quoteList = quotes.map((quote) =>
    <tr>
      <td>{quote.id}</td>
      <td>{quote.vehicle.customer.name}</td>
      <td>{quote.vehicle.customer.phone_number}</td>
      <td>{quote.vehicle.year}</td>
      <td>{quote.vehicle.make}</td>
      <td>{quote.vehicle.model}</td>
      <td>{quote.total_amount_charged}</td>
      <td>{quote.createdAt}</td>
      <td><Button onClick={() => approveQuote(quote.id)} className="btn-success">Approve Quote</Button></td>
      <td><Link to={`/invoices/${quote.id}`}><Button>View</Button></Link></td>
      <td><Button className="btn-danger" onClick={(evt) => deleteInvoice(evt, quote.id)}>Delete</Button></td>
    </tr>
  );
  
  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Quotes</Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Year</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Sale Amount</th>
                      <th>Date</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {quoteList}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default Invoices;

