import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import {Triangle} from 'react-loader-spinner';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { jsPDF } from "jspdf";
import '../../style/ViewBill.css';
import logo from '../../assets/automall.png';

function ViewVehicleBillSale(props) {
  
  const [loading, setLoading] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    
    //Initial Data
    const getData = async () => {
      const dataPayload = await axios.get(`https://redline-autohaus-api.herokuapp.com/bill-of-sale/${props.match.params.id}/get`);
      // const dataPayload = await axios.get(`http://localhost/bill-of-sale/${props.match.params.id}/get`);
      console.log(dataPayload);
      setData(dataPayload.data);
      setLoading(true);
    }
    
    getData();
    
  }, [props.match.params.id]);
  
  const printDocument = () => {
    htmlToImage.toPng(document.getElementById('printBillOfSale'), { quality: 1 })
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = 'my-image-name.jpeg';
          const pdf = new jsPDF();
          const imgProps= pdf.getImageProperties(dataUrl);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight);
          pdf.save("download.pdf"); 
        });
  }


  return (
    loading ?
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button onClick={() => printDocument()}>Print</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card id="printBillOfSale">
              <Card.Body>
                <Card.Title></Card.Title>
                <img src={logo} alt="Redline Autohaus & Performance" style={{ width: '420px', marginBottom: '50px' }}></img>
                <Row>
                  <div className="date" style={{fontSize: '18px'}}>Date Of Sale: <b>{moment(data.dayOfSale).format('YYYY-MMM-DD')}</b></div>
                </Row>
                <Row>
                   <div className="date" style={{fontSize: '18px'}}>HST #: <b>102223088RT0001</b></div>
                </Row>
                <Row>
                  <Col>
                  <Row className="noPadRow">
                    <Col className="noPadCol" xs="6">
                      <Row className="billRow rowTitle">
                        <div className="cell">Purchasers Information</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Purchasers Name:</div>
                        <div className="cell">First <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.firstName} />
                          </Form>
                        </div>
                        <div className="cell">Middle Initial <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.middleInitial} style={{maxWidth: '20px'}}/>
                          </Form>
                        </div>
                        <div className="cell">Last <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.lastName} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Purchasers Address <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.purchaserAddress} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">City<br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.city} />
                          </Form>
                        </div>
                        <div className="cell">Province<br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.province} />
                          </Form>
                        </div>
                        <div className="cell">Postal Code <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.postalCode} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Home Telephone <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.homeTelephone} />
                          </Form>
                        </div>
                        <div className="cell">Business Telephone <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.businessTelephone} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Drivers License No. <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.driverLicenseNumber} />
                          </Form>
                        </div>
                        <div className="cell">Expiry Date <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.expiryDate} />
                          </Form>
                        </div>
                        <div className="cell">Email <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.emailAddress} />
                          </Form>
                        </div>
                      </Row>
                    </Col>
                    <Col className="noPadCol" xs="6">
                      <Row className="billRow rowTitle">
                        <div className="cell">Vehicle Information</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">
                          Year <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.vehicleYear} style={{maxWidth: '60px'}} />
                          </Form>
                        </div>
                        <div className="cell">
                          Make <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.vehicleMake} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">
                          Model <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.vehicleModel} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">
                        Trim Level <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.vehicleTrim} style={{maxWidth: '120px'}} />
                          </Form>
                        </div>
                        <div className="cell">Colour <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.vehicleColour} style={{maxWidth: '60px'}}/>
                          </Form>
                        </div>
                        <div className="cell">Stock # <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.stockNumber} style={{maxWidth: '40px'}}/>
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">V.I.N Number <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.vinNumber} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Distance Travelled <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.distanceTravelled} />
                          </Form>
                        </div>
                        <div className="cell">KM/Miles <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.distanceUnit} />
                          </Form>
                        </div>
                        <div className="cell">Distance Travelled Known/Unkown <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.distanceUnknown > 0 ? 'Distance Unkown' : 'Distance Known'} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Distance Travelled Known To Be Higher <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.actualDistanceHigher > 0 ? 'Distance Known To Be Higher' : 'Distance Is Accurate'} />
                          </Form>
                        </div>
                        <div className="cell">Last Known Travel Date And Mileage If Unknown <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.lastKnownTravel ? `${data.lastKnownTravelDate} - ${data.lastKnownMileage}` : 'N/A'} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Daily Rental <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.dailyRental > 0 ? 'Yes' : 'No'} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">MTO Brand <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.mtoBrand} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Vehicle Sold With Safety Standards Certificate <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.safetyStandardsCert ? 'Yes' : 'No'} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="noPadRow">
                    <Col className="noPadCol">
                      <Row className="billRow rowTitle">
                        <div className="cell">Insurance Information</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Name Of Insurance Company <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.insuranceCompany} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Policy Number <br />
                          <Form>
                              <Form.Control className="billSaleEditInput" defaultValue={data.policyNumber} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Expiry Date <br />
                          <Form>
                              <Form.Control className="billSaleEditInput" placeholder={moment(data.policyExpiryDate).utc().format('YYYY-MM-DD')} style={{maxWidth: '150px'}} />
                          </Form>  
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Insurance Agent Phone Number <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.insurancePhone} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Vehicle To Be Traded-In</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Year <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInYear} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Make <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInMake} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Model <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInModel} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Trim <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInTrim} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Colour <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInColour} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Mileage <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInKM ? data.tradeInKM+' KM' : ''} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">V.I.N # <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInVIN} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">HST Registrant <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.tradeInHST} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Vehicle Lien <br />
                          {
                          data.tradeInVIN ?
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.tradeInLien > 0 ? 'Yes' : 'No'} style={{maxWidth: '150px'}} />
                          </Form>
                          :
                          ''
                          }
                        </div>
                        <div className="cell">Vehicle Lien Holder <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.lienHolder} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Vehicle Lien Amount <br />                          
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.lienAmount} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Manufacturer Participates In CAMVAP</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">CAMVAP <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.CAMVAP > 0 ? 'Yes' : 'No'} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Privacy Statement</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell privacyStat">By signing this contract you consent to the dealer contacting you in the future and to the sharing of information with associated businesses so that they may provide you with timely information about their services. You may withdraw your consent in writing at any time.</div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Salesperson Signature</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Salespersons Name <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.salesPersonsName} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Registration Number <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.registrationNumber} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Salespersons Signature</div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Vendors Acceptance</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Dealer Registration No. <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.dealerRegistrationNumber} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Name Of Official <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.nameOfOfficial} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Acceptors Registration No. <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.acceptorsRegistrationNumber} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Title <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.title} style={{maxWidth: '250px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Date <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={moment(data.vendorDate).utc().format('YYYY-MM-DD')} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Acceptors Signature</div>
                      </Row>
                      <Row className="billRow termsOfContractStatement">
                        <div className="cell">
                          <b>CARFAX REPORT NUMBER:</b> {data.carfaxReportNumber}<br />
                          <b>CARFAX REPORT:</b> I agree that all the required disclosures have been presented to me as shown on the carfax report number above.<br />
                          <b>ADDITIONAL DISCLOSURES: {data.notes}</b>
                          <br />
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Purchasers Signature:</div>
                      </Row>
                    </Col>
                    <Col className="noPadCol">
                      <Row className="billRow rowTitle">
                        <div className="cell">Dealer Guaranty</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Is There A Dealer Guaranty <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.dealerGuarantee > 0 ? 'Yes' : 'No'} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell"># Of Days Guaranteed <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.dealerGuaranteeDays} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Amount Of KM Guaranteed <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.dealerGuaranteeKM} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Description <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.dealerGuaranteeDescription} style={{maxWidth: '550px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Extended Warranty</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Is There An Extended Warranty On This Vehicle? <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.extendedWarranty ? 'Yes' : 'No'} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Retail Value If Included In Price <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.extendedWarrantyValue} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Warranty Expiry Date <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={moment(data.extendedWarrantyDate).utc().format('YYYY-MM-DD')} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Warranty KM (Date/KM Which Ever Comes First) <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={`${moment(data.extendedWarrantyDate).utc().format('YYYY-MM-DD')} - ${data.extendedWarrantyKM} KM`} style={{maxWidth: '450px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell" style={{width: '600px'}}>Warranty Description <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.extendedWarrantyDescription} style={{maxWidth: '950px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Terms Of Financing</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Amount Of Payments <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.amountOfPayments} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Number Of Payments <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.numberOfPayments} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Payments Start On <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.paymentsStartOn} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                        <div className="cell">Credit Approval <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" defaultValue={data.creditApproval} style={{maxWidth: '450px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Customer Has Received The Financing Disclosure Statement From The Lending Institution</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Will the dealer receive any incentive for the financing of this vehicle? <br />
                          <Form>
                            <Form.Control className="billSaleEditInput" placeholder={data.dealerIncentiveForFinancing ? 'Yes' : 'No'} style={{maxWidth: '150px'}} />
                          </Form>
                        </div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Purchasers Initials</div>
                      </Row>
                      <Row className="billRow rowTitle">
                        <div className="cell">Terms Of The Contract</div>
                      </Row>
                      <Row className="billRow termsOfContractStatement">
                        <div className="cell"><b>VEHICLE SOLD "AS IS":</b> The motor vehicle sold under this contract is being sold "as-is" and is not represented as being in roadworthy condition, mechanically sound or maintained at any guaranteed level of quality. The vehicle may not be fit for use as a means of transportation and may require substaintial repairs at the purchaser's expense. It may not be possible to register the vehicle to be driven in its current condition.</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Purchasers Initials</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell salesFinalStatement"><b>SALES FINAL</b> Please review the entire contract, including all attached statements, before signing.This contract is final and binding once you have signed it, unless the motor vehicle dealer has failed to comply with certain legal obligations.</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell acknowledgeStatement">You acknowledge having read all the terms of the contract, including those on the reverse and on attached pages. You understand these terms make up the entire contract.</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Purchasers Signature</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Co-Signer's Name (Print)</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Co-Signer's Signature</div>
                      </Row>
                    </Col>
                    <Col className='noPadCol'>
                      <Row className="billRow rowTitle">
                        <div className="cell">Terms Of Settlement</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Selling Price</div>
                        <div className="cell">{data.sellingPrice ? parseFloat(data.sellingPrice).toFixed(2) : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Extended Warranty</div>
                        <div className="cell">{data.extendedWarrantyPrice ? data.extendedWarrantyPrice : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Licence Fee</div>
                        <div className="cell">{data.licenceFee ? data.licenceFee : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Trade-In Allowance</div>
                        <div className="cell">{data.tradeInAllowance ? data.tradeInAllowance : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Net Difference</div>
                        <div className="cell">{data.netDifference ? data.netDifference : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Gasoline</div>
                        <div className="cell">{data.gasolineCost ? data.gasolineCost : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Payout On Lien On Trade In</div>
                        <div className="cell">{data.payoutLienOnTradeIn ? data.payoutLienOnTradeIn : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">HST Payable on Trade-In</div>
                        <div className="cell">{data.hstPayableOnTradeIn ? data.hstPayableOnTradeIn : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Payout On Lien</div>
                        <div className="cell">{data.payoutLienOnTradeIn ? data.payoutLienOnTradeIn : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Deposit</div>
                        <div className="cell">{ data.deposit ? data.deposit : 'N/A' }</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Deposit Amount</div>
                        <div className="cell">{data.depositAmount ? parseFloat(data.depositAmount).toFixed(2) : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Lien Registration Fee</div>
                        <div className="cell">{data.lienRegistrationFee ? data.lienRegistrationFee : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Balance Financed Subject To Approval</div>
                        <div className="cell">{data.balanceFinanced ? data.balanceFinanced : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Net Amount To Be Financed</div>
                        <div className="cell">{data.netAmountFinanced ? data.netAmountFinanced : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">PPSA</div>
                        <div className="cell">{data.ppsa ? data.ppsa : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Cost Of Borrowing</div>
                        <div className="cell">{data.costOfBorrowing ? data.costOfBorrowing : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">APR</div>
                        <div className="cell">{data.apr ? data.apr : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">Lender Admin Fee</div>
                        <div className="cell">{data.lenderAdminFee ? data.lenderAdminFee : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell"><b>Sub-Total</b></div>
                        <div className="cell">{data.subTotal ? parseFloat(data.subTotal).toFixed(2) : '0.00'}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell">HST On Sub-Total</div>
                        <div className="cell">{((parseFloat(data.subTotal)-parseFloat(data.licenceFee ? data.licenceFee : 0))*0.13).toFixed(2)}</div>
                      </Row>
                      <Row className="billRow">
                        <div className="cell"><b>Total Balance Due</b></div>
                        <div className="cell">{(parseInt(data.subTotal)+((parseInt(data.subTotal)-parseInt(data.licenceFee ? data.licenceFee : 0))*0.13)).toFixed(2)}</div>
                      </Row>
                    </Col>
                  </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    :
    <div style={{position: 'fixed', top:'50%', left: '50%'}}>
      <Triangle
         color="#FFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

export default ViewVehicleBillSale;

